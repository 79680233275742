import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import filters from "filters";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LEAD_STATUS_COLOR, MOBILE_VIEW } from "utils/constants";
import { getLastNameAndFirstNameAndSecondNameLetter } from "utils/helpers";
import DataTable from "./DataTable";
import store from "store";
import { addSelected } from "store/ducks/table/slice";

const LeadsDataTable = ({
  list,
  totalElements,
  isLoading,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  hideFooter,
  autoPageSize,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(MOBILE_VIEW);

  useEffect(() => {
    return () => store.dispatch(addSelected([]));
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      filterable: false,
      hideable: false,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/leads/${id}`}
            style={{ textDecoration: "none", color: theme.palette.text.dark, width: "100%", cursor: "pointer" }}
          >
            {id}
          </Link>
        );
      },
      minWidth: 80,
      flex: 0.3,
    },
    {
      field: "name",
      headerName: t("base.labels.contactName"),
      filterable: false,
      renderCell: ({ row }) => {
        return <Typography>{getLastNameAndFirstNameAndSecondNameLetter(row)}</Typography>;
      },
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: "phone",
      headerName: t("base.labels.phone"),
      filterable: false,
      renderCell: ({ row: { contact } }) => {
        return <Typography>{contact?.phones[0]}</Typography>;
      },
      minWidth: 140,
      flex: 0.3,
    },
    {
      field: "createdAt",
      headerName: t("base.labels.date"),
      filterable: false,
      renderCell: ({ row: { createdAt } }) => {
        const shortDate = filters.date(createdAt);
        return <Typography>{shortDate}</Typography>;
      },
      minWidth: 90,
      flex: 0.3,
    },
    {
      field: "program",
      headerName: t("base.labels.program"),
      filterable: false,
      renderCell: ({ row: { program } }) => {
        return <Typography>{program?.name}</Typography>;
      },
      minWidth: 140,
      flex: 0.3,
    },
    {
      field: "status",
      headerName: t("base.labels.status"),
      filterable: false,
      renderCell: ({ row: { status } }) => {
        const statusColor = LEAD_STATUS_COLOR(theme)[status?.name] || "text.main";
        return <Typography color={statusColor}>{status?.label}</Typography>;
      },
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "pipeline",
      headerName: t("base.labels.pipeline"),
      filterable: false,
      minWidth: 140,
      flex: 0.3,
      renderCell: ({ row: { pipeline } }) => {
        return <Typography>{pipeline?.name}</Typography>;
      },
    },
    {
      field: "stage",
      headerName: t("base.labels.stage"),
      filterable: false,
      minWidth: 140,
      flex: 0.3,
      renderCell: ({ row: { stage } }) => {
        return <Typography>{stage?.name}</Typography>;
      },
    },
    { field: "sourcePage", headerName: t("base.labels.sourcePage"), filterable: false, minWidth: 140, flex: 0.3 },
    {
      field: "channel",
      headerName: t("base.labels.channel"),
      filterable: false,
      minWidth: 140,
      flex: 0.3,
      renderCell: ({ row: { channel } }) => {
        return <Typography>{channel?.name}</Typography>;
      },
    },
    { field: "pageLink", headerName: t("base.labels.pageLink"), filterable: false, minWidth: 140, flex: 0.3 },
    { field: "source", headerName: t("base.labels.source"), filterable: false, minWidth: 140, flex: 0.3 },
    { field: "medium", headerName: t("base.labels.medium"), filterable: false, minWidth: 140, flex: 0.3 },
    { field: "campaign", headerName: t("base.labels.campaign"), filterable: false, minWidth: 140, flex: 0.3 },
  ];
  return (
    <Box
      height={isMobile ? "calc(100% - 108px)" : "calc(100% - 76px)"}
      sx={{ backgroundColor: "extra.white", p: 2, borderRadius: 2 }}
    >
      <DataTable
        list={list}
        totalElements={totalElements}
        columns={columns}
        isLoading={isLoading}
        totalPages={totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
        hideFooter={hideFooter}
        autoPageSize={autoPageSize}
      />
    </Box>
  );
};

export default memo(LeadsDataTable);
