import { createSlice } from "@reduxjs/toolkit";

const keyCall = createSlice({
  name: "keyCallSlice",
  initialState: {},
  reducers: {},
  extraReducers: () => {},
});

export const {} = keyCall.actions;
export default keyCall.reducer;
