import { memo, useEffect } from 'react';
import { useField } from 'formik';
import { TextField } from '@mui/material';
// import { usePermissionContext } from 'services/context/permissionContext';

const FormikTextField = ({ name, disableError, defValue, InputProps = {}, ...rest }) => {
  const [field, state, helpers] = useField(name);
  // const hasPermission = usePermissionContext();
//TODO: permissions
  const { readOnly } = InputProps;

  useEffect(() => {
    if (defValue) {
      helpers.setValue(defValue);
    }
  }, [defValue, helpers]);

  return (
    <TextField
      {...field}
      {...rest}
      InputProps={{
        ...InputProps,
        readOnly: 
        // !hasPermission || 
        readOnly,
      }}
      InputLabelProps={{ shrink: true }}
      autoComplete="off"
      error={!disableError && !!state.error && state.touched}
      helperText={!disableError && !!state.error && state.touched && state.error}
    />
  );
};

export default memo(FormikTextField);
