import { memo } from "react";
import { Link } from "react-router-dom";

import { Box, Typography, useTheme } from "@mui/material";

import DataTable from "views/common/DataTable";
import { useTranslation } from "react-i18next";

const ContactsList = ({
  contacts,
  contactsLoading,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      filterable: false,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/contacts/${id}`}
            style={{ textDecoration: "none", color: theme.palette.text.dark, width: "100%", cursor: "pointer" }}
          >
            {id}
          </Link>
        );
      },
      minWidth: 90,
      flex: 0.2,
    },
    {
      field: "name",
      headerName: t("base.labels.contactName"),
      filterable: false,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: "phones",
      headerName: t("base.labels.phone"),
      filterable: false,
      renderCell: ({ row: { phones } }) => {
        return <Typography>{phones.at(-1)}</Typography>;
      },
      minWidth: 180,
      flex: 0.4,
    },
    {
      field: "emails",
      headerName: t("base.labels.email"),
      filterable: false,
      renderCell: ({ row: { emails } }) => {
        return <Typography>{emails.at(-1)}</Typography>;
      },
      minWidth: 180,
      flex: 0.3,
    },
    {
      field: "country",
      headerName: t("base.labels.country"),
      filterable: false,
      renderCell: ({ row: { country } }) => {
        return <Typography>{country?.name || "-"}</Typography>;
      },
      minWidth: 180,
      flex: 0.3,
    },
    {
      field: "city",
      headerName: t("base.labels.city"),
      filterable: false,
      renderCell: ({ row: { city } }) => {
        return <Typography>{city?.name || "-"}</Typography>;
      },
      minWidth: 180,
      flex: 0.3,
    },
  ];
  return (
    <Box height="calc(100% - 76px)" sx={{ backgroundColor: "extra.white", p: 2, borderRadius: 2 }}>
      <DataTable
        list={contacts.content}
        totalElements={contacts.totalElements}
        columns={columns}
        isLoading={contactsLoading}
        totalPages={contacts.totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
      />
    </Box>
  );
};

export default memo(ContactsList);
