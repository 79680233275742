import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Button, CircularProgress, Stack, TextField } from "@mui/material";

import store from "store";
import { userSelectors } from "store/ducks/user";
import { leadActions, leadThunks } from "store/ducks/lead";

import InteractionActionSelector from "./InteractionActionSelector";
import ActionsNewTask from "./ActionsNewTask";

const InteractionActions = ({ lead }) => {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState("comment");
  const user = useSelector(userSelectors.getCurrentUser());

  const responsible = [];
  if (user) responsible.push(user);

  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleAddComment = async () => {
    setIsLoading(true);

    const response = await store.dispatch(
      leadThunks.addComment({
        id: lead.id,
        comment: value,
      })
    );

    if (!response.error) {
      setIsLoading(false);
      store.dispatch(leadActions.updateItem({ collection: "lead", data: response.payload }));
      setValue("");
      toast.success(t("messages.success.toast.addComment"));
    }
  };

  return (
    <Stack
      mt={2}
      sx={{ backgroundColor: "secondary.lighter" }}
      direction="row"
      alignItems="center"
      borderRadius={2}
      gap={2}
      p={1}
    >
      {selectedItem === "comment" && (
        <>
          <TextField
            variant="outlined"
            placeholder={t("base.placeholders.comment")}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InteractionActionSelector selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
              ),
            }}
            sx={{
              width: "100%",
              borderRadius: 2,
              backgroundColor: "extra.white",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "secondary.light",
                },
              },
            }}
          />

          <Button
            variant="contained"
            onClick={handleAddComment}
            sx={{ maxWidth: "140px" }}
            disabled={isLoading || !value}
            startIcon={isLoading && <CircularProgress size={16} sx={{ mr: 1, color: "extra.white" }} />}
          >
            {isLoading ? t("base.buttons.adding") : t("base.buttons.add")}
          </Button>
        </>
      )}
      {selectedItem === "task" && (
        <ActionsNewTask
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          leads={[lead]}
          lead={lead}
          responsible={responsible}
          t={t}
        />
      )}
    </Stack>
  );
};

export default memo(InteractionActions);
