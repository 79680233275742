import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  Button,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import store from "store";
import { pipelineActions, pipelineThunks } from "store/ducks/pipeline";

import Modal from "views/common/modal/Modal";
import QuickAddInput from "views/common/QuickAddInput";

import useQuickAddInput from "hooks/useQuickAddInput";
import useModal from "hooks/useModal";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { AttentionIcon } from "theme/icons";
import { DESKTOP_VIEW } from "utils/constants";
import { MoreVert } from "@mui/icons-material";

const StagesListItem = ({ stage, size, pipeline }) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const [modalOpen, openModal, closeModal] = useModal();
  const [editMode, newValue, setNewValue, enableEditMode, disableEditMode, isLoading, setIsLoading] =
    useQuickAddInput();

  const editIconSize = size === "smallSize" ? 16 : 20;
  const deleteIconSize = size === "smallSize" ? 16 : 24;

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: stage.id,
    data: {
      type: "Stage",
      stage,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const handleMouseEnter = () => {
    if (!isDesktop) return;
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    if (!isDesktop) return;
    setIsHovered(false);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /** --- remove hover flag from the item if we dragging stage ---  */
  useEffect(() => {
    isDragging && handleMouseLeave();
  }, [isDragging]);

  const handleUpdateStage = async () => {
    setIsLoading(true);
    /** --- remove hover flag from the item in order to avoid
     * the case when the icons are shown after adding new stage ---  */
    handleMouseLeave();
    const response = await store.dispatch(pipelineThunks.updateStage({ ...stage, name: newValue }));

    if (!response.error) {
      setIsLoading(false);
      toast.success(t("messages.success.toast.updateStage"));
    }
    handleMenuClose();
  };

  const handleDeleteStage = async () => {
    setIsLoading(true);
    const response = await store.dispatch(pipelineThunks.deleteStage(stage));

    if (!response.error) {
      const updatedPipeline = {
        ...pipeline,
        stages: pipeline.stages.filter((item) => item.id !== stage.id),
      };
      setIsLoading(false);
      toast.success(t("messages.success.toast.deleteStage"));
      /** update data of current pipeline in store  */
      store.dispatch(pipelineActions.updateItem({ collection: "pipeline", data: updatedPipeline }));
      /** update data of pipelines list in store  */
      store.dispatch(pipelineActions.replaceItem({ collection: "pipelines", data: updatedPipeline }));
      closeModal();
    }
  };
  const handleEditClick = () => {
    enableEditMode(stage.name);
    handleMenuClose();
  };

  /** extra condition to validate if we handle existing pipeline, not a new one.
   * w/o this condition isDragging return true and new created stage has styles as "overlay item for dragging"
   */
  if (isDragging && pipeline.id) {
    return (
      <ListItem
        ref={setNodeRef}
        style={style}
        disableGutters
        sx={{
          border: "1px solid",
          borderColor: "success.main",
          borderRadius: size === "smallSize" ? 1 : 2,
          minWidth: size === "smallSize" ? 0 : "140px",
          maxWidth: "fit-content",
          py: size === "smallSize" ? 0.5 : 1,
        }}
      >
        <ListItemText
          primary={stage.name}
          {...attributes}
          {...listeners}
          primaryTypographyProps={{ fontSize: size === "smallSize" ? 12 : 16 }}
          sx={{
            color: "transparent",
            textAlign: "center",
            m: size === "smallSize" ? 0 : 0.5,
            px: size === "smallSize" ? 2 : 0,
          }}
        />
      </ListItem>
    );
  }

  return (
    <>
      {!editMode && (
        <>
          <ListItem
            ref={setNodeRef}
            style={style}
            disableGutters
            sx={{
              border: "1px solid",
              borderColor: "primary.main",
              borderRadius: size === "smallSize" ? 1 : 2,
              minWidth: size === "smallSize" ? 0 : "140px",
              maxWidth: "fit-content",
              py: size === "smallSize" ? 0.5 : 1,
              position: "relative",
              cursor: "grab",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleMenuClick}
            id="stage"
            aria-controls={open ? "stage-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <ListItemText
              primary={stage.name}
              {...attributes}
              {...listeners}
              primaryTypographyProps={{ fontSize: size === "smallSize" ? 12 : 16 }}
              sx={{
                color: "text.dark",
                textAlign: "center",
                m: size === "smallSize" ? 0 : 0.5,
                px: size === "smallSize" ? 2 : 0,
              }}
            />
            {isHovered && !isDragging && (
              <>
                <Tooltip title={t("base.tooltips.edit")}>
                  <IconButton
                    onClick={handleEditClick}
                    sx={{
                      p: 0,
                      position: "absolute",
                      top: -8,
                      left: -8,
                      color: "primary.light",
                      transition: "0.3s",
                      backgroundColor: "extra.white",
                      "&:hover": { color: "primary.main", backgroundColor: "extra.white" },
                    }}
                  >
                    <NoteAltOutlinedIcon sx={{ width: editIconSize, height: editIconSize }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("base.tooltips.delete")}>
                  <IconButton
                    onClick={openModal}
                    sx={{
                      p: 0,
                      position: "absolute",
                      top: -8,
                      right: -8,
                      color: "error.light",
                      transition: "0.3s",
                      backgroundColor: "extra.white",
                      "&:hover": { color: "error.main", backgroundColor: "extra.white" },
                    }}
                  >
                    <DeleteOutlineOutlinedIcon sx={{ width: deleteIconSize, height: deleteIconSize }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {!isDesktop && (
              <ListItemIcon sx={{ minWidth: 24 }}>
                <IconButton
                  sx={{
                    p: 0,
                    px: 0.5,
                    color: "primary.main",
                  }}
                  onClick={handleMenuClick}
                >
                  <MoreVert fontSize="small" />
                </IconButton>
              </ListItemIcon>
            )}
          </ListItem>
          {!isDesktop && (
            <Menu
              id="stage-menu"
              anchorEl={anchorEl}
              open={open}
              MenuListProps={{
                "aria-labelledby": "stage",
                role: "listbox",
              }}
              PaperProps={{
                sx: { mt: 0.5, px: 0.5 },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              onClose={handleMenuClose}
            >
              <MenuItem sx={{ fontSize: 14, minHeight: 0, py: 0 }} onClick={handleEditClick}>
                {t("base.tooltips.edit")}
              </MenuItem>
              <Divider sx={{ bgcolor: "background.default", width: "100%", py: 0 }} />
              <MenuItem sx={{ fontSize: 14, minHeight: 0, py: 0, color: "error.main" }} onClick={openModal}>
                {t("base.tooltips.delete")}
              </MenuItem>
            </Menu>
          )}
          <Modal
            title={`${t("base.dictionary.delete")} "${stage.name}"`}
            open={modalOpen}
            onClose={closeModal}
            icon={<AttentionIcon />}
            actions={
              <>
                <Button sx={{ px: 8 }} onClick={closeModal}>
                  {t("base.buttons.cancel")}
                </Button>
                <Button
                  variant="contained"
                  sx={{ px: 8 }}
                  type="button"
                  onClick={handleDeleteStage}
                  disabled={isLoading}
                  color="error"
                >
                  {t("base.buttons.delete")}
                </Button>
              </>
            }
          />
        </>
      )}
      {editMode && (
        <ListItem disablePadding sx={{ width: "fit-content" }}>
          <QuickAddInput
            newValue={newValue}
            setNewValue={setNewValue}
            disableEditMode={disableEditMode}
            handleActionSubmit={handleUpdateStage}
            placeholder={t("base.placeholders.newStageName")}
            isLoading={isLoading}
            size={size}
          />
        </ListItem>
      )}
    </>
  );
};

export default memo(StagesListItem);
