import { memo } from "react"
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

import { FormColumnTitle, FormColumnWrapper } from "views/common/styledComponents";
import Error from "views/common/Error";

import Account from "./Account";
import PersonalInformation from "./PersonalInformation";
import LoginDetails from "./LoginDetails";
import General from "./General";

const DesktopProfileView = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" gap={2} mt={2} width="100%" minHeight="360px" maxHeight="424px">
      <FormColumnWrapper sx={{ width: "25%" }}>
        <FormColumnTitle>{t("types.settings.profile.account")}</FormColumnTitle>
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <Account />
        </ErrorBoundary>
      </FormColumnWrapper>
      <FormColumnWrapper sx={{ width: "25%" }}>
        <FormColumnTitle>{t("types.settings.profile.personalInfo")}</FormColumnTitle>
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <PersonalInformation />
        </ErrorBoundary>
      </FormColumnWrapper>
      <FormColumnWrapper sx={{ width: "25%" }}>
        <FormColumnTitle>{t("types.settings.profile.login")}</FormColumnTitle>
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <LoginDetails />
        </ErrorBoundary>
      </FormColumnWrapper>
      <FormColumnWrapper sx={{ width: "25%" }}>
        <FormColumnTitle>{t("types.settings.profile.general")}</FormColumnTitle>
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <General />
        </ErrorBoundary>
      </FormColumnWrapper>
    </Stack>
  );
};

export default memo(DesktopProfileView);
