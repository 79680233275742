import { memo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "@sentry/react";

import { Box, LinearProgress, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import filters from "filters";
import { PIPELINE_STATUSES } from "utils/constants";
import Error from "views/common/Error";

const PipelinesList = ({ pipelines, loading }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = [
    {
      field: "id",
      headerName: "Id",
      filterable: false,
      hideable: false,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/pipelines/${id}`}
            style={{
              textDecoration: "none",
              color: theme.palette.text.dark,
              width: "100%",
              cursor: "pointer",
            }}
          >
            {id}
          </Link>
        );
      },
      minWidth: 90,
      flex: 0.3,
    },
    {
      field: "name",
      headerName: t("base.labels.name"),
      filterable: false,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: "description",
      headerName: t("base.labels.description"),
      filterable: false,
      minWidth: 200,
      flex: 0.4,
    },
    {
      field: "pageLink",
      headerName: t("base.labels.pageLink"),
      filterable: false,
      minWidth: 200,
      flex: 0.4,
    },
    {
      field: "status",
      headerName: t("base.labels.status"),
      filterable: false,
      renderCell: ({ row: { status } }) => {
        const pipeStatus = PIPELINE_STATUSES(theme).find((item) => item.name === status.name);
        return <Typography color={pipeStatus?.color}>{status.label}</Typography>;
      },
      minWidth: 120,
      flex: 0.3,
    },
    {
      field: "createdAt",
      headerName: t("base.labels.date"),
      filterable: false,
      renderCell: ({ row: { createdAt } }) => {
        const shortDate = filters.date(createdAt);
        return <Typography>{shortDate}</Typography>;
      },
      minWidth: 110,
      flex: 0.3,
    },
  ];

  return (
    <Box height="calc(100% - 76px)" sx={{ backgroundColor: "extra.white", p: 2, borderRadius: 2 }}>
      <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
        <DataGrid
          rows={pipelines}
          rowCount={pipelines.length}
          columns={columns}
          loading={loading}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          rowHeight={44}
          autoPageSize
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd")}
          checkboxSelection
        />
      </ErrorBoundary>
    </Box>
  );
};

export default memo(PipelinesList);
