import { Stack, Box } from "@mui/material";
import { memo } from "react";

const ColumnIndicators = ({ columns, currentIndex }) => {
  return (
    <Stack direction="row" justifyContent="center" spacing={1} sx={{ mt: 1 }}>
      {columns.map((_, index) => (
        <Box
          key={index}
          sx={{
            width: "100%",
            height: 5,
            borderRadius: 1,
            backgroundColor: currentIndex === index ? "primary.main" : "extra.white",
          }}
        />
      ))}
    </Stack>
  );
};

export default memo(ColumnIndicators);
