import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import Error from "views/common/Error";
import { FormColumnWrapper } from "views/common/styledComponents";
import Account from "./Account";
import PersonalInformation from "./PersonalInformation";
import LoginDetails from "./LoginDetails";
import General from "./General";

export const COLUMNS = ["account", "personalInfo", "login", "general"];

const MobileProfileView = () => {
  const { t } = useTranslation();
  const [selectedColumn, setSelectedColumn] = useState("account");

  const handleChange = (event, newValue) => {
    if (newValue === selectedColumn || newValue === null) return;
    setSelectedColumn(newValue);
  };
  return (
    <Stack>
      <Stack m={1} mt={0}>
        <ToggleButtonGroup
          value={selectedColumn}
          exclusive
          onChange={handleChange}
          sx={{
            mt: { xs: 1, sm: 2, lg: 0 },
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            msOverflowStyle: "none",
            scrollbarWidth: "none", 
          }}
        >
          {COLUMNS.map((column) => {
            return (
              <ToggleButton value={column} key={column}>
                {t(`types.settings.profile.${column}`)}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Stack>
      {selectedColumn === "account" && (
        <FormColumnWrapper>
          <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
            <Account />
          </ErrorBoundary>
        </FormColumnWrapper>
      )}
      {selectedColumn === "personalInfo" && (
        <FormColumnWrapper>
          <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
            <PersonalInformation />
          </ErrorBoundary>
        </FormColumnWrapper>
      )}
      {selectedColumn === "login" && (
        <FormColumnWrapper>
          <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
            <LoginDetails />
          </ErrorBoundary>
        </FormColumnWrapper>
      )}
      {selectedColumn === "general" && (
        <FormColumnWrapper>
          <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
            <General />
          </ErrorBoundary>
        </FormColumnWrapper>
      )}
    </Stack>
  );
};

export default memo(MobileProfileView);
