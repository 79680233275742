const getContacts = () => (state) => state.contact.contacts.data;
const contactsLoading = () => (state) => state.contact.contacts.isLoading;
const getContactsError = () => (state) => state.contact.contacts.error;

const getContact = () => (state) => state.contact.contact.data;
const contactLoading = () => (state) => state.contact.contact.isLoading;
const getContactError = () => (state) => state.contact.contact.error;

export default {
  getContacts,
  contactsLoading,
  getContactsError,
  getContact,
  contactLoading,
  getContactError,
};
