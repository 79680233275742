const getTasks = () => (state) => state.task.tasks.data;
const tasksLoading = () => (state) => state.task.tasks.isLoading;
const getTasksError = () => (state) => state.task.tasks.error;

const getTask = () => (state) => state.task.task.data;
const taskLoading = () => (state) => state.task.task.isLoading;
const getTaskError = () => (state) => state.task.task.error;

const getActiveTasks = () => (state) => state.task.activeTasks.data;
const activeTasksLoading = () => (state) => state.task.activeTasks.isLoading;
const getActiveTasksError = () => (state) => state.task.activeTasks.error;

export default {
  getTasks,
  tasksLoading,
  getTasksError,
  getTask,
  taskLoading,
  getTaskError,
  getActiveTasks,
  activeTasksLoading,
  getActiveTasksError,
};
