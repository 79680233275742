import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api";
import { addItem, deleteItem, replaceItem } from "./slice";

const fetchEnums = createAsyncThunk("dictionary/fetchEnums", () => {
  return api.get("/dictionaries/enums");
});

/**------------- PROGRAMS ------------------- */
const fetchPrograms = createAsyncThunk("dictionary/fetchPrograms", () => {
  return api.get("/dictionaries/programs");
});

/**------------- MANAGERS ------------------- */
const fetchManagers = createAsyncThunk("dictionary/fetchManagers", () => {
  return api.get("/dictionaries/managers");
});

/**------------- EMPLOYEES ------------------- */
const fetchEmployees = createAsyncThunk("dictionary/fetchEmployees", () => {
  return api.get("/dictionaries/employees");
});

/**-------------MARKETING CHANNELS ------------------- */
const fetchMarketingChannels = createAsyncThunk("dictionary/fetchMarketingChannels", () => {
  return api.get("/dictionaries/marketing-channels");
});

const createMarketingChannel = createAsyncThunk("dictionary/createMarketingChannel", (data, { dispatch }) => {
  return api.post("/dictionaries/marketing-channels", data).then((res) => {
    dispatch(addItem({ collection: "marketingChannels", data: res }));
    return res;
  });
});

const updateMarketingChannel = createAsyncThunk("dictionary/updateMarketingChannel", (data, { dispatch }) => {
  return api.put(`/dictionaries/marketing-channels/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "marketingChannels", data: res }));
    return res;
  });
});

const deleteMarketingChannel = createAsyncThunk("dictionary/deleteMarketingChannel", (data, { dispatch }) => {
  return api.delete(`/dictionaries/marketing-channels/${data.id}`).then((res) => {
    dispatch(deleteItem({ collection: "marketingChannels", data }));
    return res;
  });
});

/**-------------LEAD CLOSE REASONS ------------------- */
const fetchLeadCloseReasons = createAsyncThunk("dictionary/fetchLeadCloseReasons", () => {
  return api.get("/dictionaries/lead-close-reasons");
});

const createLeadCloseReason = createAsyncThunk("dictionary/createLeadCloseReason", (data, { dispatch }) => {
  return api.post("/dictionaries/lead-close-reasons", data).then((res) => {
    dispatch(addItem({ collection: "leadCloseReasons", data: res }));
    return res;
  });
});

const updateLeadCloseReason = createAsyncThunk("dictionary/updateLeadCloseReason", (data, { dispatch }) => {
  return api.put(`/dictionaries/lead-close-reasons/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "leadCloseReasons", data: res }));
    return res;
  });
});

const deleteLeadCloseReason = createAsyncThunk("dictionary/deleteLeadCloseReason", (data, { dispatch }) => {
  return api.delete(`/dictionaries/lead-close-reasons/${data.id}`).then((res) => {
    dispatch(deleteItem({ collection: "leadCloseReasons", data }));
    return res;
  });
});

/**------------- COUNTRIES ------------------- */
const fetchCountries = createAsyncThunk("dictionary/fetchCountries", () => {
  return api.get("/dictionaries/countries");
});

const createCountry = createAsyncThunk("dictionary/createCountry", (data, { dispatch }) => {
  return api.post("/dictionaries/countries", data).then((res) => {
    dispatch(addItem({ collection: "countries", data: res }));
    return res;
  });
});

const updateCountry = createAsyncThunk("dictionary/updateCountry", (data, { dispatch }) => {
  return api.put(`/dictionaries/countries/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "countries", data: res }));
    return res;
  });
});

const deleteCountry = createAsyncThunk("dictionary/deleteCountry", (data, { dispatch }) => {
  return api.delete(`/dictionaries/countries/${data.id}`).then((res) => {
    dispatch(deleteItem({ collection: "countries", data }));
    return res;
  });
});

/**------------- CITIES ------------------- */
const fetchCities = createAsyncThunk("dictionary/fetchCities", () => {
  return api.get("/dictionaries/cities");
});

const createCity = createAsyncThunk("dictionary/createCity", (data, { dispatch }) => {
  return api.post("/dictionaries/cities", data).then((res) => {
    dispatch(addItem({ collection: "cities", data: res }));
    return res;
  });
});

const updateCity = createAsyncThunk("dictionary/updateCity", (data, { dispatch }) => {
  return api.put(`/dictionaries/cities/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "cities", data: res }));
    return res;
  });
});

const deleteCity = createAsyncThunk("dictionary/deleteCity", (data, { dispatch }) => {
  return api.delete(`/dictionaries/cities/${data.id}`).then((res) => {
    dispatch(deleteItem({ collection: "cities", data }));
    return res;
  });
});

/**------------- UNIVERSITIES ------------------- */
const fetchUniversities = createAsyncThunk("dictionary/fetchUniversities", () => {
  return api.get("/dictionaries/universities");
});

const createUniversity = createAsyncThunk("dictionary/createUniversity", (data, { dispatch }) => {
  return api.post("/dictionaries/universities", data).then((res) => {
    dispatch(addItem({ collection: "universities", data: res }));
    return res;
  });
});

const updateUniversity = createAsyncThunk("dictionary/updateUniversity", (data, { dispatch }) => {
  return api.put(`/dictionaries/universities/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "universities", data: res }));
    return res;
  });
});

const deleteUniversity = createAsyncThunk("dictionary/deleteUniversity", (data, { dispatch }) => {
  return api.delete(`/dictionaries/universities/${data.id}`).then((res) => {
    dispatch(deleteItem({ collection: "universities", data }));
    return res;
  });
});

/**------------- PROFESSIONS ------------------- */
const fetchProfessions = createAsyncThunk("dictionary/fetchProfessions", () => {
  return api.get("/dictionaries/professions");
});

const createProfession = createAsyncThunk("dictionary/createProfession", (data, { dispatch }) => {
  return api.post("/dictionaries/professions", data).then((res) => {
    dispatch(addItem({ collection: "professions", data: res }));
    return res;
  });
});

const updateProfession = createAsyncThunk("dictionary/updateProfession", (data, { dispatch }) => {
  return api.put(`/dictionaries/professions/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "professions", data: res }));
    return res;
  });
});

const deleteProfession = createAsyncThunk("dictionary/deleteProfession", (data, { dispatch }) => {
  return api.delete(`/dictionaries/professions/${data.id}`).then((res) => {
    dispatch(deleteItem({ collection: "professions", data }));
    return res;
  });
});

/**------------- EDUCATIONS ------------------- */
const fetchEducations = createAsyncThunk("dictionary/fetchEducations", () => {
  return api.get("/dictionaries/educations");
});

const createEducation = createAsyncThunk("dictionary/createEducation", (data, { dispatch }) => {
  return api.post("/dictionaries/educations", data).then((res) => {
    dispatch(addItem({ collection: "educations", data: res }));
    return res;
  });
});

const updateEducation = createAsyncThunk("dictionary/updateEducation", (data, { dispatch }) => {
  return api.put(`/dictionaries/educations/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "educations", data: res }));
    return res;
  });
});

const deleteEducation = createAsyncThunk("dictionary/deleteEducation", (data, { dispatch }) => {
  return api.delete(`/dictionaries/educations/${data.id}`).then((res) => {
    dispatch(deleteItem({ collection: "educations", data }));
    return res;
  });
});

/**------------- TASK TYPES ------------------- */
const fetchTaskTypes = createAsyncThunk("dictionary/fetchTaskTypes", () => {
  return api.get("/dictionaries/task-types");
});

const createTaskTypes = createAsyncThunk("dictionary/createTaskTypes", (data, { dispatch }) => {
  return api.post("/dictionaries/task-types", data).then((res) => {
    dispatch(addItem({ collection: "taskTypes", data: res }));
    return res;
  });
});

const updateTaskTypes = createAsyncThunk("dictionary/updateTaskTypes", (data, { dispatch }) => {
  return api.put(`/dictionaries/task-types/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "taskTypes", data: res }));
    return res;
  });
});

export default {
  fetchEnums,
  fetchPrograms,
  fetchManagers,
  fetchEmployees,
  fetchMarketingChannels,
  createMarketingChannel,
  updateMarketingChannel,
  deleteMarketingChannel,
  fetchLeadCloseReasons,
  createLeadCloseReason,
  updateLeadCloseReason,
  deleteLeadCloseReason,
  fetchCountries,
  createCountry,
  updateCountry,
  deleteCountry,
  fetchCities,
  createCity,
  updateCity,
  deleteCity,
  fetchUniversities,
  createUniversity,
  updateUniversity,
  deleteUniversity,
  fetchProfessions,
  createProfession,
  updateProfession,
  deleteProfession,
  fetchEducations,
  createEducation,
  updateEducation,
  deleteEducation,
  fetchTaskTypes,
  createTaskTypes,
  updateTaskTypes,
};
