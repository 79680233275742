import { memo, useEffect } from "react";
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Stack, useMediaQuery } from "@mui/material";

import store from "store";
import { contactSelectors, contactThunks } from "store/ducks/contact";
import { leadSelectors, leadThunks } from "store/ducks/lead";

import Error from "views/common/Error";
import { PageWrapper } from "views/common/styledComponents";
import Loader from "views/common/Loader";
import ContactForm from "views/common/ContactForm";
import LeadsDataTable from "views/common/LeadsDataTable";

import useModal from "hooks/useModal";
import { DESKTOP_VIEW } from "utils/constants";

const ContactPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [modalOpen, openModal, closeModal] = useModal();
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const contact = useSelector(contactSelectors.getContact());
  const contactLoading = useSelector(contactSelectors.contactLoading());

  const contactLeads = useSelector(leadSelectors.getContactLeads());
  const contactLeadsLoading = useSelector(leadSelectors.contactLeadsLoading());

  useEffect(() => {
    store.dispatch(contactThunks.fetchContact(id));
    store.dispatch(leadThunks.fetchContactLeads(id));
  }, [id]);

  return (
    <PageWrapper>
      {contactLoading && contactLeadsLoading ? (
        <Loader />
      ) : (
        <Stack gap={isDesktop ? 2 : 1} height="100%">
          <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
            <ContactForm t={t} contact={contact} openModal={openModal} closeModal={closeModal} modalOpen={modalOpen} />
          </ErrorBoundary>
          <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
            <LeadsDataTable
              list={contactLeads}
              totalElements={contactLeads.length}
              isLoading={contactLeadsLoading}
              hideFooter
              autoPageSize
            />
          </ErrorBoundary>
        </Stack>
      )}
    </PageWrapper>
  );
};

export default memo(ContactPage);
