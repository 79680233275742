import { memo } from "react";

const { Typography } = require("@mui/material");
const { INTERACTION_MESSAGES } = require("utils/constants");

const InteractionMessage = ({ item, t }) => {
  const message = INTERACTION_MESSAGES[item.type];
  return message ? (
    <Typography variant="capture" color="text.main">
      {message(item, t)}
    </Typography>
  ) : (
    ""
  );
};

export default memo(InteractionMessage);
