import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, IconButton, Stack, TextField, Tooltip, useTheme } from "@mui/material";

import { CloseIcon, DoneIcon } from "theme/icons";

const QuickAddInput = ({
  newValue,
  setNewValue,
  handleActionSubmit,
  placeholder,
  disableEditMode,
  isLoading,
  size,
  width,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [validationError, setValidationError] = useState(false);

  const SIZES = {
    smallSize: {
      icon: 18,
      font: 12,
    },
    mediumSize: {
      icon: 20,
      font: 14,
    },
    largeSize: {
      icon: 24,
      font: 16,
    },
  };

  const iconsSize = {
    width: SIZES[size].icon,
    height: SIZES[size].icon,
  };

  const handleChangeNewValue = (e) => {
    setNewValue(e.target.value);
    if (validationError && newValue.length > 0) {
      setValidationError(false);
    }
  };

  const handleSubmit = async () => {
    if (!newValue) {
      setValidationError(true);
      return;
    }
    await handleActionSubmit();
    setValidationError(false);
    disableEditMode();
  };

  return (
    <TextField
      value={newValue}
      autoFocus
      onChange={(e) => handleChangeNewValue(e)}
      placeholder={placeholder}
      error={validationError}
      helperText={validationError ? t("messages.errors.validation.required") : ""}
      sx={{
        width: width,
        "& input::placeholder": {
          fontSize: SIZES[size].font,
        },
        "& input": {
          fontSize: SIZES[size].font,
        },
      }}
      InputProps={{
        endAdornment: (
          <Stack direction="row" gap={0.5}>
            <Tooltip title={t("base.tooltips.save")}>
              <IconButton disabled={isLoading} onClick={handleSubmit} sx={{ p: 0 }}>
                {isLoading ? (
                  <CircularProgress size={18} />
                ) : (
                  <DoneIcon fill={theme.palette.success.main} style={iconsSize} />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title={t("base.tooltips.cancel")}>
              <IconButton onClick={disableEditMode} sx={{ p: 0 }}>
                <CloseIcon fill={theme.palette.error.main} style={iconsSize} />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      }}
    />
  );
};

export default memo(QuickAddInput);
