import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, withFormik } from "formik";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Button, CircularProgress, Divider, Stack, TextField } from "@mui/material";

import { FormColumnWrapper } from "views/common/styledComponents";
import FormikAutocomplete from "views/form/FormikAutocomplete";
import FormikTextField from "views/form/FormikTextField";
import FormikDatePicker from "views/form/FormikDatePicker";

import store from "store";
import { dictionarySelectors } from "store/ducks/dictionary";
import { leadThunks } from "store/ducks/lead";
import { pipelineSelectors, pipelineThunks } from "store/ducks/pipeline";

import { getShortName } from "utils/helpers";
import { leadDetailsSchema } from "utils/schema";
import filters from "filters";

const LeadDetailsForm = ({ values, isSubmitting, dirty, lead }) => {
  const { t } = useTranslation();

  const programs = useSelector(dictionarySelectors.getPrograms());
  const programsLoading = useSelector(dictionarySelectors.programsLoading());
  const marketingChannels = useSelector(dictionarySelectors.getMarketingChannels());
  const marketingChannelsLoading = useSelector(dictionarySelectors.marketingChannelsLoading());
  const managers = useSelector(dictionarySelectors.getManagers());
  const managersLoading = useSelector(dictionarySelectors.managersLoading());
  const reasonOfClosing = useSelector(dictionarySelectors.getLeadCloseReasons());
  const reasonOfClosingLoading = useSelector(dictionarySelectors.leadCloseReasonsLoading());

  const enums = useSelector(dictionarySelectors.getEnums());
  const enumsLoading = useSelector(dictionarySelectors.enumsLoading());
  const pipelines = useSelector(pipelineSelectors.getPipelines());
  const pipelinesLoading = useSelector(pipelineSelectors.pipelinesLoading());

  const pipelinesWithArchived = pipelines.some((pipe) => pipe.id === lead?.pipeline.id) ? [...pipelines] : [...pipelines, lead.pipeline]
  

  useEffect(() => {
    pipelines.length === 0 && store.dispatch(pipelineThunks.fetchPipelines());
  }, [pipelines]);

  const stages = pipelines?.find((pipe) => pipe.id === values?.pipeline?.id)?.stages || [];
  const stagesWithArchivedPipe = pipelines.some((pipe) => pipe.id === lead?.pipeline.id) ? stages : [... stages, lead.stage] ;

  return (
    <Form id="leadDetailsForm" style={{ height: "100%" }}>
      <Stack height="100%" justifyContent="space-between">
        <FormColumnWrapper sx={{ width: "100%", overflowY: "auto" }}>
          <FormikTextField name="name" placeholder={t("base.placeholders.name")} label={t("base.labels.leadName")} />
          <FormikAutocomplete
            name="responsible"
            placeholder={t("base.placeholders.choose")}
            label={t("base.labels.responsible")}
            options={managers}
            getCustomLabel={getShortName}
            loading={managersLoading}
          />
          <FormikAutocomplete
            name="pipeline"
            placeholder={t("base.placeholders.choose")}
            label={t("base.labels.pipeline")}
            options={pipelinesWithArchived}
            loading={pipelinesLoading}
          />
          <FormikAutocomplete
            name="stage"
            placeholder={t("base.placeholders.choose")}
            label={t("base.labels.stage")}
            options={stagesWithArchivedPipe}
            loading={pipelinesLoading}
            defaultValue={!values.stage && values.pipeline && stages?.[0]}
          />
          <FormikAutocomplete
            name="status"
            placeholder={t("base.placeholders.choose")}
            label={t("base.labels.status")}
            options={enums?.activeStatuses || []}
            loading={enumsLoading}
            forReadOnly
          />
          {values.status?.name === "ARCHIVED" && (
            <FormikAutocomplete
              name="closeReason"
              placeholder={t("base.placeholders.choose")}
              label={t("base.labels.reasonOfClosing")}
              options={reasonOfClosing}
              loading={reasonOfClosingLoading}
            />
          )}
          {values.closeDate && (
            <TextField value={filters.date(values.closeDate, "dd.MM.yyyy")} label="Close date" disabled />
          )}
          <FormikDatePicker name="meetingDate" label={t("base.labels.meetDate")} />
          <FormikDatePicker name="contractDate" label={t("base.labels.contractDate")} />

          <Divider sx={{ borderColor: "primary.main", width: "100%", my: 2 }} orientation="horizontal" />
          <FormikAutocomplete
            name="format"
            placeholder={t("base.placeholders.choose")}
            label={t("base.labels.format")}
            options={enums?.groupFormats || []}
            loading={enumsLoading}
          />
          <FormikAutocomplete
            name="program"
            placeholder={t("base.placeholders.choose")}
            label={t("base.labels.program")}
            options={programs}
            loading={programsLoading}
          />
          <FormikTextField
            name="contractAmount"
            placeholder={t("base.placeholders.contractAmount")}
            label={t("base.labels.contractAmount")}
          />
          <FormikAutocomplete
            name="channel"
            placeholder={t("base.placeholders.choose")}
            label={t("base.labels.channel")}
            options={marketingChannels}
            loading={marketingChannelsLoading}
          />
          <FormikTextField
            name="sourcePage"
            placeholder={t("base.placeholders.sourcePage")}
            label={t("base.labels.sourcePage")}
          />
          <FormikTextField
            name="campaign"
            placeholder={t("base.placeholders.campaign")}
            label={t("base.labels.campaign")}
          />
          <FormikTextField name="medium" placeholder={t("base.placeholders.medium")} label={t("base.labels.medium")} />
          <FormikTextField name="source" placeholder={t("base.placeholders.source")} label={t("base.labels.source")} />
          <FormikTextField
            name="pageLink"
            placeholder={t("base.placeholders.pageLink")}
            label={t("base.labels.pageLink")}
          />
        </FormColumnWrapper>
        <FormColumnWrapper sx={{ alignItems: "center" }}>
          <Button
            variant="contained"
            type="submit"
            sx={{ maxWidth: "140px" }}
            disabled={isSubmitting || !dirty}
            startIcon={isSubmitting && <CircularProgress size={16} sx={{ mr: 1, color: "extra.white" }} />}
          >
            {isSubmitting ? t("base.buttons.saving") : t("base.buttons.save")}
          </Button>
        </FormColumnWrapper>
      </Stack>
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ lead = {} }) => ({
      id: lead?.id,
      name: lead?.name || "",
      responsible: lead?.responsible || null,
      pipeline: lead?.pipeline || null,
      stage: lead?.stage || null,
      status: lead?.status || null,
      closeReason: lead?.closeReason || null,
      closeDate: lead?.closeDate || "",
      meetingDate: lead?.meetingDate || null,
      contractDate: lead?.contractDate || null,
      format: lead?.format || null,
      program: lead?.program || null,
      contractAmount: lead?.contractAmount || "",
      channel: lead?.channel || null,
      sourcePage: lead?.sourcePage || "",
      campaign: lead?.campaign || "",
      medium: lead?.medium || "",
      source: lead?.source || "",
      pageLink: lead?.pageLink || "",
    }),
    validationSchema: leadDetailsSchema,
    handleSubmit: async (values, { props, setSubmitting }) => {
      const response = await store.dispatch(leadThunks.updateLead(values));

      if (!response.error) {
        toast.success(props.t("messages.success.toast.updateLead"));
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
  })(LeadDetailsForm)
);
