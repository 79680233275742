import { memo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Divider, IconButton, List, Stack, Typography } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";

import store from "store";
import DictionaryItemCard from "./DictionaryItemCard";
import useQuickAddInput from "hooks/useQuickAddInput";
import QuickAddInput from "views/common/QuickAddInput";

const DictionaryItemsList = ({ selectedDictionary, list, selectedCountry, setSelectedCountry, showItemButton }) => {
  const { t } = useTranslation();

  const [editMode, newValue, setNewValue, enableEditMode, disableEditMode, isLoading, setIsLoading] =
    useQuickAddInput();

  const handleEditClick = () => {
    enableEditMode("");
  };
  const handleCreate = async () => {
    setIsLoading(true);

    let response;

    if (selectedDictionary.name === "cities") {
      response = await store.dispatch(selectedDictionary.createItem({ name: newValue, country: selectedCountry }));
    } else response = await store.dispatch(selectedDictionary.createItem({ name: newValue }));

    if (!response.error) {
      setIsLoading(false);
      toast.success(t("messages.success.toast.add"));
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
        <Typography textAlign="center" variant="h6" color="primary.main">
          {t("types.settings.dictionaries.listOf")}{" "}
          {t(`types.settings.dictionaries.listPlural.${selectedDictionary?.name}`)}
        </Typography>
        <IconButton sx={{ color: "primary.main" }} onClick={handleEditClick}>
          <AddCircleOutline fontSize="small" />
        </IconButton>
      </Stack>
      <Divider sx={{ borderColor: "secondary.light" }} />
      <Stack gap={2} mt={1}>
        {editMode && (
          <QuickAddInput
            newValue={newValue}
            setNewValue={setNewValue}
            disableEditMode={disableEditMode}
            handleActionSubmit={handleCreate}
            placeholder={t(
              t(`base.placeholders.dictionaryNew`) +
                " " +
                t(`types.settings.dictionaries.listSingle.${selectedDictionary?.name}`)
            )}
            isLoading={isLoading}
            size="largeSize"
          />
        )}
        {list.length === 0 ? (
          <Typography variant="h6" color="text.main">
            {selectedDictionary.label} {t("messages.warning.empty")}
          </Typography>
        ) : (
          <List>
            {list.map((item) => (
              <DictionaryItemCard
                key={item.id}
                item={item}
                selectedDictionary={selectedDictionary}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                showItemButton={showItemButton}
              />
            ))}
          </List>
        )}
      </Stack>
    </>
  );
};

export default memo(DictionaryItemsList);
