import { memo } from "react";
import { useSelector } from "react-redux";
import DictionaryItemsList from "./DictionaryItemsList";

const SingleDictionary = ({ selectedDictionary }) => {
  const itemsList = useSelector(selectedDictionary.selector);

  return <DictionaryItemsList selectedDictionary={selectedDictionary} list={itemsList} />;
};

export default memo(SingleDictionary);
