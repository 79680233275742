import { memo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import store from "store";
import { pipelineThunks } from "store/ducks/pipeline";

import StagesList from "./StagesList";
import useQuickAddInput from "hooks/useQuickAddInput";

const PipelineCardStages = ({ pipeline }) => {
  const { t } = useTranslation();
  const [editMode, newValue, setNewValue, enableEditMode, disableEditMode, isLoading, setIsLoading] =
    useQuickAddInput();

  const handleAddNewStage = async () => {
    setIsLoading(true);
    const response = await store.dispatch(
      pipelineThunks.createStage({
        id: pipeline.id,
        stageData: {
          name: newValue,
        },
      })
    );

    if (!response.error) {
      setIsLoading(false);
      toast.success(t("messages.success.toast.createStage"));
    }
  };

  return (
    <Accordion sx={{ "&.MuiPaper-root.Mui-expanded": { margin: 0 } }}>
      <AccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"
        expandIcon={<ExpandMore sx={{ color: "info.main", fontSize: "18px" }} />}
        sx={{ fontSize: 12, color: "info.main" }}
      >
        {t("types.pipeline.stagePlural")}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 0,
          pt: 1,
        }}
      >
        <StagesList
          pipeline={pipeline}
          handleActionSubmit={handleAddNewStage}
          editMode={editMode}
          enableEditMode={enableEditMode}
          newValue={newValue}
          setNewValue={setNewValue}
          disableEditMode={disableEditMode}
          isLoading={isLoading}
          size="smallSize"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(PipelineCardStages);
