import { Autocomplete, Chip, TextField } from "@mui/material";
import { useField } from "formik";
import { memo, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CloseIcon } from "theme/icons";
// import { usePermissionContext } from 'services/context/permissionContext';

const FormikAutocomplete = ({
  name,
  options,
  label,
  multiple,
  getCustomLabel,
  forReadOnly,
  loading,
  defaultValue,
  fontSize = 16,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const showError = meta.touched && Boolean(meta.error);
  // const hasPermission = usePermissionContext();
  //TODO: permissions
  const getValuesFromOptions = (value) => {
    return (
      options?.filter((option) =>
        option.id ? value.some(({ id }) => option.id === id) : value.some(({ name }) => option.name === name)
      ) || []
    );
  };

  const getValueFromOptions = (value) => {
    return options?.find((option) => (option.id ? option.id === value?.id : option.name === value?.name)) || null;
  };

  useEffect(() => {
    if (defaultValue) {
      const defaultValueOption = getValueFromOptions(defaultValue);
      helpers.setValue(defaultValueOption);
    }
  }, [defaultValue, helpers]);

  const handleTagDelete = (deletedOption) => {
    const updatedValue = field.value.filter((option) => option.name !== deletedOption.name);
    helpers.setValue(updatedValue);
  };

  return (
    <Autocomplete
      {...field}
      {...rest}
      onChange={(_, value) => {
        helpers.setValue(value !== null ? value : null);
      }}
      popupIcon={<KeyboardArrowDownIcon sx={{ color: "primary.lighter" }} />}
      multiple={multiple}
      readOnly={
        // !hasPermission ||
        forReadOnly
      }
      renderTags={(value, getTagProps) =>
        value.map((option) => {
          return (
            <Chip
              deleteIcon={<CloseIcon width={20} height={20} />}
              sx={{
                fontSize: fontSize ? fontSize : 14,
                m: 0,
                height: 0,
                "& .MuiChip-label": { pl: 0, pr: 1 },
                border: "none",
              }}
              {...getTagProps({
                label: getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name,
              })}
              key={option.name || option.id}
              variant="outlined"
              label={getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name}
              onDelete={() => handleTagDelete(option)}
            />
          );
        })
      }
      loading={loading}
      options={options}
      value={multiple ? getValuesFromOptions(field.value) : getValueFromOptions(field.value)}
      getOptionLabel={(option) => {
        return getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name;
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id || option.name}>
            {getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name}
          </li>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder={rest.placeholder ? rest.placeholder : "Choose"}
            InputLabelProps={{ shrink: true }}
            label={label}
            name={name}
            error={showError}
            helperText={(meta.touched && meta.error?.id) || (meta.touched && meta.error)}
            sx={{ "& input": { fontSize: fontSize } }}
          />
        );
      }}
    />
  );
};

export default memo(FormikAutocomplete);
