import { Autocomplete, Box, Chip, TextField, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "theme/icons";
import { memo } from "react";

const CustomAutocomplete = ({
  options,
  value,
  onChange,
  label,
  getCustomLabel,
  loading,
  multiple,
  groupBy,
  width,
  maxWidth,
}) => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      options={options}
      value={value}
      popupIcon={<KeyboardArrowDownIcon sx={{ color: "primary.lighter" }} />}
      groupBy={groupBy}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id || option.name}>
            {getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name}
          </li>
        );
      }}
      getOptionLabel={(option) => {
        return getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name;
      }}
      isOptionEqualToValue={(option, value) => (option.id ? option.id === value.id : option.name === value.name)}
      renderTags={(value, getTagProps) =>
        value.map((option) => (
          <Chip
            deleteIcon={<CloseIcon width={20} height={20} />}
            sx={{
              fontSize: 14,
              m: 0,
              height: 16,
              "& .MuiChip-label": { pl: 0, pr: 1 },
              border: "none",
            }}
            {...getTagProps({
              label: getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name,
            })}
            key={option.name || option.id}
            variant="outlined"
            label={getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name}
          />
        ))
      }
      renderGroup={(params) => (
        <Box key={params.key}>
          <Box
            sx={{
              position: "sticky",
              top: -8,
              padding: "4px 10px",
              color: "primary.light",
              backgroundColor: "primary.lightest",
            }}
          >
            {params.group}
          </Box>
          <Typography>{params.children}</Typography>
        </Box>
      )}
      loading={loading}
      onChange={(_, newValue) => onChange(newValue)}
      multiple={multiple}
      sx={{ width: width ? width : "100%", maxWidth: maxWidth ? maxWidth : 380 }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label={t(label)}
          placeholder={t("base.placeholders.choose")}
        />
      )}
    />
  );
};

export default memo(CustomAutocomplete);
