const getLeads = () => (state) => state.lead.leads.data;
const leadsLoading = () => (state) => state.lead.leads.isLoading;
const getLeadsError = () => (state) => state.lead.leads.error;

const getLead = () => (state) => state.lead.lead.data;
const leadLoading = () => (state) => state.lead.lead.isLoading;
const getLeadError = () => (state) => state.lead.lead.error;

const getContactLeads = () => (state) => state.lead.contactLeads.data;
const contactLeadsLoading = () => (state) => state.lead.contactLeads.isLoading;
const getContactLeadsError = () => (state) => state.lead.contactLeads.error;

export default {
  getLeads,
  leadsLoading,
  getLeadsError,
  getLead,
  leadLoading,
  getLeadError,
  getContactLeads,
  contactLeadsLoading,
  getContactLeadsError,
};
