import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";

import { useMediaQuery } from "@mui/material";

import store from "store";
import { contactSelectors, contactThunks } from "store/ducks/contact";

import Error from "views/common/Error";
import HeaderPage from "views/common/HeaderPage";
import { PageWrapper } from "views/common/styledComponents";
import { DESKTOP_VIEW } from "utils/constants";
import ContactsList from "./components/ContactsList";
import HeaderPageMobile from "views/common/HeaderPageMobile";

const ContactsPage = () => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const { t } = useTranslation();

  const contacts = useSelector(contactSelectors.getContacts());
  const contactsLoading = useSelector(contactSelectors.contactsLoading());

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get("page") || 0);
  const pageSizeQuery = parseInt(searchParams.get("size") || 15);
  const searchQuery = searchParams.get("q") || "";
  const sortByQuery = searchParams.get("sortBy") || "";
  const sortDirectionQuery = searchParams.get("direction") || "";

  useEffect(() => {
    query && store.dispatch(contactThunks.fetchContacts(`?${query}`));
  }, [query]);

  return (
    <PageWrapper>
      {isDesktop ? (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPage title={t("types.contact.namePlural")} isSearchbarShow />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPageMobile isSearchbarShow />
        </ErrorBoundary>
      )}
      <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
        <ContactsList
          contacts={contacts}
          contactsLoading={contactsLoading}
          setSearchParams={setSearchParams}
          pageQuery={pageQuery}
          pageSizeQuery={pageSizeQuery}
          searchQuery={searchQuery}
          sortByQuery={sortByQuery}
          sortDirectionQuery={sortDirectionQuery}
        />
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default memo(ContactsPage);
