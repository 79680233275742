import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import loadDataInitialState from "store/utils/loadDataInitialState";
import loadDataExtraReducer from "store/utils/loadDataExtraReducer";

const dictionarySlice = createSlice({
  name: "dictionarySlice",
  initialState: {
    enums: loadDataInitialState([]),
    marketingChannels: loadDataInitialState([]),
    leadCloseReasons: loadDataInitialState([]),
    countries: loadDataInitialState([]),
    cities: loadDataInitialState([]),
    managers: loadDataInitialState([]),
    programs: loadDataInitialState([]),
    universities: loadDataInitialState([]),
    professions: loadDataInitialState([]),
    educations: loadDataInitialState([]),
    employees: loadDataInitialState([]),
    taskTypes: loadDataInitialState([])
  },
  reducers: {
    addItem(state, action) {
      state[action.payload.collection].data.push(action.payload.data);
    },
    replaceItem(state, action) {
      const index = state[action.payload.collection].data.findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data[index] = action.payload.data;
    },
    deleteItem(state, action) {
      const index = state[action.payload.collection].data.findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchEnums, "enums");
    loadDataExtraReducer(builder, thunks.fetchMarketingChannels, "marketingChannels");
    loadDataExtraReducer(builder, thunks.fetchLeadCloseReasons, "leadCloseReasons");
    loadDataExtraReducer(builder, thunks.fetchCountries, "countries");
    loadDataExtraReducer(builder, thunks.fetchCities, "cities");
    loadDataExtraReducer(builder, thunks.fetchManagers, "managers");
    loadDataExtraReducer(builder, thunks.fetchPrograms, "programs");
    loadDataExtraReducer(builder, thunks.fetchUniversities, "universities");
    loadDataExtraReducer(builder, thunks.fetchProfessions, "professions");
    loadDataExtraReducer(builder, thunks.fetchEducations, "educations");
    loadDataExtraReducer(builder, thunks.fetchEmployees, "employees");
    loadDataExtraReducer(builder, thunks.fetchTaskTypes, "taskTypes");
  },
});

export const { addItem, replaceItem, deleteItem } = dictionarySlice.actions;
export default dictionarySlice.reducer;
