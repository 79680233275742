import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";

import { userSelectors } from "store/ducks/user";
import { memo } from "react";

const PersonalInformation = () => {
  const user = useSelector(userSelectors.getCurrentUser());
  const { name, lastName, secondName, phones } = user.contact;

  const { t } = useTranslation();

  return (
    <>
      <TextField
        label={t("base.labels.lastName")}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
        }}
        value={lastName}
      />
      <TextField
        label={t("base.labels.name")}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
        }}
        value={name}
      />
      <TextField
        label={t("base.labels.secondName")}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
        }}
        value={secondName}
      />
      {phones?.map((phone) => (
        <TextField
          key={phone}
          label={t("base.labels.phone")}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
          }}
          value={phone}
        />
      ))}
    </>
  );
};

export default memo(PersonalInformation);
