import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import { useSelector } from "react-redux";

import store from "store";
import { pipelineSelectors, pipelineThunks } from "store/ducks/pipeline";

import Error from "views/common/Error";
import { PipelineForm } from "./components";
import Loader from "views/common/Loader";

import useModal from "hooks/useModal";

const PipelinePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [modalOpen, openModal, closeModal] = useModal();

  const pipelineData = useSelector(pipelineSelectors.getPipeline());
  const pipeLineLoading = useSelector(pipelineSelectors.pipelineLoading());

  useEffect(() => {
    store.dispatch(pipelineThunks.fetchPipeline(id));
  }, [id]);

  return (
    <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
      {pipeLineLoading ? (
        <Loader />
      ) : (
        <PipelineForm
          pipelineData={pipelineData}
          modalOpen={modalOpen}
          closeModal={closeModal}
          openModal={openModal}
          t={t}
        />
      )}
    </ErrorBoundary>
  );
};

export default memo(PipelinePage);
