import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from "react-router-dom";
import AuthCheck from "./AuthCheck";
import Login from "views/pages/auth/Login";
import AuthContainer from "views/containers/AuthContainer";
import ForgotPassword from "../views/pages/auth/ForgotPassword";
import ResetPassword from "../views/pages/auth/ResetPassword";
import Page404 from "../views/pages/error/Page404";
import HomePage from "../views/pages/home/HomePage";
import Layout from "views/layouts/Layout";
import LeadsPage from "views/pages/leads/LeadsPage";
import LeadPage from "views/pages/leads/LeadPage";
import NewLeadPage from "views/pages/leads/NewLeadPage";
import PipelinesPage from "views/pages/pipelines/PipelinesPage";
import PipelinePage from "views/pages/pipelines/PipelinePage";
import NewPipelinePage from "views/pages/pipelines/NewPipelinePage";
import SettingsLayout from "views/layouts/SettingsLayout";
import ProfilePage from "views/pages/settings/profile/ProfilePage";
import DictionariesPage from "views/pages/settings/dictionary/DictionariesPage";
import TasksPage from "views/pages/tasks/TasksPage";
import TaskPage from "views/pages/tasks/TaskPage";
import NewTaskPage from "views/pages/tasks/NewTaskPage";
import ContactsPage from "views/pages/contacts/ContactsPage";
import ContactPage from "views/pages/contacts/ContactPage";

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route
      key="AuthCheck"
      path="/"
      element={
        <AuthCheck>
          <AuthContainer />
        </AuthCheck>
      }
    >
      <Route path="/" element={<Layout />} exact>
        <Route index element={<Navigate to="/home" replace />} />
        <Route path="home" element={<HomePage />} />
        <Route path="leads" element={<LeadsPage />} />
        <Route path="leads/pipeline/:pipelineId" element={<LeadsPage />} />
        <Route path="leads/:id" element={<LeadPage />} />
        <Route path="leads/new" element={<NewLeadPage />} />
        <Route path="pipelines" element={<PipelinesPage />} />
        <Route path="pipelines/:id" element={<PipelinePage />} />
        <Route path="pipelines/new" element={<NewPipelinePage />} />
        <Route path="tasks" element={<TasksPage />} />
        <Route path="tasks/:id" element={<TaskPage />} />
        <Route path="tasks/new" element={<NewTaskPage />} />
        <Route path="contacts" element={<ContactsPage />} />
        <Route path="contacts/:id" element={<ContactPage />} />
        <Route path="settings" element={<SettingsLayout />} exact>
          <Route index element={<Navigate to="profile" />} replace />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="dictionaries" element={<DictionariesPage />} />
        </Route>
      </Route>
    </Route>,
    <Route key="login" path="/login" element={<Login />} exact />,
    <Route key="forgot" path="/forgot" element={<ForgotPassword />} exact />,
    <Route key="reset" path="/reset/:token" element={<ResetPassword />} exact />,
    <Route key="404" path="*" element={<Page404 />} />,
  ])
);

export default router;
