import { memo } from "react";
import { Link } from "react-router-dom";

import { Divider, Stack, Typography, Link as MuiLink, useTheme } from "@mui/material";

import PipelineCardStages from "./PipelineCardStages";
import { PIPELINE_STATUSES } from "utils/constants";
import filters from "filters";

const PipeLineCard = ({ pipeline }) => {
  const theme = useTheme();
  const statusIcon = PIPELINE_STATUSES(theme).find((status) => status.name === pipeline?.status?.name);

  return (
    <Stack gap={1} sx={{ backgroundColor: "extra.white", p: 1, borderRadius: 1, height: "fit-content" }}>
      <Stack width="inherit" direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant="body2"
          color="text.main"
          fontWeight={600}
          component={Link}
          to={`/pipelines/${pipeline.id}`}
          sx={{
            textDecoration: "none",
            "&:hover": { color: "text.dark", textDecoration: "underline", cursor: "pointer" },
          }}
        >
          {pipeline?.name}
        </Typography>
        {statusIcon?.icon}
      </Stack>
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <MuiLink sx={{ cursor: "pointer", color: "info.main", textDecoration: "none", fontSize: 12 }}>
            {pipeline.pageLink}
          </MuiLink>
          <Typography variant="capture" color="text.light">
            {filters.date(pipeline.createdAt)}
          </Typography>
        </Stack>
        <Divider sx={{ borderColor: "background.default", mt: 1 }} />
      </Stack>
      <PipelineCardStages pipeline={pipeline} />
    </Stack>
  );
};

export default memo(PipeLineCard);
