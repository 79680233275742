import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import TaskForm from "./components/TaskForm";
import useModal from "hooks/useModal";

const NewTaskPage = () => {
  const { t } = useTranslation();
  const [modalOpen, openModal, closeModal] = useModal();
  const navigate = useNavigate();
  const [files, setFiles] = useState([""]);


  return (
    <TaskForm
      t={t}
      modalOpen={modalOpen}
      openModal={openModal}
      closeModal={closeModal}
      files={files}
      setFiles={setFiles}
      navigate={navigate}
    />
  );
};

export default memo(NewTaskPage);
