import { memo, useEffect, useMemo, useRef, useState } from "react";
import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ErrorBoundary } from "@sentry/react";

import { Stack, useMediaQuery } from "@mui/material";

import store from "store";
import { leadThunks } from "store/ducks/lead";
import { pipelineActions, pipelineSelectors, pipelineThunks } from "store/ducks/pipeline";

import StageCard from "./StageCard";
import Error from "views/common/Error";
import LeadCard from "./LeadCard";
import HeaderPage from "views/common/HeaderPage";
import { DESKTOP_VIEW, MOBILE_VIEW } from "utils/constants";
import HeaderPageMobile from "views/common/HeaderPageMobile";

const StagesColumns = ({
  selectedPipeline,
  setSelectedPipeline,
  pipelinesList,
  pageView,
  togglePageView,
  setPageView,
  createNewItemAction,
}) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);
  //TODO: SEARCH

  const scrollContainerRef = useRef(null);
  const [currentStageIndex, setCurrentStageIndex] = useState(0);

  const handleScroll = () => {
    if (!scrollContainerRef.current) return;

    const scrollLeft = scrollContainerRef.current.scrollLeft;
    const stageWidth = scrollContainerRef.current.scrollWidth / selectedPipeline.stages.length;
    const newIndex = Math.round(scrollLeft / stageWidth);

    if (newIndex !== currentStageIndex) {
      setCurrentStageIndex(newIndex);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [currentStageIndex]);

  const [filter, setFilter] = useState({
    my: false,
  });

  const handleLeadsFilterChange = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.checked,
    });
  };

  const stagesLeads = useSelector(pipelineSelectors.getStagesLeads());

  const leads = Object.values(stagesLeads)
    .map((el) => el.data.content)
    .flat();

  const stagesId = useMemo(() => selectedPipeline.stages?.map((stage) => stage.id), [selectedPipeline.stages]);
  const leadsIds = useMemo(() => leads?.map((lead) => lead.id), [leads]);

  /** --- client side search --- */
  const [searchPhrase, setSearchPhrase] = useState("");

  const [activeStage, setActiveStage] = useState(null);
  const [activeLead, setActiveLead] = useState(null);

  /** --- client side search by contact name, email or phone --- */
  // const filteredLeads = leads?.filter((lead) => {
  //   const nameMatch = lead.contact?.name.toLowerCase().includes(searchPhrase.toLowerCase());
  //   const phoneMatch = lead.contact?.phones.some((phone) => phone.includes(searchPhrase.toLowerCase()));
  //   const emailMatch = lead.contact?.emails.some((email) => email.toLowerCase().includes(searchPhrase.toLowerCase()));
  //   return nameMatch || phoneMatch || emailMatch;
  // });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    })
  );

  const onDragStart = (event) => {
    if (event.active.data.current?.type === "Stage") {
      setActiveStage(event.active.data.current.stage);
      return;
    }

    if (event.active.data.current?.type === "Lead") {
      setActiveLead(event.active.data.current.lead);
      return;
    }
  };

  const onDragEnd = async (event) => {
    setActiveStage(null);
    setActiveLead(null);

    const { active, over } = event;

    if (!over) return;

    if (active.id === over.id) return;

    const isActiveLead = active.data.current?.type === "Lead";
    const isOverLead = over.data.current?.type === "Lead";
    const isOverStage = over.data.current?.type === "Stage";

    /** --- moving stages--- */
    if (activeStage) {
      const movedStageIndex = selectedPipeline.stages.findIndex((stage) => stage.id === active.id);
      const targetIndex = selectedPipeline.stages.findIndex((stage) => stage.id === over.id);

      const currentPipeLine = { ...selectedPipeline };

      const updatedPipeline = {
        ...selectedPipeline,
        stages: arrayMove(selectedPipeline.stages, movedStageIndex, targetIndex),
      };

      const newStagesOrder = updatedPipeline.stages.map((stage) => stage.id);

      store.dispatch(pipelineActions.replaceItem({ collection: "pipelines", data: updatedPipeline }));
      setSelectedPipeline(updatedPipeline);

      const res = await store.dispatch(pipelineThunks.sortStages(newStagesOrder));

      if (!res.error) {
        toast.success(t("messages.success.toast.reorderStage"));
      } else {
        store.dispatch(pipelineActions.replaceItem({ collection: "pipelines", data: currentPipeLine }));
        setSelectedPipeline(currentPipeLine);
      }
    }
    /** --- drag lead and drop over another lead ---  */
    if (isActiveLead && isOverLead) {
      const activeLead = leads.find((l) => l.id === active.id);
      const overLead = leads.find((l) => l.id === over.id);

      if (activeLead.stage.id === overLead.stage.id) {
        return;
      }

      const updatedLead = {
        ...activeLead,
        stage: overLead.stage,
      };

      //** --- moving lead between stages in state --- */
      store.dispatch(
        pipelineActions.moveLeadBetweenStages({
          collection: "stagesLeads",
          currentStageId: activeLead.stage.id,
          overStageId: overLead.stage.id,
          data: updatedLead,
        })
      );
      //** --- update the lead with new stage on server --- */
      const res = await store.dispatch(
        leadThunks.changeStage({
          id: activeLead.id,
          stage: over.data.current.lead.stage,
        })
      );

      if (!res.error) {
        toast.success("Lead's stage changed");
      } else {
        //** --- if error of updating stage in lead on server we move back lead to previous stage in state ---  */
        store.dispatch(
          pipelineActions.moveLeadBetweenStages({
            collection: "stagesLeads",
            currentStageId: overLead.stage.id,
            overStageId: activeLead.stage.id,
            data: activeLead,
          })
        );
      }
    }
    /** --- drag lead and drop over empty stage ---  */
    if (isActiveLead && isOverStage) {
      if (active.data.current.lead.stage.id === over.id) return;

      const updatedLead = {
        ...activeLead,
        stage: over.data.current.stage,
      };
      //** --- moving lead between stages in state --- */
      store.dispatch(
        pipelineActions.moveLeadBetweenStages({
          collection: "stagesLeads",
          currentStageId: activeLead.stage.id,
          overStageId: over.data.current.stage.id,
          data: updatedLead,
        })
      );

      const res = await store.dispatch(
        leadThunks.changeStage({
          id: activeLead.id,
          stage: over.data.current.stage,
        })
      );

      if (!res.error) {
        toast.success("Lead's stage changed");
      } else {
        //** --- if error of updating stage in lead on server we move back lead to previous stage in state ---  */
        store.dispatch(
          pipelineActions.moveLeadBetweenStages({
            collection: "stagesLeads",
            currentStageId: over.data.current.stage.id,
            overStageId: activeLead.stage.id,
            data: activeLead,
          })
        );
      }
    }
  };

  return (
    <>
      {isDesktop ? (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPage
            pipelinesList={pipelinesList}
            selectedPipeline={selectedPipeline}
            setSelectedPipeline={setSelectedPipeline}
            isSearchbarShow
            pageView={pageView}
            togglePageView={togglePageView}
            setPageView={setPageView}
            createNewItemBtnTitle={"+ " + t("base.buttons.createLead")}
            createNewItemAction={createNewItemAction}
            // items={filteredLeads} // TODO: items for search
            onSearch={setSearchPhrase}
            myLeadsFilter={filter}
            handleMyLeadsFilterChange={handleLeadsFilterChange}
          />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPageMobile
            isSearchbarShow
            onSearch={setSearchPhrase}
            pageView={pageView}
            setPageView={setPageView}
            togglePageView={togglePageView}
            createNewItemAction={createNewItemAction}
            pipelinesList={pipelinesList}
            selectedPipeline={selectedPipeline}
            setSelectedPipeline={setSelectedPipeline}
            myLeadsFilter={filter}
            handleMyLeadsFilterChange={handleLeadsFilterChange}
            currentColumnIndex={currentStageIndex}
            indicatorColumnsList={selectedPipeline?.stages}
            columnIndicator
          />
        </ErrorBoundary>
      )}

      <Stack
        direction="row"
        height="100%"
        sx={{
          overflowX: "scroll",
          ...(isMobile && {
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }),
        }}
        gap={2}
        ref={scrollContainerRef}
      >
        {isDesktop ? (
          <DndContext
            sensors={sensors}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            // onDragOver={onDragOver} //TODO: find a way to render DragOverlay w/o sending api call
          >
            <SortableContext items={stagesId}>
              {selectedPipeline?.stages?.map((stage) => (
                <StageCard stage={stage} key={stage.id} leadsIds={leadsIds} filter={filter} />
              ))}
            </SortableContext>

            {createPortal(
              <DragOverlay>
                {activeStage && <StageCard stage={activeStage} leadsIds={leadsIds} />}
                {activeLead && <LeadCard lead={activeLead} />}
              </DragOverlay>,
              document.body
            )}
          </DndContext>
        ) : (
          selectedPipeline?.stages?.map((stage) => (
            <StageCard stage={stage} key={stage.id} leadsIds={leadsIds} filter={filter} />
          ))
        )}
      </Stack>
    </>
  );
};

export default memo(StagesColumns);
