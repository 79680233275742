import { Fragment, memo } from "react";
import { FieldArray } from "formik";

import FormikTextField from "./FormikTextField";
import FormikPhone from "./FormikPhone";
import AddInputAdornment from "./AddInputAdornment";
import RemoveInputAdornment from "./RemoveInputAdornment";
import CopyInputAdornment from "./CopyInputAdornment";

// import { usePermissionContext } from 'services/context/permissionContext';

const FormikArrayPhone = ({ name, values, label, readOnly, checkIfContactExist, copyInputAdornment }) => {
  // const hasPermission = usePermissionContext();
  //TODO: has Permission?

  const handleOnBlur = () => {
    if (checkIfContactExist) {
      checkIfContactExist();
    }
  };

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {values && values.length > 0 ? (
            values.map((option, index) => (
              <Fragment key={index}>
                {/* <MemoizedFormikPhone */}
                <FormikPhone
                  name={`${name}.${index}`}
                  label={label}
                  value={option}
                  readOnly={readOnly}
                  onBlur={() => handleOnBlur(index, option)}
                  adornmentProps={
                    <>
                      {copyInputAdornment && <CopyInputAdornment value={option} />}
                      {
                        // hasPermission &&
                        !readOnly && <AddInputAdornment add={() => arrayHelpers.push("")} name={name} />
                      }
                      {values.length > 1 && (
                        <RemoveInputAdornment remove={() => arrayHelpers.remove(index)} name={name} />
                      )}
                    </>
                  }
                />
              </Fragment>
            ))
          ) : (
            <FormikTextField
              label={label}
              name={`${name}`}
              placeholder={"+380 (50) 555 55 55"}
              onFocus={() => arrayHelpers.push("")}
            />
          )}
        </>
      )}
    />
  );
};

// const MemoizedFormikPhone = memo(FormikPhone, () => true);

export default memo(FormikArrayPhone);
