import { memo } from "react";

import { IconButton, InputAdornment, Stack, TextField, Tooltip, useTheme } from "@mui/material";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

import api from "services/api";
import { AttachmentIcon, BrowseFileIcon, DeleteIcon, DownloadIcon, UpdatedIcon } from "theme/icons";
import { useTranslation } from "react-i18next";

const FilesUploader = ({ files, fileSize, filesAccept, isLoading, uploadedFiles, setFiles }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleChooseFile = (e, index) => {
    const newFiles = [...files];
    newFiles[index] = e.target.files[0];
    setFiles(newFiles);
  };

  const addFileUploaderInput = () => {
    setFiles([...files, ""]);
  };

  const removeFileUploaderInput = (index) => {
    const newFiles = [...files.slice(0, index), ...files.slice(index + 1)];
    setFiles(newFiles);
  };

  const handleDeleteFile = (index) => {
    const newFiles = [...files];
    newFiles[index] = null;
    setFiles(newFiles);
  };

  const handleDownloadFile = (file) => {
    api.get(`files/drive?id=${file?.fileId}`, {}, { responseType: "blob", raw: true }).then((response) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response);
      const defaultName = "download";
      const fileName = file.fileName;
      link.download = fileName || defaultName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <Stack width="100%" gap={theme.spacing(1.5)}>
      {files?.map((file, index) => {
        let nameToDisplay = "";

        /** -- conditional render the file name in input. null is a trigger that we should remove file --- */
        if (uploadedFiles?.[index] && !file && file !== null) {
          nameToDisplay = uploadedFiles?.[index].fileName;
        } else if (file === null) {
          nameToDisplay = "";
        } else {
          nameToDisplay = file.name;
        }
        return (
          <Stack key={index} direction="row" alignItems="flex-end" gap={theme.spacing(2)} width="100%">
            <TextField
              value={nameToDisplay ? nameToDisplay : ""}
              placeholder={t("base.placeholders.chooseFile")}
              sx={{ flex: 1 }}
              label={uploadedFiles?.[index] ? t("base.labels.attachment") : t("base.labels.attachFile")}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
                startAdornment: (uploadedFiles?.[index] || file) && (
                  <InputAdornment position="start" sx={{ mr: 0.5 }}>
                    <AttachmentIcon width={20} height={20} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Tooltip
                      title={`${t("base.tooltips.addField")} ${t("base.tooltips.fileAttachment").toLowerCase()}`}
                    >
                      <IconButton
                        onClick={addFileUploaderInput}
                        sx={{
                          color: "primary.main",
                          p: 0,
                          ml: 1,
                        }}
                      >
                        <AddCircleOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>

                    {files.length > 1 && (
                      <Tooltip
                        title={`${t("base.tooltips.removeField")} ${t("base.tooltips.fileAttachment").toLowerCase()}`}
                      >
                        <IconButton onClick={() => removeFileUploaderInput(index)} sx={{ color: "primary.main", p: 0 }}>
                          <RemoveCircleOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <label htmlFor={`upload-file-${index}`}>
                      <Tooltip
                        title={uploadedFiles?.[index] ? t("base.tooltips.changeFile") : t("base.tooltips.browseFile")}
                      >
                        <IconButton
                          sx={{
                            p: 0,
                          }}
                          component="span"
                          disabled={isLoading}
                        >
                          {uploadedFiles?.[index] ? (
                            <UpdatedIcon fill={theme.palette.success.main} />
                          ) : (
                            <BrowseFileIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                      <input
                        id={`upload-file-${index}`}
                        hidden
                        accept={filesAccept}
                        type="file"
                        onChange={(e) => handleChooseFile(e, index)}
                        size={fileSize}
                      />
                    </label>

                    {uploadedFiles?.[index] && (
                      <Tooltip title={t("base.tooltips.downloadFile")}>
                        <IconButton onClick={() => handleDownloadFile(uploadedFiles?.[index])} sx={{ p: 0 }}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {(uploadedFiles?.[index] || file) && (
                      <Tooltip title={t("base.tooltips.deleteFile")}>
                        <IconButton onClick={() => handleDeleteFile(index)} sx={{ p: 0, color: "error.main" }}>
                          <DeleteIcon width={24} height={24} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                ),
              }}
              disabled={isLoading}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

export default memo(FilesUploader);
