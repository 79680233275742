import { EditOutlined } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material";
import useQuickAddInput from "hooks/useQuickAddInput";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import store from "store";
import { leadActions, leadThunks } from "store/ducks/lead";
import { DESKTOP_VIEW } from "utils/constants";
import { linkify } from "utils/helpers";
import QuickAddInput from "views/common/QuickAddInput";

const CommentInteraction = ({ item, lead }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const [editMode, newValue, setNewValue, enableEditMode, disableEditMode, isLoading, setIsLoading] =
    useQuickAddInput();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleEditClick = () => {
    enableEditMode(item.comment);
  };

  const handleUpdateComment = async () => {
    setIsLoading(true);
    const response = await store.dispatch(
      leadThunks.updateComment({ commentId: item.id, body: { comment: newValue } })
    );
    if (!response.error) {
      const updatedInteractions = lead.interactions.map((interaction) =>
        interaction.id === item.id ? response.payload : interaction
      );
      const updatedLead = { ...lead, interactions: updatedInteractions };

      store.dispatch(leadActions.updateItem({ collection: "lead", data: updatedLead }));
      toast.success(t("messages.success.toast.updateComment"));
      setIsLoading(false);
    }
  };

  return (
    <Box width="100%" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} position="relative">
      {isDesktop && isHovered && !editMode && (
        <Tooltip title={t("base.tooltips.edit")}>
          <IconButton
            onClick={handleEditClick}
            sx={{
              p: 0,
              position: "absolute",
              top: 0,
              right: 8,
              color: "primary.light",
              transition: "0.3s",
              backgroundColor: "extra.white",
              "&:hover": { color: "primary.main", backgroundColor: "extra.white" },
            }}
          >
            <EditOutlined sx={{ width: 20, height: 20 }} />
          </IconButton>
        </Tooltip>
      )}
      {!isDesktop && !editMode && (
        <IconButton
          onClick={handleEditClick}
          sx={{
            p: 0,
            position: "absolute",
            top: 0,
            right: 8,
            color: "primary.light",
            transition: "0.3s",
            backgroundColor: "extra.white",
            "&:hover": { color: "primary.main", backgroundColor: "extra.white" },
          }}
        >
          <EditOutlined sx={{ width: 20, height: 20 }} />
        </IconButton>
      )}
      {!editMode && (
        <Typography
          sx={{
            "& a": {
              color: "info.main",
              textDecoration: "none",
            },
          }}
          dangerouslySetInnerHTML={{ __html: linkify(item.comment) }}
        />
      )}
      {editMode && (
        <QuickAddInput
          newValue={newValue}
          setNewValue={setNewValue}
          disableEditMode={disableEditMode}
          handleActionSubmit={handleUpdateComment}
          isLoading={isLoading}
          size="largeSize"
          width="100%"
        />
      )}
    </Box>
  );
};

export default memo(CommentInteraction);
