import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api";
import {replaceItem, } from "./slice";

const fetchContacts = createAsyncThunk("contact/fetchContacts", (query) => {
  return api.get(`/contacts${query}`);
});

const fetchContact = createAsyncThunk("contact/fetchContact", (id) => {
  return api.get(`/contacts/${id}`);
});

const updateContact = createAsyncThunk("contact/updateContact", (data, { dispatch }) => {
  return api.put(`/contacts/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "contacts", data: res }));
    return res;
  });
});

const isContactExist = createAsyncThunk("contact/isContactExist", (data) => {
  return api.put(`/contacts/existing`, data).then((res) => {
    return res;
  });
});

export default {
  fetchContacts,
  fetchContact,
  updateContact,
  isContactExist,
};
