import { memo, useMemo, useState } from "react";
import { t } from "i18next";

import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import QuickAddInput from "views/common/QuickAddInput";
import StagesListItem from "./StagesListItem";

import { DndContext, DragOverlay } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import store from "store";
import { pipelineActions, pipelineThunks } from "store/ducks/pipeline";
import { toast } from "react-toastify";

const StagesList = ({
  pipeline,
  handleActionSubmit,
  editMode,
  enableEditMode,
  newValue,
  setNewValue,
  disableEditMode,
  isLoading,
  size,
}) => {
  const stagesIds = useMemo(() => pipeline?.stages.map((stage) => stage.id), [pipeline.stages]);
  const [activeStage, setActiveStage] = useState(null);

  const onDragStart = (event) => {
    if (event.active.data.current?.type === "Stage") {
      setActiveStage(event.active.data.current.stage);
      return;
    }
  };

  const handleEditClick = () => {
    enableEditMode("");
  };

  const onDragEnd = async (event) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const movedStageIndex = pipeline.stages.findIndex((stage) => stage.id === active.id);
    const targetIndex = pipeline.stages.findIndex((stage) => stage.id === over.id);

    const currentPipeLine = { ...pipeline };

    const updatedPipeline = {
      ...pipeline,
      stages: arrayMove(pipeline.stages, movedStageIndex, targetIndex),
    };

    const newStagesOrder = updatedPipeline.stages.map((stage) => stage.id);

    if (size === "smallSize") {
      /** --- if the component is rendered in pipelineCard of pipelines list, we replace pipeline
       * in pipelines collection --- */
      store.dispatch(pipelineActions.replaceItem({ collection: "pipelines", data: updatedPipeline }));
      /** if the component is rendered in pipelineForm, we update current item in state*/
    } else store.dispatch(pipelineActions.updateItem({ collection: "pipeline", data: updatedPipeline }));

    const res = await store.dispatch(pipelineThunks.sortStages(newStagesOrder));

    if (!res.error) {
      toast.success(t("messages.success.toast.reorderStage"));
    } else {
      if (size === "smallSize") {
        /** --- if the server responded with error we return order of stages back as it was before --- */
        store.dispatch(pipelineActions.replaceItem({ collection: "pipelines", data: currentPipeLine }));
      } else store.dispatch(pipelineActions.updateItem({ collection: "pipeline", data: currentPipeLine }));
    }
  };

  return (
    <List
      disablePadding
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: size === "smallSize" ? 1 : 2,
        flexWrap: "wrap",
      }}
    >
      <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <SortableContext items={stagesIds}>
          {pipeline?.stages?.map((stage) => (
            <StagesListItem key={stage.id ? stage.id : stage?.name} stage={stage} size={size} pipeline={pipeline} />
          ))}
        </SortableContext>
        {createPortal(
          <DragOverlay>{activeStage && <StagesListItem stage={activeStage} size={size} />}</DragOverlay>,
          document.body
        )}
      </DndContext>
      <ListItem disablePadding sx={{ width: "fit-content" }}>
        {!editMode && (
          <ListItemButton
            onClick={handleEditClick}
            sx={{
              border: "1px dashed",
              borderColor: "primary.light",
              color: "primary.light",
              borderRadius: size === "smallSize" ? 1 : 2,
              px: size === "smallSize" ? 1 : 2,
              py: size === "smallSize" ? 0.5 : 1,
              gap: 1,
              maxWidth: 140,
              transition: "color 0.3s, border-color 0.3s",
              "&:hover": {
                borderColor: "primary.main",
                color: "primary.main",
                background: "none",
                "& .MuiListItemIcon-root": { color: "primary.main", transition: "color 0.3s" },
              },
            }}
          >
            <ListItemText
              primary={t("base.buttons.addNew")}
              primaryTypographyProps={{ fontSize: size === "smallSize" ? 12 : 16 }}
              sx={{ m: size === "smallSize" ? 0 : 0.5 }}
            />
            <ListItemIcon
              sx={{
                padding: 0,
                margin: 0,
                minWidth: 0,
                color: "primary.light",
                transition: "color 0.3s",
                "&:hover": {
                  color: "primary.main",
                },
              }}
            >
              <AddCircleOutlineOutlinedIcon
                style={{ width: size === "smallSize" ? 16 : 20, height: size === "smallSize" ? 16 : 20 }}
              />
            </ListItemIcon>
          </ListItemButton>
        )}
        {editMode && (
          <QuickAddInput
            newValue={newValue}
            setNewValue={setNewValue}
            disableEditMode={disableEditMode}
            handleActionSubmit={handleActionSubmit}
            placeholder={t("base.placeholders.stageName")}
            isLoading={isLoading}
            size={size}
          />
        )}
      </ListItem>
    </List>
  );
};

export default memo(StagesList);
