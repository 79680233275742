import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MOBILE_VIEW } from "utils/constants";

const MyLeadsFilter = ({ filter, handleFilter }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t("base.tooltips.leadFilter")}>
        <IconButton
          id="leads-filter-button"
          aria-controls={open ? "leads-filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickOpen}
          sx={{ width: 40 }}
        >
          {filter.my ? (
            <FilterAltOffOutlinedIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          ) : (
            <FilterAltOutlinedIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="leads-filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "filter-lead",
          role: "listbox",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            ...(isMobile
              ? {
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 10,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                }
              : {
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 10,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                }),
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <FormGroup>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" px={2}>
            <FormControlLabel
              control={
                <Checkbox sx={{ color: "primary.main" }} checked={filter.my} onChange={handleFilter} name="my" />
              }
              label={t("types.lead.myLeads")}
            />
          </Stack>
        </FormGroup>
      </Menu>
    </>
  );
};

export default memo(MyLeadsFilter);
