import { memo } from "react";
import { useTranslation } from "react-i18next";

import { Divider, Stack, Typography } from "@mui/material";

import filters from "filters";
import { INTERACTION_TYPES_ICONS } from "utils/constants";
import { getShortName } from "utils/helpers";
import InteractionMessage from "./InteractionMessage";
import CommentInteraction from "./CommentInteraction";
import CallInteraction from "./CallInteraction";

const InteractionItemMobile = ({ item, lead }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" gap={0.5} width="100%">
      <Stack direction="row" alignItems="center" gap={2}>
        {INTERACTION_TYPES_ICONS[item.type] || null}
        <Typography variant="capture" color="text.light" whiteSpace="nowrap">
          {filters.date(item.createdAt, "dd.MM.yyyy / HH:mm")}
        </Typography>
      </Stack>
      <Typography variant="capture" color={item.author ? "info.main" : "text.light"}>
        {item.author ? getShortName(item.author) : t("types.lead.interactionsMessages.system")}
      </Typography>
      <InteractionMessage item={item} t={t} />
      {(item.type === "PHONE_CALL_IN" || item.type === "PHONE_CALL_OUT") && <CallInteraction item={item} />}
      {item.type === "COMMENT" && <CommentInteraction item={item} lead={lead} />}
      <Divider sx={{ borderColor: "secondary.light" }} />
    </Stack>
  );
};

export default memo(InteractionItemMobile);
