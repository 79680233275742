import { memo, useEffect, useState } from "react";
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import store from "store";
import { pipelineSelectors, pipelineThunks } from "store/ducks/pipeline";

import Loader from "views/common/Loader";
import Error from "views/common/Error";
import { PageWrapper } from "views/common/styledComponents";
import StagesColumns from "./components/StagesColumns";
import LeadsList from "./components/LeadsList";

import { getDataFromLS } from "utils/helpers";

const LeadsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pipelineId } = useParams();

  const leadsView = getDataFromLS("leadsView");

  const [pageView, setPageView] = useState(leadsView || "col");

  const pipelinesList = useSelector(pipelineSelectors.getPipelines());
  const pipelinesListLoading = useSelector(pipelineSelectors.pipelinesLoading());
  const mainPipeline = pipelinesList.find((el) => el.main === true);

  const [selectedPipeline, setSelectedPipeline] = useState(null);

  useEffect(() => {
    pipelinesList.length === 0 && store.dispatch(pipelineThunks.fetchPipelines());
  }, [pipelinesList]);

  useEffect(() => {
    if (!pipelinesListLoading && pipelinesList.length > 0) {
      if (pageView === "col") {
        if (pipelineId) {
          const pipeline = pipelinesList.find((el) => el.id === +pipelineId);
          setSelectedPipeline(pipeline);
        } else setSelectedPipeline(mainPipeline);
      } else setSelectedPipeline("All");
    }
  }, [pipelinesListLoading, pipelineId, mainPipeline, pipelinesList, pageView]);

  useEffect(() => {
    localStorage.setItem("leadsView", JSON.stringify(pageView));
  }, [pageView]);

  const togglePageView = () => {
    if (pageView === "col") {
      setPageView("list");
      setSelectedPipeline("All");
      navigate("/leads");
    } else {
      setSelectedPipeline(mainPipeline);
      setPageView("col");
      navigate(`/leads/pipeline/${mainPipeline.id}`);
    }
  };

  const navigateToCreateNewLeadPage = () => {
    navigate("/leads/new");
  };

  if (pipelinesListLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      {pageView === "col" ? (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          {selectedPipeline && (
            <StagesColumns
              selectedPipeline={selectedPipeline}
              setSelectedPipeline={setSelectedPipeline}
              pipelinesList={pipelinesList}
              isSearchbarShow
              pageView={pageView}
              togglePageView={togglePageView}
              setPageView={setPageView}
              createNewItemBtnTitle={"+ " + t("base.buttons.createLead")}
              createNewItemAction={navigateToCreateNewLeadPage}
            />
          )}
        </ErrorBoundary>
      ) : (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <LeadsList
            pipelinesList={pipelinesList}
            selectedPipeline={selectedPipeline}
            setSelectedPipeline={setSelectedPipeline}
            isSearchbarShow
            pageView={pageView}
            togglePageView={togglePageView}
            setPageView={setPageView}
            createNewItemBtnTitle={"+ " + t("base.buttons.createLead")}
            createNewItemAction={navigateToCreateNewLeadPage}
          />
        </ErrorBoundary>
      )}
    </PageWrapper>
  );
};

export default memo(LeadsPage);
