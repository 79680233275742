import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, withFormik } from "formik";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";

import { FormColumnTitle, FormColumnWrapper } from "views/common/styledComponents";
import FormikAutocomplete from "views/form/FormikAutocomplete";
import FormikTextField from "views/form/FormikTextField";
import FormikArrayPhone from "views/form/FormikArrayPhone";
import FormikArrayTextField from "views/form/FormikArrayTextField";

import store from "store";
import { dictionarySelectors } from "store/ducks/dictionary";
import { leadActions } from "store/ducks/lead";

import { contactThunks } from "store/ducks/contact";
import { contactSchema } from "utils/schema";
import { DESKTOP_VIEW, MOBILE_VIEW } from "utils/constants";
import HeaderForm from "./HeaderForm";

const ContactForm = ({
  lead,
  contact,
  values,
  isSubmitting,
  dirty,
  setFieldValue,
  errors,
  modalOpen,
  closeModal,
  openModal,
}) => {
  console.log("🚀 ~ errors:", errors)
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const countries = useSelector(dictionarySelectors.getCountries());
  const countriesLoading = useSelector(dictionarySelectors.countriesLoading());
  const cities = useSelector(dictionarySelectors.getCities());
  const citiesLoading = useSelector(dictionarySelectors.citiesLoading());
  const educations = useSelector(dictionarySelectors.getEducations());
  const educationsLoading = useSelector(dictionarySelectors.educationsLoading());
  const universities = useSelector(dictionarySelectors.getUniversities());
  const universitiesLoading = useSelector(dictionarySelectors.universitiesLoading());
  const professions = useSelector(dictionarySelectors.getProfessions());
  const professionsLoading = useSelector(dictionarySelectors.professionsLoading());

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [contactColumnView, setContactColumnView] = useState("general");

  const handleContactColumnViewChange = (event, newView) => {
    setContactColumnView(newView);
  };

  useEffect(() => {
    if (values.country) {
      setSelectedCountry(values.country);
    }
  }, [values.country]);

  const selectedCountryCities = cities.filter((city) => city.country?.id === selectedCountry?.id);

  useEffect(() => {
    if (!values.country && values.city) {
      const cityCountry = countries.find((country) => country.id === values.city.country.id);
      setFieldValue("country", cityCountry);
    }
  }, [values.country, values.city, countries, setFieldValue]);

  return (
    <Form id="contactForm" style={{ height: "100%" }}>
      {/** --- Header is shown only on Contact page and doesn't on Lead page --- */}
      {!lead && (
        <Box mb={isDesktop ? 2 : 0}>
          <HeaderForm
            title={t("types.contact.name")}
            data={contact}
            isSubmitting={isSubmitting}
            formId="contactForm"
            modalOpen={modalOpen}
            closeModal={closeModal}
            openModal={openModal}
            isFormChanged={dirty}
          />
          {isMobile && (
            <ToggleButtonGroup
              color="primary"
              value={contactColumnView}
              exclusive
              onChange={handleContactColumnViewChange}
              sx={{ px: 2, width: "100%" }}
            >
              <ToggleButton sx={{ width: "100%" }} value={"general"}>
                {t("types.contact.general")}
              </ToggleButton>
              <ToggleButton sx={{ width: "100%" }} value={"details"}>
                {t("types.contact.contactDetails")}
              </ToggleButton>
              <ToggleButton sx={{ width: "100%" }} value={"background"}>
                {t("types.contact.background")}
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Box>
      )}
      {lead ? (
        <Stack height="100%" justifyContent="space-between">
          <FormColumnWrapper sx={{ width: "100%", overflowY: "auto" }}>
            <FormikTextField
              name="lastName"
              placeholder={t("base.placeholders.lastName")}
              label={t("base.labels.lastName")}
            />
            <FormikTextField name="name" placeholder={t("base.placeholders.name")} label={t("base.labels.name")} />
            <FormikTextField
              name="secondName"
              placeholder={t("base.placeholders.secondName")}
              label={t("base.labels.secondName")}
            />
            <FormikArrayPhone name="phones" label={t("base.labels.phone")} values={values.phones} copyInputAdornment />
            <FormikArrayTextField
              name="emails"
              label={t("base.labels.email")}
              placeholder={t("base.placeholders.email")}
              values={values.emails}
              copyInputAdornment
            />
            <FormikTextField
              name="messenger"
              placeholder={t("base.placeholders.messenger")}
              label={t("base.labels.messenger")}
            />
            <FormikAutocomplete
              name="country"
              placeholder={t("base.placeholders.choose")}
              label={t("base.labels.country")}
              options={countries}
              loading={countriesLoading}
            />
            <FormikAutocomplete
              name="city"
              placeholder={t("base.placeholders.choose")}
              label={t("base.labels.city")}
              options={selectedCountry ? selectedCountryCities : cities}
              loading={citiesLoading}
            />
            <FormikAutocomplete
              name="profession"
              placeholder={t("base.placeholders.choose")}
              label={t("base.labels.profession")}
              options={professions}
              loading={professionsLoading}
            />
            <FormikAutocomplete
              name="education"
              placeholder={t("base.placeholders.choose")}
              label={t("base.labels.education")}
              options={educations}
              loading={educationsLoading}
            />
            <FormikAutocomplete
              name="university"
              placeholder={t("base.placeholders.choose")}
              label={t("base.labels.university")}
              options={universities}
              loading={universitiesLoading}
            />
          </FormColumnWrapper>
          <FormColumnWrapper sx={{ alignItems: "center" }}>
            <Divider sx={{ width: "100%" }} orientation="horizontal" />
            <Button
              variant="contained"
              type="submit"
              sx={{ maxWidth: "140px" }}
              disabled={isSubmitting || !dirty}
              startIcon={isSubmitting && <CircularProgress size={16} sx={{ mr: 1, color: "extra.white" }} />}
            >
              {isSubmitting ? t("base.buttons.saving") : t("base.buttons.save")}
            </Button>
          </FormColumnWrapper>
        </Stack>
      ) : (
        <Stack direction="row" gap={isDesktop ? 2 : 1} p={isDesktop ? 0 : 1}>
          {((isMobile && contactColumnView === "general") || !isMobile) && (
            <FormColumnWrapper sx={{ width: "100%" }}>
              {!isMobile && <FormColumnTitle>{t("types.contact.general")}</FormColumnTitle>}
              <FormikTextField
                name="lastName"
                placeholder={t("base.placeholders.lastName")}
                label={t("base.labels.lastName")}
              />
              <FormikTextField name="name" placeholder={t("base.placeholders.name")} label={t("base.labels.name")} />
              <FormikTextField
                name="secondName"
                placeholder={t("base.placeholders.secondName")}
                label={t("base.labels.secondName")}
              />
            </FormColumnWrapper>
          )}
          {((isMobile && contactColumnView === "details") || !isMobile) && (
            <FormColumnWrapper sx={{ width: "100%" }}>
              {!isMobile && <FormColumnTitle>{t("types.contact.contactDetails")}</FormColumnTitle>}
              <FormikArrayPhone
                name="phones"
                label={t("base.labels.phone")}
                values={values.phones}
                copyInputAdornment
              />
              <FormikArrayTextField
                name="emails"
                label={t("base.labels.email")}
                placeholder={t("base.placeholders.email")}
                values={values.emails}
                copyInputAdornment
              />
              <FormikTextField
                name="messenger"
                placeholder={t("base.placeholders.messenger")}
                label={t("base.labels.messenger")}
              />
              <FormikAutocomplete
                name="country"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.country")}
                options={countries}
                loading={countriesLoading}
              />
              <FormikAutocomplete
                name="city"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.city")}
                options={selectedCountry ? selectedCountryCities : cities}
                loading={citiesLoading}
              />
            </FormColumnWrapper>
          )}
          {((isMobile && contactColumnView === "background") || !isMobile) && (
            <FormColumnWrapper sx={{ width: "100%" }}>
              {!isMobile && <FormColumnTitle>{t("types.contact.background")}</FormColumnTitle>}
              <FormikAutocomplete
                name="profession"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.profession")}
                options={professions}
                loading={professionsLoading}
              />
              <FormikAutocomplete
                name="education"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.education")}
                options={educations}
                loading={educationsLoading}
              />
              <FormikAutocomplete
                name="university"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.university")}
                options={universities}
                loading={universitiesLoading}
              />
            </FormColumnWrapper>
          )}
        </Stack>
      )}
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ contact = {} }) => ({
      ...contact,
      id: contact?.id,
      name: contact?.name || "",
      lastName: contact?.lastName || "",
      secondName: contact?.secondName || "",
      phones: contact?.phones || [],
      emails: contact?.emails || [],
      messenger: contact?.messenger || "",
      country: contact?.country || null,
      city: contact?.city || null,
      profession: contact?.profession || null,
      education: contact?.education || null,
      university: contact?.university || null,
    }),
    validationSchema: contactSchema,
    handleSubmit: async (values, { props, setSubmitting }) => {
      const valuesWithoutEmptyEmails = { ...values, emails: values.emails.filter((email) => email !== "") };

      const response = await store.dispatch(contactThunks.updateContact(valuesWithoutEmptyEmails));

      if (!response.error) {
        if (props.lead) {
          /** ---- if form is used on lead page, we update lead item --- */
          store.dispatch(
            leadActions.updateItem({ collection: "lead", data: { ...props.lead, contact: response.payload } })
          );
        }
        props.closeModal && props.closeModal();
        toast.success(props.t("messages.success.toast.updateContact"));
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
  })(ContactForm)
);
