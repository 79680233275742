import { memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import { Button, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";

import SearchBar from "./SearchBar";
import { ColumnsIcon, ExportIcon, ListIcon } from "theme/icons";
import PipelineSelector from "./topbar/PipelineSelector";
import TasksFilter from "views/pages/tasks/components/TasksFilter";
import MyLeadsFilter from "views/pages/leads/components/MyLeadsFilter";
import AllLeadsFilter from "views/pages/leads/components/AllLeadsFilter";
import { isFilterFieldFilled } from "utils/helpers";
import KeyCall from "views/pages/leads/components/KeyCall";
import BulkLeadsStage from "views/pages/leads/components/BulkLeadsStage";
import BulkLeadsStatus from "views/pages/leads/components/BulkLeadsStatus";
import BulkLeadsTask from "views/pages/leads/components/BulkLeadsTask";

/** --- PROPS DESCRIPTION ---
 * title -  title of the page
 * isSearchbarShow - condition to display searchbar or not
 * togglePageView - switch view from column to list
 * pageView - indicator of current switch view - list or columns
 * setPageView - handle switch view - list/columns
 * createNewItemAction - function to handle the click on button for creation new item of entity
 * createNewItemBtnTitle - title of the button to create a new item
 * pipelinesList - to handle pipelines selector in Leads Page
 * selectedPipeline - to handle pipelines selector in Leads Page
 * setSelectedPipeline - to handle pipelines selector in Leads Page
 * onSearch - handle client side search
 * tasksFilter - object with filter options for tasks filtering
 * handleFilterChange - handle filter
 * closeLead - trigger for button "Close lead" on lead page
 * openModal - action to open modal
 * ---------------------------- */

const HeaderPage = ({
  title,
  isSearchbarShow,
  togglePageView,
  pageView,
  setPageView,
  createNewItemAction,
  createNewItemBtnTitle,
  pipelinesList,
  selectedPipeline,
  setSelectedPipeline,
  onSearch,
  tasksFilter,
  handleFilterChange,
  closeLead,
  openModal,
  myLeadsFilter,
  handleMyLeadsFilterChange,
  allLeadsFilter,
  handleAllLeadsFilter,
  initialAllLeadsFilterValues,
  handleApplyAllLeadsFilter,
  handleExportToExcel,
  setIsAllLeadsFilterApplied,
  appliedAllLeadsFilter,
  setAppliedAllLeadsFilter,
  selectedLeads,
  isAllLeadsFilterApplied,
  query,
}) => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="extra.white"
      gap={4}
      py={1}
      px={2}
      sx={{
        borderBottomLeftRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
      }}
    >
      <Stack direction="row" gap={2} alignItems="center">
        <Button variant="contained" startIcon={<ArrowBackIosNewOutlined />} LinkComponent={Link} onClick={goBack}>
          {t("base.buttons.back")}
        </Button>
        {pipelinesList && (
          <PipelineSelector
            pipelinesList={pipelinesList}
            pageView={pageView}
            setPageView={setPageView}
            selectedPipeline={selectedPipeline}
            setSelectedPipeline={setSelectedPipeline}
          />
        )}
        {title && (
          <Typography variant="h4" color="text.main">
            {title}
          </Typography>
        )}
        {/** --- filter, switch view --- */}
        <Stack direction="row">
          {pageView && (
            <Tooltip
              title={pageView === "list" ? t("base.tooltips.switchToColView") : t("base.tooltips.switchToListView")}
            >
              <IconButton onClick={togglePageView}>
                {pageView === "list" ? (
                  <ListIcon fill={theme.palette.primary.main} />
                ) : (
                  <ColumnsIcon fill={theme.palette.primary.main} />
                )}
              </IconButton>
            </Tooltip>
          )}
          {tasksFilter && <TasksFilter filter={tasksFilter} handleFilter={handleFilterChange} pageView={pageView} />}
          {myLeadsFilter && <MyLeadsFilter filter={myLeadsFilter} handleFilter={handleMyLeadsFilterChange} />}
          {allLeadsFilter && (
            <>
              <AllLeadsFilter
                filter={allLeadsFilter}
                handleFilter={handleAllLeadsFilter}
                initialAllLeadsFilterValues={initialAllLeadsFilterValues}
                handleApplyAllLeadsFilter={handleApplyAllLeadsFilter}
                setIsAllLeadsFilterApplied={setIsAllLeadsFilterApplied}
                setAppliedAllLeadsFilter={setAppliedAllLeadsFilter}
              />
              <Tooltip title={t("base.tooltips.export")}>
                <span>
                  <IconButton disabled={!isFilterFieldFilled(appliedAllLeadsFilter)} onClick={handleExportToExcel}>
                    <ExportIcon
                      fill={
                        !isFilterFieldFilled(appliedAllLeadsFilter)
                          ? theme.palette.action.disabled
                          : theme.palette.primary.main
                      }
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
          {selectedLeads?.length > 0 && (
            <>
              <KeyCall
                isAllLeadsFilterApplied={isAllLeadsFilterApplied}
                query={query}
                appliedAllLeadsFilter={appliedAllLeadsFilter}
              />
              <BulkLeadsStage
                isAllLeadsFilterApplied={isAllLeadsFilterApplied}
                query={query}
                appliedAllLeadsFilter={appliedAllLeadsFilter}
              />
              <BulkLeadsStatus
                isAllLeadsFilterApplied={isAllLeadsFilterApplied}
                query={query}
                appliedAllLeadsFilter={appliedAllLeadsFilter}
              />
              <BulkLeadsTask />
            </>
          )}
        </Stack>
      </Stack>
      {/** --- SEARCH BAR --- */}
      {isSearchbarShow && <SearchBar onSearch={onSearch} />}
      {/** --- CREATE NEW ITEM --- */}
      {createNewItemAction && <Button onClick={createNewItemAction}>{createNewItemBtnTitle}</Button>}
      {/** ---- CLOSE LEAD  ---- */}
      {closeLead && (
        <Button onClick={openModal} variant="text" color="error" sx={{ fontWeight: 600 }}>
          {t("types.lead.closeLead")}
        </Button>
      )}
    </Stack>
  );
};

export default memo(HeaderPage);
