import { Stack, Typography, useMediaQuery } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import Player from "./Player";

import { ErrorBoundary } from "@sentry/react";
import Error from "views/common/Error";
import { MOBILE_VIEW } from "utils/constants";

const CallInteraction = ({ item }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(MOBILE_VIEW);

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      alignItems={isMobile ? 'flex-start' : "center"}
      justifyContent="space-between"
      width="100%"
      pr={2}
    >
      <Typography variant="capture" color="text.main" width="100%">
        {item.phoneNumber},{" "}
        {item.callDuration < 60
          ? item.callDuration + " " + t("types.lead.interactionsMessages.sec")
          : Math.floor(item.callDuration / 60) +
            t("types.lead.interactionsMessages.min") +
            " " +
            (item.callDuration % 60) +
            t("types.lead.interactionsMessages.sec")}
      </Typography>
      {(item.callDisposition === "ANSWER" ||
        item.callDisposition === "VM - SUCCESS" ||
        item.callDisposition === "SUCCESS") && (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <Player item={item} />
        </ErrorBoundary>
      )}
    </Stack>
  );
};

export default memo(CallInteraction);
