import { memo } from "react";

import { Chip, IconButton, Tooltip, useTheme } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { useCopyToClipboard } from "utils/helpers";

import { CopyIcon, DoneIcon } from "theme/icons";

const CopyInputAdornment = ({ value, tooltipTitle }) => {
  const theme = useTheme();
  const [copyToClipboard, copyResult] = useCopyToClipboard();

  return (
    <>
      {copyResult === null ? (
        <IconButton
          sx={{ p: 0.25 }}
          disabled={value === "" || value === undefined || value.length === 0}
          onClick={() => copyToClipboard(value)}
        >
          <Tooltip title={tooltipTitle}>
            <CopyIcon
              fill={
                value === "" || value === undefined || value.length === 0
                  ? theme.palette.extra.grey
                  : theme.palette.primary.main
              }
            />
          </Tooltip>
        </IconButton>
      ) : copyResult?.state === "success" ? (
        <DoneIcon style={{ width: "32px", height: "32px" }} fill={theme.palette.primary.main} />
      ) : (
        <Chip variant="outlined" label={copyResult?.message || "Error"} color="error" icon={<ErrorOutlineIcon />} />
      )}
    </>
  );
};

export default memo(CopyInputAdornment);
