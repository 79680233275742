import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, withFormik } from "formik";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { IconButton, Stack, Link, Tooltip, Typography, useMediaQuery } from "@mui/material";

import HeaderForm from "views/common/HeaderForm";
import { FormColumnTitle, FormColumnWrapper, PageWrapper } from "views/common/styledComponents";
import FormikAutocomplete from "views/form/FormikAutocomplete";
import FormikTextField from "views/form/FormikTextField";
import FormikDatePicker from "views/form/FormikDatePicker";
import FormikArrayPhone from "views/form/FormikArrayPhone";
import FormikArrayTextField from "views/form/FormikArrayTextField";

import store from "store";
import { pipelineSelectors, pipelineThunks } from "store/ducks/pipeline";
import { dictionarySelectors } from "store/ducks/dictionary";
import { leadThunks } from "store/ducks/lead";
import { contactThunks } from "store/ducks/contact";

import { getShortName } from "utils/helpers";
import { DESKTOP_VIEW, MOBILE_VIEW, TABLET_VIEW } from "utils/constants";
import { newLeadSchema } from "utils/schema";
import { AutoFillIcon, LinkOpenIcon } from "theme/icons";

const NewLeadForm = ({ values, isSubmitting, setFieldValue }) => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const isTablet = useMediaQuery(TABLET_VIEW);

  const { t } = useTranslation();

  const countries = useSelector(dictionarySelectors.getCountries());
  const countriesLoading = useSelector(dictionarySelectors.countriesLoading());
  const cities = useSelector(dictionarySelectors.getCities());
  const citiesLoading = useSelector(dictionarySelectors.citiesLoading());
  const programs = useSelector(dictionarySelectors.getPrograms());
  const programsLoading = useSelector(dictionarySelectors.programsLoading());
  const marketingChannels = useSelector(dictionarySelectors.getMarketingChannels());
  const marketingChannelsLoading = useSelector(dictionarySelectors.marketingChannelsLoading());
  const managers = useSelector(dictionarySelectors.getManagers());
  const managersLoading = useSelector(dictionarySelectors.managersLoading());
  const educations = useSelector(dictionarySelectors.getEducations());
  const educationsLoading = useSelector(dictionarySelectors.educationsLoading());
  const universities = useSelector(dictionarySelectors.getUniversities());
  const universitiesLoading = useSelector(dictionarySelectors.universitiesLoading());
  const professions = useSelector(dictionarySelectors.getProfessions());
  const professionsLoading = useSelector(dictionarySelectors.professionsLoading());
  const enums = useSelector(dictionarySelectors.getEnums());
  const enumsLoading = useSelector(dictionarySelectors.enumsLoading());
  const pipelines = useSelector(pipelineSelectors.getPipelines());
  const pipelinesLoading = useSelector(pipelineSelectors.pipelinesLoading());

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [existingContact, setExistingContact] = useState(null);

  useEffect(() => {
    if (values.country) {
      setSelectedCountry(values.country);
    }
  }, [values.country]);

  const selectedCountryCities = cities.filter((city) => city.country?.id === selectedCountry?.id);

  useEffect(() => {
    if (!values.country && values.city) {
      const cityCountry = countries.find((country) => country.id === values.city.country.id);
      setFieldValue("country", cityCountry);
    }
  }, [values.country, values.city, countries, setFieldValue]);

  useEffect(() => {
    pipelines.length === 0 && store.dispatch(pipelineThunks.fetchPipelines());
  }, [pipelines]);

  const checkIfContactExist = async () => {
    const data = {
      phones: values.phones,
      emails: values.emails,
    };

    const response = await store.dispatch(contactThunks.isContactExist(data));
    if (response.payload !== null) {
      setExistingContact(response.payload);
    }
  };

  const handleFillContact = () => {
    setFieldValue("name", existingContact.name);
    setFieldValue("phones", existingContact.phones);
    setFieldValue("emails", existingContact.emails);
    existingContact.country && setFieldValue("country", existingContact.country);
    existingContact.city && setFieldValue("city", existingContact.city);
    existingContact.profession && setFieldValue("profession", existingContact.profession);
    existingContact.education && setFieldValue("education", existingContact.education);
    existingContact.university && setFieldValue("university", existingContact.university);
    setExistingContact(null);
  };

  return (
    <PageWrapper>
      <Form id="newLeadForm" style={{ height: "inherit" }}>
        <HeaderForm title={t("types.lead.new")} isSubmitting={isSubmitting} formId="newLeadForm" />

        <Stack height="calc(100% - 60px)" p={isDesktop ? 0 : 1}>
          <Stack
            direction={isMobile ? "column" : "row"}
            gap={isDesktop ? 2 : 1}
            mt={isDesktop ? 2 : 0}
            width="100%"
            sx={{ ...(!isDesktop && { height: "100%", overflowY: "scroll" }) }}
          >
            {/* --- First column --- */}
            <FormColumnWrapper
              sx={{ width: isMobile ? "100%" : "33.33%", ...(isTablet && { overflowY: "auto", height: "100%" }) }}
            >
              <FormColumnTitle>{t("types.lead.form.contact")}</FormColumnTitle>
              <FormikTextField
                name="lastName"
                placeholder={t("base.placeholders.lastName")}
                label={t("base.labels.lastName")}
              />
              <FormikTextField name="name" placeholder={t("base.placeholders.name")} label={t("base.labels.name")} />
              <FormikTextField
                name="secondName"
                placeholder={t("base.placeholders.secondName")}
                label={t("base.labels.secondName")}
              />
              <FormikArrayPhone
                name="phones"
                label={t("base.labels.phone")}
                values={values.phones}
                checkIfContactExist={checkIfContactExist}
              />
              <FormikArrayTextField
                name="emails"
                label={t("base.labels.email")}
                placeholder={t("base.placeholders.email")}
                values={values.emails}
                checkIfContactExist={checkIfContactExist}
              />
              <FormikTextField
                name="messenger"
                placeholder={t("base.placeholders.messenger")}
                label={t("base.labels.messenger")}
              />
              <FormikAutocomplete
                name="country"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.country")}
                options={countries}
                loading={countriesLoading}
              />
              <FormikAutocomplete
                name="city"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.city")}
                options={selectedCountry ? selectedCountryCities : cities}
                loading={citiesLoading}
              />
              <FormikAutocomplete
                name="profession"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.profession")}
                options={professions}
                loading={professionsLoading}
              />
              <FormikAutocomplete
                name="education"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.education")}
                options={educations}
                loading={educationsLoading}
              />
              <FormikAutocomplete
                name="university"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.university")}
                options={universities}
                loading={universitiesLoading}
              />
              {existingContact && (
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" color="success.main">
                    {t("types.lead.form.contactExist")}
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    <Tooltip title={t("base.tooltips.fillContactData")}>
                      <IconButton onClick={handleFillContact}>
                        <AutoFillIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("base.tooltips.openContact")}>
                      <IconButton LinkComponent={Link} target="blank" href={`/contacts/${existingContact?.id}`}>
                        <LinkOpenIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              )}
            </FormColumnWrapper>
            {/* --- First column end  --- */}

            {/* --- Second column  --- */}
            <FormColumnWrapper
              sx={{ width: isMobile ? "100%" : "33.33%", ...(isTablet && { overflowY: "auto", height: "100%" }) }}
            >
              <FormColumnTitle>{t("types.lead.form.leadStage")}</FormColumnTitle>
              <FormikTextField
                name="leadName"
                placeholder={t("base.placeholders.name")}
                label={t("base.labels.leadName")}
              />
              <FormikAutocomplete
                name="responsible"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.responsible")}
                options={managers}
                getCustomLabel={getShortName}
                loading={managersLoading}
              />
              <FormikAutocomplete
                name="pipeline"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.pipeline")}
                options={pipelines}
                loading={pipelinesLoading}
              />
              <FormikAutocomplete
                name="stage"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.stage")}
                options={values?.pipeline?.stages || []}
                loading={pipelinesLoading}
              />

              <FormikDatePicker name="meetingDate" label={t("base.labels.meetDate")} />
              <FormikDatePicker name="contractDate" label={t("base.labels.contractDate")} />
            </FormColumnWrapper>
            {/* --- Second column  end  --- */}
            {/* --- Third column  --- */}
            <FormColumnWrapper
              sx={{ width: isMobile ? "100%" : "33.33%", ...(isTablet && { overflowY: "auto", height: "100%" }) }}
            >
              <FormColumnTitle>{t("types.lead.form.leadDetails")}</FormColumnTitle>
              <FormikAutocomplete
                name="format"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.format")}
                options={enums?.groupFormats || []}
                loading={enumsLoading}
              />
              <FormikAutocomplete
                name="program"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.program")}
                options={programs}
                loading={programsLoading}
              />
              <FormikTextField
                name="contractAmount"
                placeholder={t("base.placeholders.contractAmount")}
                label={t("base.labels.contractAmount")}
              />

              <FormikAutocomplete
                name="channel"
                placeholder={t("base.placeholders.choose")}
                label={t("base.labels.channel")}
                options={marketingChannels}
                loading={marketingChannelsLoading}
              />
              <FormikTextField
                name="sourcePage"
                placeholder={t("base.placeholders.sourcePage")}
                label={t("base.labels.sourcePage")}
              />
              <FormikTextField
                name="campaign"
                placeholder={t("base.placeholders.campaign")}
                label={t("base.labels.campaign")}
              />
              <FormikTextField
                name="medium"
                placeholder={t("base.placeholders.medium")}
                label={t("base.labels.medium")}
              />
              <FormikTextField
                name="source"
                placeholder={t("base.placeholders.source")}
                label={t("base.labels.source")}
              />
            </FormColumnWrapper>
          </Stack>
        </Stack>
      </Form>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ leadData = {} }) => ({
      ...leadData,
      leadName: "",
      name: "",
      lastName: "",
      secondName: "",
      phones: [],
      emails: [],
      messenger: "",
      country: null,
      city: null,
      pipeline: null,
      responsible: null,
      stage: null,
      meetDate: null,
      contractAmount: "",
      contractDate: null,
      format: null,
      program: null,
      sale: "",
      channel: null,
      source: "",
    }),
    validationSchema: newLeadSchema,
    handleSubmit: async (values, { props, setSubmitting }) => {
      const valuesWithoutEmptyEmail = { ...values, emails: values.emails.filter((email) => email !== "") };

      const response = await store.dispatch(leadThunks.createLead(valuesWithoutEmptyEmail));
      if (!response.error) {
        toast.success(props.t("messages.success.toast.createLead"));
        setSubmitting(false);
        props.navigate(`/leads/${response.payload.id}`);
      }
    },
    enableReinitialize: true,
  })(NewLeadForm)
);
