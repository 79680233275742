import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

import { PERIODS } from "utils/constants";

const PeriodSelector = () => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState("day");

  const handleChange = (event, newPeriod) => {
    setPeriod(newPeriod);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={period}
      exclusive
      onChange={handleChange}
      sx={{ justifyContent: "center", mt: { xs: 1, sm: 2, lg: 0 } }}
    >
      {PERIODS.map((period) => {
        return (
          <ToggleButton value={period} key={period}>
            {t(`periods.${period}`)}
          </ToggleButton>
      );
      })}
      <ToggleButton value="period" sx={{ gap: 1 }} onClick={() => console.log("period btn clicked")}>
        <CalendarMonthOutlinedIcon /> {t("periods.period")}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default PeriodSelector;
