import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, withFormik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Stack, TextField, useMediaQuery } from "@mui/material";

import store from "store";
import { dictionarySelectors } from "store/ducks/dictionary";

import { FormColumnTitle, FormColumnWrapper, PageWrapper } from "views/common/styledComponents";
import HeaderForm from "views/common/HeaderForm";
import FormikTextField from "views/form/FormikTextField";
import FormikAutocomplete from "views/form/FormikAutocomplete";

import { DESKTOP_VIEW, MOBILE_VIEW } from "utils/constants";

import { getLeadNameForTask, getShortName, uploadFilesAndReturnUploadedFiles } from "utils/helpers";
import FormikDatePicker from "views/form/FormikDatePicker";
import filters from "filters";
import { taskSchema } from "utils/schema";
import { taskThunks } from "store/ducks/task";
import { leadActions, leadSelectors, leadThunks } from "store/ducks/lead";
import FilesUploader from "views/form/FilesUploader";
import FormikTimePicker from "views/form/FormikTimePicker";
import FormikAutocompleteMultipleFetch from "views/form/FormikAutocompleteMultipleFetch";

const TaskForm = ({ taskData, values, modalOpen, closeModal, openModal, isSubmitting, dirty, files, setFiles }) => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const { t } = useTranslation();

  const enums = useSelector(dictionarySelectors.getEnums());
  const { taskStatuses: statuses } = enums;
  const taskTypes = useSelector(dictionarySelectors.getTaskTypes());
  const taskTypesLoading = useSelector(dictionarySelectors.taskTypesLoading());

  const employees = useSelector(dictionarySelectors.getEmployees());
  const employeesLoading = useSelector(dictionarySelectors.employeesLoading());
  const leads = useSelector(leadSelectors.getLeads());
  const leadsLoading = useSelector(leadSelectors.leadsLoading());

  const fetchLeads = (value) => {
    store.dispatch(leadThunks.fetchLeads(`?q=${value}`));
  };

  useEffect(() => {
    if (taskData) {
      const existing = Array.isArray(values.leads) ? values.leads : [values.leads];
      const newEntities = existing.filter((e) => e?.id && !leads.content.find((en) => en.id === e?.id));

      if (newEntities.length > 0) {
        const updatedEntities = [...leads.content, ...newEntities];
        store.dispatch(leadActions.setLeads(updatedEntities));
      }
    }
  }, [taskData, leads.content, values.leads]);

  return (
    <PageWrapper>
      <Form id="taskForm" style={{ height: "inherit" }}>
        <HeaderForm
          title={taskData ? t("types.task.name") : t("types.task.new")}
          data={taskData}
          isSubmitting={isSubmitting}
          formId="taskForm"
          modalOpen={modalOpen}
          closeModal={closeModal}
          openModal={openModal}
          isFormChanged={dirty || (files.length > 0 && files[0] !== "")}
        />
        <Stack height="calc(100% - 60px)">
          <Stack
            direction={isDesktop ? "row" : "column"}
            gap={isDesktop ? 2 : 1}
            mt={isDesktop ? 2 : 0}
            width="100%"
            sx={{ ...(isMobile && { overflowY: "auto" }) }}
          >
            {/* --- First column --- */}
            <FormColumnWrapper sx={{ width: isDesktop ? "50%" : "100%" }}>
              <FormColumnTitle>{t("base.dictionary.data")}</FormColumnTitle>
              <FormikTextField name="name" placeholder={t("base.placeholders.name")} label={t("base.labels.name")} />
              <FormikTextField
                name="description"
                placeholder={t("base.placeholders.description")}
                label={t("base.labels.description")}
              />
              <FormikAutocomplete
                name="responsible"
                label={t("base.labels.responsible")}
                getCustomLabel={getShortName}
                options={employees}
                loading={employeesLoading}
                multiple
                limitTags={2}
              />
              <Stack direction="row" alignItems="center" gap={2} width="100%">
                <FormikAutocomplete
                  name="status"
                  label={t("base.labels.status")}
                  options={statuses || []}
                  sx={{ width: "30%" }}
                />
                <FormikAutocomplete
                  name="type"
                  label={t("base.labels.type")}
                  options={taskTypes || []}
                  loading={taskTypesLoading}
                  sx={{ width: "70%" }}
                />
              </Stack>
              <Stack direction="row" alignItems="center" gap={2} width="100%">
                <FormikDatePicker name="dueDate" label={t("base.labels.dueDate")} sx={{ width: "50%" }} />
                <FormikTimePicker
                  name="dueTime"
                  label={t("base.labels.dueTime")}
                  sx={{ width: "50%" }}
                  useTimezone
                  date={values.dueDate}
                  timeZone={values.timezone}
                />
              </Stack>
            </FormColumnWrapper>
            {/* --- Second column --- */}
            <FormColumnWrapper sx={{ width: isDesktop ? "50%" : "100%" }}>
              <FormColumnTitle>{t("types.task.about")}</FormColumnTitle>
              <TextField
                name="author"
                label={t("base.labels.author")}
                placeholder={t("base.placeholders.readOnly")}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                }}
                value={getShortName(values?.author)}
              />
              <TextField
                name="createdAt"
                label={t("base.labels.date")}
                placeholder={t("base.placeholders.readOnly")}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                }}
                value={filters.date(values?.createdAt, "dd.MM.yyyy HH:mm")}
              />
              <FormikAutocompleteMultipleFetch
                name="leads"
                label={t("base.labels.lead")}
                getCustomLabel={getLeadNameForTask}
                options={leads.content}
                fetchOptions={fetchLeads}
                loading={leadsLoading}
                limitTags={2}
                multiple
                pathForOpenEntity="/leads/"
              />
              <FilesUploader
                files={files}
                setFiles={setFiles}
                fileSize="15000000"
                filesAccept=".pdf, .ppt, .pptx, .txt, .xls, .xlsx, .doc, .docx, .zip, .mp4, .mpeg, .avi, .jpeg, .jpg, .png"
                isLoading={isSubmitting}
                uploadedFiles={values?.files}
              />
            </FormColumnWrapper>
          </Stack>
        </Stack>
      </Form>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ taskData = {} }) => ({
      ...taskData,
      id: taskData?.id,
      author: taskData?.author,
      name: taskData?.name || "",
      description: taskData?.description || "",
      responsible: taskData?.responsible || [],
      status: taskData?.status || null,
      createdAt: taskData?.createdAt,
      dueDate: taskData?.dueDate || null,
      dueTime: taskData?.dueTime || null,
      files: taskData?.files || [],
      leads: taskData?.leads || [],
      timezone: taskData?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      type: taskData?.type || null,
    }),
    validationSchema: taskSchema,
    handleSubmit: async (values, { props, setSubmitting }) => {
      const uploadedFiles = await uploadFilesAndReturnUploadedFiles(props.files, values);

      const updatedValues = { ...values, files: uploadedFiles.filter((file) => file !== "") }; // remove empty items from array which were added by null trigger
      const response = props.taskData
        ? await store.dispatch(taskThunks.updateTask(updatedValues))
        : await store.dispatch(taskThunks.createTask(updatedValues));

      if (!response.error) {
        toast.success(
          props.taskData ? props.t("messages.success.toast.updateTask") : props.t("messages.success.toast.createTask")
        );
        setSubmitting(false);

        if (props.taskData) {
          props.closeModal();
        } else props.navigate(`/tasks/${response.payload.id}`);
      }
    },
    enableReinitialize: true,
  })(TaskForm)
);
