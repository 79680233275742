import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Box, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import store from "store";
import { taskActions, taskThunks } from "store/ducks/task";

const TaskCardMenu = ({ task }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenLead = (id) => {
    window.open(`/leads/${id}`, "_blank");
    handleClose();
  };

  const handleCancelTask = async () => {
    const updatedTask = { ...task, status: { name: "CANCELLED", label: "Cancelled", type: "TaskStatus" } };
    const res = await store.dispatch(taskThunks.updateTask(updatedTask));

    if (!res.error) {
      toast.success(t("messages.success.toast.taskCancel"));
      handleClose();
      if (res.meta.requestStatus === "fulfilled") {
        store.dispatch(taskActions.deleteItem({ collection: "activeTasks", data: res.payload }));
      }
    }
  };

  const handleCompleteTask = async () => {
    const updatedTask = { ...task, status: { name: "COMPLETED", label: "Completed", type: "TaskStatus" } };
    const res = await store.dispatch(taskThunks.updateTask(updatedTask));
    if (!res.error) {
      toast.success(t("messages.success.toast.taskComplete"));
      handleClose();
      if (res.meta.requestStatus === "fulfilled") {
        store.dispatch(taskActions.deleteItem({ collection: "activeTasks", data: res.payload }));
      }
    }
  };

  return (
    <>
      <IconButton
        id="task"
        aria-controls={open ? "task-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClickOpen}
        sx={{ p: 0, color: "primary.lighter", "&:hover": { color: "text.main" } }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="task-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "task",
          role: "listbox",
        }}
        sx={{ py: 0 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            ml: 1,
            px: 1,

            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {task.leads.length !== 0 && (
          <Box>
            {task.leads.map((lead) => (
              <MenuItem
                key={lead.id}
                sx={{
                  color: "text.main",
                  transition: "color 0.3s, backgroundColor 0.3s",
                  borderRadius: 1,
                  "&:hover": {
                    color: "primary.main",
                    backgroundColor: "primary.lighter",
                  },
                }}
                onClick={() => handleOpenLead(lead.id)}
              >
                <Typography>
                  {t("types.lead.cardMenu.open")} {lead.name}
                </Typography>
              </MenuItem>
            ))}
            <Divider sx={{ width: "100%", my: 0 }} />
          </Box>
        )}
        <MenuItem
          sx={{
            color: "success.main",
            transition: "color 0.3s, backgroundColor 0.3s",
            borderRadius: 1,
            "&:hover": {
              color: "success.main",
              backgroundColor: "success.light",
            },
          }}
          onClick={handleCompleteTask}
        >
          <Typography>{t("types.lead.cardMenu.completeTask")}</Typography>
        </MenuItem>
        <MenuItem
          sx={{
            color: "error.main",
            transition: "color 0.3s, backgroundColor 0.3s",
            borderRadius: 1,
            "&:hover": {
              color: "error.main",
              backgroundColor: "error.light",
            },
          }}
          onClick={handleCancelTask}
        >
          <Typography>{t("types.lead.cardMenu.cancelTask")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default memo(TaskCardMenu);
