import { memo } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Avatar, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";

import store from "store";
import { uploadSelectors, uploadThunks } from "store/ducks/upload";
import { userSelectors, userThunks } from "store/ducks/user";

import { AvatarChangeIcon, DeleteIcon } from "theme/icons";

const UserAvatar = () => {
  const { t } = useTranslation();
  const user = useSelector(userSelectors.getCurrentUser());
  const { avatar, contact } = user;

  const fileUrl = useSelector(uploadSelectors.getUploadedFileUrl());
  const isLoading = useSelector(uploadSelectors.uploadedFileUrlLoading()) && !!fileUrl;

  const handleChooseFile = async (event) => {
    const file = event?.target.files[0];

    const response = await store.dispatch(uploadThunks.uploadImage(file));

    if (response?.meta.requestStatus === "fulfilled") {
      const res = await store.dispatch(userThunks.updateUserAvatar({ ...user, avatar: response.payload }));

      if (!res.error) {
        toast.success(avatar ? t("messages.success.toast.updateAvatar") : t("messages.success.toast.addAvatar"));
      }
    }
  };

  const handleDelete = async () => {
    const resp = await store.dispatch(userThunks.updateUserAvatar({ ...user, avatar: "" }));
    if (!resp.error) {
      toast.success(t("messages.success.toast.deleteAvatar"));
    }
  };

  return (
    <>
      <Avatar
        variant="rounded"
        sx={{
          width: 100,
          height: 100,
          bgcolor: "primary.main",
          position: "relative",
          "&:hover::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          },
        }}
      >
        {avatar ? (
          <img src={avatar} alt="avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
        ) : (
          <Typography
            variant="h1"
            color="extra.white"
            sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
          >
            {contact.lastName?.charAt(0) + contact.name?.charAt(0)}
          </Typography>
        )}
      </Avatar>
      <Stack direction="row" alignItems="center" justifyContent="center" mt={1}>
        <label htmlFor="upload-image">
          <Tooltip title={avatar ? t("base.tooltips.editPhoto") : t("base.tooltips.addPhoto")}>
            <IconButton
              component="span"
              disabled={isLoading}
              sx={{ minWidth: 0, color: avatar ? "success.main" : "primary.main" }}
            >
              {avatar ? <AvatarChangeIcon style={{ width: 24, height: 24 }} /> : <AddPhotoAlternateOutlinedIcon />}
            </IconButton>
          </Tooltip>
          <input
            id="upload-image"
            hidden
            accept="image/*"
            type="file"
            onChange={handleChooseFile}
            disabled={isLoading}
          />
        </label>
        {avatar && (
          <Tooltip title={t("base.tooltips.deletePhoto")}>
            <IconButton sx={{ color: "error.main", minWidth: 0 }} onClick={handleDelete}>
              <DeleteIcon style={{ width: 20, height: 20 }} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </>
  );
};

export default memo(UserAvatar);
