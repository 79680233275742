import { memo } from "react";

import { Divider, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";

import { SortUpDownIcon } from "theme/icons";
import TaskCard from "./TaskCard";
import { MOBILE_VIEW } from "utils/constants";
import { useTheme } from "@emotion/react";

const TasksColumn = ({ column, list }) => {
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const theme = useTheme();

  return (
    <Stack
      key={column.title}
      px={1}
      py={2}
      height="100%"
      borderRadius={2}
      minWidth={isMobile ? "100%" : "0%"}
      maxWidth={isMobile ? "100%" : "25%"}
      boxShadow={`0px 0px 4px 0px ${theme.palette.primary.lighter}`}
      sx={{ backgroundColor: "secondary.lighter", flex: 1 }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ cursor: "grab" }}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="h6" color="text.dark">
            {column.title}
          </Typography>
          <Typography color="text.dark">({list.length})</Typography>
        </Stack>
        <IconButton>
          <SortUpDownIcon />
        </IconButton>
      </Stack>
      <Divider sx={{ borderColor: "background.default", borderWidth: "1px", my: 2 }} />
      <Stack sx={{ overflowY: "auto" }} gap={1}>
        {list?.map((task) => (
          <TaskCard key={task.id} task={task} />
        ))}
      </Stack>
    </Stack>
  );
};

export default memo(TasksColumn);
