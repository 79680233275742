import { memo, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import TasksGrid from "./components/TasksGrid";
import TasksList from "./components/TasksList";

import { getDataFromLS } from "utils/helpers";

const TasksPage = () => {
  const navigate = useNavigate();

  const tasksView = getDataFromLS("tasksPageView");
  const [searchParams, setSearchParams] = useSearchParams();

  const [pageView, setPageView] = useState(tasksView || "col");

  const [filter, setFilter] = useState({
    my: true,
    byMe: false,
    notMy: false,
    type: null,
  });

  const handleFilterChange = (eventOrValue, name) => {
    if (eventOrValue === null) {
      setFilter({ ...filter, type: null });
      return;
    }
    
    if (eventOrValue.target) {
      setFilter({
        ...filter,
        [eventOrValue.target.name]: eventOrValue.target.checked,
      });
    } else {
      setFilter({
        ...filter,
        [name]: eventOrValue,
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("tasksPageView", JSON.stringify(pageView));
  }, [pageView]);

  const togglePageView = () => {
    setPageView((prevPageView) => {
      const newPageView = prevPageView === "list" ? "col" : "list";
      setSearchParams({});
      return newPageView;
    });
  };

  const navigateToCreateNewTaskPage = () => {
    navigate("/tasks/new");
  };

  return pageView === "col" ? (
    <TasksGrid
      togglePageView={togglePageView}
      pageView={pageView}
      createNewItemAction={navigateToCreateNewTaskPage}
      tasksFilter={filter}
      handleFilterChange={handleFilterChange}
    />
  ) : (
    <TasksList
      togglePageView={togglePageView}
      pageView={pageView}
      createNewItemAction={navigateToCreateNewTaskPage}
      tasksFilter={filter}
      handleFilterChange={handleFilterChange}
    />
  );
};

export default memo(TasksPage);
