import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { Button, CircularProgress, IconButton, Stack, TextField } from "@mui/material";

import store from "store";
import { userSelectors } from "store/ducks/user";
import { leadActions, leadThunks } from "store/ducks/lead";

import ActionsNewTask from "./ActionsNewTask";
import { AssignmentOutlined, CommentOutlined } from "@mui/icons-material";

const InteractionActionsMobile = ({ lead, setIsShownMobileActionsBar }) => {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState("comment");
  const user = useSelector(userSelectors.getCurrentUser());

  const responsible = [];
  if (user) responsible.push(user);

  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleAddComment = async () => {
    setIsLoading(true);

    const response = await store.dispatch(
      leadThunks.addComment({
        id: lead.id,
        comment: value,
      })
    );

    if (!response.error) {
      setIsLoading(false);
      store.dispatch(leadActions.updateItem({ collection: "lead", data: response.payload }));
      setValue("");
      toast.success(t("messages.success.toast.addComment"));
      setIsShownMobileActionsBar(false);
    }
  };

  return (
    <Stack mt={2} sx={{ backgroundColor: "secondary.lighter" }} direction="column" borderRadius={2} gap={1} p={1}>
      <Stack direction="row" gap={1.5}>
        <IconButton
          onClick={() => setSelectedItem("comment")}
          sx={{ p: 0, color: selectedItem === "comment" ? "primary.main" : "primary.light" }}
        >
          <CommentOutlined sx={{}} />
        </IconButton>
        <IconButton
          onClick={() => setSelectedItem("task")}
          sx={{ p: 0, color: selectedItem === "task" ? "primary.main" : "primary.light" }}
        >
          <AssignmentOutlined fontSize="medium" />
        </IconButton>
      </Stack>
      {selectedItem === "comment" && (
        <>
          <TextField
            variant="outlined"
            placeholder={t("base.placeholders.comment")}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            sx={{
              width: "100%",
              borderRadius: 2,
              backgroundColor: "extra.white",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "secondary.light",
                },
              },
            }}
          />
          <Stack direction="row" alignItems="center" gap={2} width="100%">
            <Button
              variant="contained"
              onClick={handleAddComment}
              sx={{ width: "100%" }}
              disabled={isLoading || !value}
              startIcon={isLoading && <CircularProgress size={16} sx={{ mr: 1, color: "extra.white" }} />}
            >
              {isLoading ? t("base.buttons.adding") : t("base.buttons.add")}
            </Button>
            <Button variant="outlined" onClick={() => setIsShownMobileActionsBar(false)} sx={{ width: "100%" }}>
              {t("base.buttons.cancel")}
            </Button>
          </Stack>
        </>
      )}
      {selectedItem === "task" && (
        <ActionsNewTask
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          leads={[lead]}
          lead={lead}
          responsible={responsible}
          setIsShownMobileActionsBar={setIsShownMobileActionsBar}
          t={t}
        />
      )}
    </Stack>
  );
};

export default memo(InteractionActionsMobile);
