import DOMPurify from "dompurify";
import filters from "filters";
import { DateTime } from "luxon";
import { useState } from "react";
import store from "store";
import { uploadThunks } from "store/ducks/upload";

const navigator = window.navigator || {};

const fallbackCopyTextToClipboard = async (text) => {
  const textArea = document.createElement("input");
  textArea.style.position = "fixed";
  textArea.style.top = "0";
  textArea.style.left = "-9999px";
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  textArea.setSelectionRange(0, 99999);

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    return msg;
  } catch (err) {
    console.err("Unable to copy to clipboard"); //eslint-disable-line
  }

  document.body.removeChild(textArea); //eslint-disable-line
  return "success";
};

/** --- Copy to Clipboard --- */
export function useCopyToClipboard() {
  const [copyResult, setCopyResult] = useState(null);

  const copy = async (text) => {
    try {
      if (!navigator.clipboard) {
        const msg = await fallbackCopyTextToClipboard(text);
        setCopyResult({ state: "success", message: msg });
      } else await navigator.clipboard.writeText(text);
      setCopyResult({ state: "success" });
    } catch (e) {
      setCopyResult({ state: "error", message: e.message });
      throw e;
    } finally {
      // 👇 Show the result feedback for 2 seconds
      setTimeout(() => {
        setCopyResult(null);
      }, 2000);
    }
  };

  // 👇 We want the result as a tuple
  return [copy, copyResult];
}

export const getLastNameAndFirstNameAndSecondNameLetter = (option) => {
  if (!option?.contact) return "";

  const { lastName, name, secondName } = option.contact;
  let result = "";

  if (lastName) {
    result += lastName;
  }

  if (name) {
    result += (lastName ? " " : "") + name[0] + ".";
  }

  if (secondName) {
    result += secondName[0] + ".";
  }

  if (!lastName && !secondName && name) {
    result = name;
  }

  return result;
};

export const getLastNameAndNameLetter = (option) => {
  return option?.contact ? option.contact?.lastName + " " + option.contact?.name[0] + "." : "";
};

export const getShortName = (option) => {
  return option ? option.contact?.lastName + " " + option.contact?.name : "";
};

export const getLeadNameForTask = (option) => {
  return option ? option.name + " | " + filters.date(option.createdAt, "dd.MM.yy") : "";
};

export const capitalize = (string) => {
  return string.at(0).toUpperCase() + string.slice(1);
};

/** --- Get data from local storage --- */
export const getDataFromLS = (key) => {
  const lsData = localStorage.getItem(key);
  if (!lsData) return "";
  try {
    const value = JSON.parse(lsData);
    return value;
  } catch (e) {
    return "";
  }
};

/** --- Function that uploads files and returns an array with uploaded files data --- */
export const uploadFilesAndReturnUploadedFiles = async (files, values) => {
  const uploadedFiles = [];

  await Promise.all(
    files?.map(async (file, index) => {
      /** -- null - is the trigger that we should remove file --- */
      if (file === null) {
        uploadedFiles.push("");
        /** -- check if we have already uploaded file  and there is no updates we just leave the file as it is--- */
      } else if (file === "" && values?.files[index] && file !== null) {
        uploadedFiles.push(values?.files[index]);
        /** -- we upload the file in case if the input is not empty.
         * We always have empty item in array in order to render first upload file input,
         * that's why this condition is needed  --- */
      } else if (file !== "" && file !== undefined) {
        const response = await store.dispatch(uploadThunks.uploadFile(file));
        if (response.meta.requestStatus === "fulfilled") {
          uploadedFiles.push(response.payload);
        }
      }
    })
  );

  return uploadedFiles;
};

/** Define task status depending on Due Date. Returns one of the values:
 * today, tomorrow, overdue or future
 */

export const defineTaskStatus = (dueDate) => {
  if (!dueDate) return null;

  const today = DateTime.local().startOf("day");

  if (DateTime.fromISO(dueDate).equals(today)) {
    return "today";
  }

  if (DateTime.fromISO(dueDate) < today) {
    return "overdue";
  }

  if (DateTime.fromISO(dueDate).equals(today.plus({ days: 1 }))) {
    return "tomorrow";
  }

  if (DateTime.fromISO(dueDate) > today.plus({ days: 1 })) {
    return "future";
  }
};

export const isPipelineStatusChangedToArchived = (currentStatus, newStatus) => {
  return currentStatus?.name === "ACTIVE" && newStatus?.name === "ARCHIVED";
};

export const isFilterFieldFilled = (filterData) => {
  return Object.values(filterData).some((item) => {
    if (Array.isArray(item)) {
      return item?.length !== 0;
    } else return item !== null;
  });
};

export const saveAsExcelFile = (buffer, fileName) => {
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const downloadLink = document.createElement("a");
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url);
};

export const linkify = (inputText) => {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  const commentWithLink = DOMPurify.sanitize(inputText).replace(urlRegex, function (url) {
    let hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = "http://" + hyperlink;
    }
    return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + "</a>";
  });
  return commentWithLink;
};
