import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { Divider, Stack, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from "@mui/material";

import { MOBILE_VIEW, PERIODS } from "utils/constants";

const chartData = [
  { name: "Mon", value: 2390 },
  { name: "Tue", value: 3000 },
  { name: "Wed", value: 4000 },
  { name: "Thu", value: 2780 },
  { name: "Fri", value: 1890 },
  { name: "Sat", value: 2390 },
  { name: "Sun", value: 3490 },
];

const Chart = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selected, setSelected] = useState("day");

  const isMobile = useMediaQuery(MOBILE_VIEW);

  const handleChange = (event, newPeriod) => {
    setSelected(newPeriod);
  };

  return (
    <Stack sx={{ backgroundColor: "extra.white", borderRadius: 2, px: 2, py: 1 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" color="text.main">
          {t("types.dashboard.productivity")}
        </Typography>
        <ToggleButtonGroup exclusive value={selected} onChange={handleChange}>
          {PERIODS.map((period) => {
            return (
              <ToggleButton
                sx={{
                  background: "none",
                  minWidth: "fit-content",
                  "&.Mui-selected:hover, &:hover": {
                    backgroundColor: "transparent",
                    color: "primary.main",
                  },
                }}
                key={period}
                value={period}
              >
                {t(`periods.${period}`)}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Stack>
      <Divider sx={{ width: "100%" }} />
      <Stack mt={2} height="360px" width="100%">
        <ResponsiveContainer height={"100%"} width="100%">
          <AreaChart data={chartData}>
            <defs>
              <linearGradient id="colorProgress" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse">
                <stop stopColor={theme.palette.dashboardChart.start} />
                <stop offset="1" stopColor={theme.palette.dashboardChart.finish} stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              axisLine={false}
              tickLine={false}
              dataKey="name"
              style={{ fontSize: isMobile ? 12 : 14, color: theme.palette.text.main, fontWeight: 500 }}
              dy={4}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              style={{ fontSize: isMobile ? 12 : 14, color: theme.palette.text.main, fontWeight: 500 }}
              dx={-16}
            />
            <Tooltip />

            <Area type="monotone" dataKey="value" stroke="none" fill="url(#colorProgress)" />
          </AreaChart>
        </ResponsiveContainer>
      </Stack>
    </Stack>
  );
};

export default Chart;
