const getEnums = () => (state) => state.dictionary.enums.data;
const enumsLoading = () => (state) => state.dictionary.enums.isLoading;

const getPrograms = () => (state) => state.dictionary.programs.data;
const programsLoading = () => (state) => state.dictionary.programs.isLoading;

const getManagers = () => (state) => state.dictionary.managers.data;
const managersLoading = () => (state) => state.dictionary.managers.isLoading;

const getEmployees = () => (state) => state.dictionary.employees.data;
const employeesLoading = () => (state) => state.dictionary.employees.isLoading;

const getMarketingChannels = () => (state) => state.dictionary.marketingChannels.data;
const marketingChannelsLoading = () => (state) => state.dictionary.marketingChannels.isLoading;

const getLeadCloseReasons = () => (state) => state.dictionary.leadCloseReasons.data;
const leadCloseReasonsLoading = () => (state) => state.dictionary.leadCloseReasons.isLoading;

const getCountries = () => (state) => state.dictionary.countries.data;
const countriesLoading = () => (state) => state.dictionary.countries.isLoading;

const getCities = () => (state) => state.dictionary.cities.data;
const citiesLoading = () => (state) => state.dictionary.cities.isLoading;

const getUniversities = () => (state) => state.dictionary.universities.data;
const universitiesLoading = () => (state) => state.dictionary.universities.isLoading;

const getProfessions = () => (state) => state.dictionary.professions.data;
const professionsLoading = () => (state) => state.dictionary.professions.isLoading;

const getEducations = () => (state) => state.dictionary.educations.data;
const educationsLoading = () => (state) => state.dictionary.educations.isLoading;

const getTaskTypes = () => (state) => state.dictionary.taskTypes.data;
const taskTypesLoading = () => (state) => state.dictionary.taskTypes.isLoading;

export default {
  getEnums,
  enumsLoading,

  getPrograms,
  programsLoading,

  getManagers,
  managersLoading,

  getEmployees,
  employeesLoading,

  getMarketingChannels,
  marketingChannelsLoading,

  getLeadCloseReasons,
  leadCloseReasonsLoading,

  getCountries,
  countriesLoading,

  getCities,
  citiesLoading,

  getUniversities,
  universitiesLoading,

  getProfessions,
  professionsLoading,

  getEducations,
  educationsLoading,

  getTaskTypes,
  taskTypesLoading,
};
