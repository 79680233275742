import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import loadDataInitialState from "store/utils/loadDataInitialState";
import loadDataExtraReducer from "store/utils/loadDataExtraReducer";
import loadDataPaginatedState from "store/utils/loadDataPaginatedState";

const pipeline = createSlice({
  name: "pipelineSlice",
  initialState: {
    pipelines: loadDataInitialState([]),
    pipeline: loadDataInitialState(null),
    stagesLeads: {},
  },
  reducers: {
    addItem(state, action) {
      state[action.payload.collection].data.push(action.payload.data);
    },
    replaceItem(state, action) {
      const index = state[action.payload.collection].data.findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data[index] = action.payload.data;
    },
    updateItem(state, action) {
      state[action.payload.collection].data = action.payload.data;
    },
    deleteItem(state, action) {
      const index = state[action.payload.collection].data.findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data.splice(index, 1);
    },
    moveLeadBetweenStages(state, action) {
      const { collection, currentStageId, overStageId, data } = action.payload;
      //** --- delete the lead from current stage in Dnd ---  */
      const indexLeadOfCurrentStage = state[collection][currentStageId].data.content.findIndex((i) => i.id === data.id);
      state[collection][currentStageId].data.content.splice(indexLeadOfCurrentStage, 1);
      //** --- adding the lead to over stage in Dnd ---  */
      state[collection][overStageId].data.content.push(data);
    },

    deleteLead(state, action) {

      //** ---- action for removing the lead from stage column after the closing lead --- */
      const { collection, stageId, data } = action.payload;
      const index = state[collection][stageId].data.content.findIndex((i) => i.id === data.id);

      if (index !== -1) {
        state[collection][stageId].data.content.splice(index, 1);
      }
    },
    resetStagesLeads(state) {
      state.stagesLeads = {};
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchPipelines, "pipelines");
    loadDataExtraReducer(builder, thunks.fetchPipeline, "pipeline");

    builder.addCase(thunks.fetchStagesLeads.pending, (state, action) => {
      const stageId = action.meta.arg.stageId;
      if (!state.stagesLeads[stageId]) {
        state.stagesLeads[stageId] = loadDataPaginatedState();
      }
      state.stagesLeads[stageId].isLoading = true;
    });

    builder.addCase(thunks.fetchStagesLeads.fulfilled, (state, action) => {
      const { stageId, leadsData, page } = action.payload;
      state.stagesLeads[stageId].isLoading = false;
      state.stagesLeads[stageId].error = null;

      if (page === 0) {
        state.stagesLeads[stageId].data = leadsData;
      } else {

        //** --- validation for avoiding duplicates in state stagesLeads. This validation is necessary for avoiding 
        //*-- duplicates when move lead from stage and it triggers the fetching leads from the next page ---- */
        const existingLeadIds = new Set(state.stagesLeads[stageId].data.content.map((lead) => lead.id));
        const newLeads = leadsData.content.filter((lead) => !existingLeadIds.has(lead.id));
        
        
        state.stagesLeads[stageId].data = {
          ...leadsData,
          content: [...state.stagesLeads[stageId].data.content, ...newLeads],
        };
      }
    });

    builder.addCase(thunks.fetchStagesLeads.rejected, (state, action) => {
      const stageId = action.meta.arg.stageId;
      if (!state.stagesLeads[stageId]) {
        state.stagesLeads[stageId] = loadDataPaginatedState();
      }
      state.stagesLeads[stageId].isLoading = false;
      state.stagesLeads[stageId].error = action.error;
    });
  },
});

export const { addItem, replaceItem, updateItem, deleteItem, deleteLead, resetStagesLeads, moveLeadBetweenStages } =
  pipeline.actions;
export default pipeline.reducer;
