import {
  SettingsOutlined,
  AssignmentOutlined,
  MonetizationOnOutlined,
  RoofingOutlined,
  PlaylistAddCheckOutlined,
  ContactPageOutlined,
  CommentOutlined,
} from "@mui/icons-material";
import {
  ArchiveIcon,
  UpdatedIcon,
  ChatIcon,
  CircleIcon,
  IncomeCall,
  StageChangedIcon,
  PipeLineChangedIcon,
  DueDateIcon,
  OutcomeCall,
  StatusChangedIcon,
} from "theme/icons";
import { dictionarySelectors, dictionaryThunks } from "store/ducks/dictionary";

export const API_TOKEN = "token";
export const AUTH_HEADER = "authorization";

export const REG_EXP = {
  string: /^[a-zA-ZА-ЩЬЮЯҐЄІЇа-щьюяґєії'ʼ-]+$/,
  phone: /((\+38)?\(?\d{3}\)?[\s/.]?(\d{7}|\d{3}[\s/.]\d{2}[\s/.]\d{2}|\d{3}\d{4}))/,
  lower: /^(?=.*[a-z])/,
  upper: /^(?=.*[A-Z])/,
  digit: /^(?=.*[0-9])/,
  specChar: /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
  url: /((https?:\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?)|((https?:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+)/,
};

export const MAIN_MENU = [
  {
    key: "dashboard",
    to: "/home",
    icon: <RoofingOutlined />,
    //permission: PERMISSIONS.dashboard //TODO: is needed?
  },
  {
    key: "leads",
    to: "/leads",
    icon: <MonetizationOnOutlined />,
    // permission: PERMISSIONS.programs //TODO: is needed?
  },
  { key: "pipelines", to: "/pipelines", icon: <AssignmentOutlined /> },
  { key: "tasks", to: "/tasks", icon: <PlaylistAddCheckOutlined /> },
  { key: "contacts", to: "/contacts", icon: <ContactPageOutlined /> },
  { key: "settings", to: "/settings", icon: <SettingsOutlined /> },
];

export const DESKTOP_HEIGHT = "calc(100dvh - 16px)"; // 16px bottom indent
export const MOBILE_HEIGHT = "calc(100dvh - 72px)"; // 72px top bar height
export const TABLET_HEIGHT = "calc(100dvh - 88px)"; // 88px top bar height

export const MOBILE_VIEW = (theme) => theme.breakpoints.down("sm");
export const TABLET_VIEW = (theme) => theme.breakpoints.between("sm", "lg");
export const DESKTOP_VIEW = (theme) => theme.breakpoints.up("lg");

export const PERIODS = ["day", "week", "month", "year"];

export const TASK_STATUS_COLOR = (theme) => ({
  NEW: theme.palette.primary.main,
  COMPLETED: theme.palette.success.main,
  PENDING: theme.palette.warning.main,
  CANCELLED: theme.palette.error.main,
});

export const TASK_PERFORMANCE_DEADLINE_COLOR = (theme) => ({
  today: { color: theme.palette.warning.main, key: "today" },
  tomorrow: { color: theme.palette.info.main, key: "tomorrow" },
  future: { color: theme.palette.success.main, key: "future" },
  overdue: { color: theme.palette.error.main, key: "overdue" },
});

export const LEAD_STATUS_COLOR = (theme) => ({
  ACTIVE: theme.palette.info.main,
  ARCHIVED: theme.palette.warning.main,
});

export const PIPELINE_STATUSES = (theme) => [
  {
    key: "archived",
    name: "archived",
    color: theme.palette.warning.main,
    icon: <ArchiveIcon fill={theme.palette.warning.main} />,
  },
  {
    key: "active",
    name: "ACTIVE",
    color: theme.palette.success.main,
    icon: <UpdatedIcon fill={theme.palette.success.main} />,
  },
];

export const INTERACTION_TYPES_ICONS = {
  COMMENT: <ChatIcon />,
  FIELD_CHANGED: <CircleIcon />,
  STAGE_CHANGED: <StageChangedIcon />,
  PIPELINE_CHANGED: <PipeLineChangedIcon />,
  PHONE_CALL_IN: <IncomeCall />,
  PHONE_CALL_OUT: <OutcomeCall />,
  TASK: <DueDateIcon />,
  STATUS_CHANGED: <StatusChangedIcon />,
};

export const INTERACTION_TYPES = [
  { key: "comment", type: "COMMENT", icon: <ChatIcon /> },
  { key: "field", type: "FIELD_CHANGED", icon: <CircleIcon /> },
  { key: "stage", type: "STAGE_CHANGED", icon: <StageChangedIcon /> },
  { key: "pipeline", type: "PIPELINE_CHANGED", icon: <PipeLineChangedIcon /> },
  { key: "incomeCall", type: "PHONE_CALL_IN", icon: <IncomeCall /> },
  { key: "outcomeCall", type: "PHONE_CALL_OUT", icon: <OutcomeCall /> },
  { key: "task", type: "TASK", icon: <DueDateIcon /> },
  { key: "status", type: "STATUS_CHANGED", icon: <StatusChangedIcon /> },
];

export const INTERACTION_ACTIONS_MENU = [
  { key: "comment", icon: <CommentOutlined /> },
  { key: "task", icon: <AssignmentOutlined /> },
];

export const INTERACTION_MESSAGES = {
  FIELD_CHANGED: (item, t) =>
    item.before === "" || !item.before
      ? `${t("types.lead.interactionsMessages.field")}  "${item?.field}" ${t("types.lead.interactionsMessages.hasBeenSet")} "${item?.after}"`
      : `${t("types.lead.interactionsMessages.field")} "${item?.field}" ${t("types.lead.interactionsMessages.wasChanged")}. ${t("types.lead.interactionsMessages.from")} "${item?.before || ""}", ${t("types.lead.interactionsMessages.to").toLowerCase()} "${item?.after || ""}".`,
  STAGE_CHANGED: (item, t) =>
    `${t("types.lead.interactionsMessages.stage")} ${t("types.lead.interactionsMessages.wasChanged")}. ${t("types.lead.interactionsMessages.from")} "${item?.before || ""}", ${t("types.lead.interactionsMessages.to").toLowerCase()} "${item?.after || ""}".`,
  STATUS_CHANGED: (item, t) =>
    `${t("types.lead.interactionsMessages.status")} ${t("types.lead.interactionsMessages.wasChanged")}. ${t("types.lead.interactionsMessages.from")} "${item?.before || ""}", ${t("types.lead.interactionsMessages.to").toLowerCase()} "${item?.after || ""}".`,
  PIPELINE_CHANGED: (item, t) =>
    `${t("types.lead.interactionsMessages.pipeline")} ${t("types.lead.interactionsMessages.wasChanged")}. ${t("types.lead.interactionsMessages.from")} "${item?.before || ""}", ${t("types.lead.interactionsMessages.to").toLowerCase()} "${item?.after || ""}".`,
};

/* ---  DICTIONARY --- */
export const DICTIONARIES_LIST = [
  {
    name: "marketing_channels",
    label: "Marketing channels",
    selector: dictionarySelectors.getMarketingChannels(),
    createItem: (data) => dictionaryThunks.createMarketingChannel(data),
    updateItem: (data) => dictionaryThunks.updateMarketingChannel(data),
    deleteItem: (id) => dictionaryThunks.deleteMarketingChannel(id),
  },
  {
    name: "lead_close_reason",
    label: "Lead close reason",
    selector: dictionarySelectors.getLeadCloseReasons(),
    createItem: (data) => dictionaryThunks.createLeadCloseReason(data),
    updateItem: (data) => dictionaryThunks.updateLeadCloseReason(data),
    deleteItem: (id) => dictionaryThunks.deleteLeadCloseReason(id),
  },
  {
    name: "location",
    label: "Location",
    subList: [
      {
        name: "countries",
        label: "Countries",
        selector: dictionarySelectors.getCountries(),
        createItem: (data) => dictionaryThunks.createCountry(data),
        updateItem: (data) => dictionaryThunks.updateCountry(data),
        deleteItem: (id) => dictionaryThunks.deleteCountry(id),
      },
      {
        name: "cities",
        label: "Cities",
        selector: dictionarySelectors.getCities(),
        createItem: (data) => dictionaryThunks.createCity(data),
        updateItem: (data) => dictionaryThunks.updateCity(data),
        deleteItem: (id) => dictionaryThunks.deleteCity(id),
      },
    ],
  },
  {
    name: "universities",
    label: "Universities",
    selector: dictionarySelectors.getUniversities(),
    createItem: (data) => dictionaryThunks.createUniversity(data),
    updateItem: (data) => dictionaryThunks.updateUniversity(data),
    deleteItem: (id) => dictionaryThunks.deleteUniversity(id),
  },
  {
    name: "educations",
    label: "Educations",
    selector: dictionarySelectors.getEducations(),
    createItem: (data) => dictionaryThunks.createEducation(data),
    updateItem: (data) => dictionaryThunks.updateEducation(data),
    deleteItem: (id) => dictionaryThunks.deleteEducation(id),
  },
  {
    name: "professions",
    label: "Professions",
    selector: dictionarySelectors.getProfessions(),
    createItem: (data) => dictionaryThunks.createProfession(data),
    updateItem: (data) => dictionaryThunks.updateProfession(data),
    deleteItem: (id) => dictionaryThunks.deleteProfession(id),
  },
  {
    name: "taskTypes",
    label: "Task types",
    selector: dictionarySelectors.getTaskTypes(),
    createItem: (data) => dictionaryThunks.createTaskTypes(data),
    updateItem: (data) => dictionaryThunks.updateTaskTypes(data),
  },
];
