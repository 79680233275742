import { memo } from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";

const Loader = ({ fullscreen, size = 40 }) => {
  return fullscreen ? (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
      <CircularProgress color="primary" />
    </Backdrop>
  ) : (
    <Box p={2} display="flex" justifyContent="center">
      <CircularProgress color="primary" size={size} />
    </Box>
  );
};

export default memo(Loader);
