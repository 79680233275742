import { useState } from "react";
import { Outlet } from "react-router-dom";

import { Box, Stack, useMediaQuery } from "@mui/material";

import HeaderSettings from "views/pages/settings/components/HeaderSettings";
import { DESKTOP_VIEW } from "utils/constants";
import SettingsMenu from "views/pages/settings/components/SettingsMenu";

const SettingsLayout = () => {
  const [props, setProps] = useState({
    search: false,
    buttonTitle: "",
    handleButtonClick: null,
  });
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  return (
    <Box>
      {isDesktop ? (
        <HeaderSettings
          search={props.search}
          buttonTitle={props.buttonTitle}
          handleButtonClick={props.handleButtonClick}
        />
      ) : (
        <Stack justifyContent="center">
          <SettingsMenu />
        </Stack>
      )}
      <Outlet context={[props, setProps]} />
    </Box>
  );
};

export default SettingsLayout;
