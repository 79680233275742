import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import loadDataInitialState from "store/utils/loadDataInitialState";
import loadDataExtraReducer from "store/utils/loadDataExtraReducer";

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    current: loadDataInitialState(null, true),
    initialLoading: true,
  },
  reducers: {
    loadingFinished(state) {
      state.initialLoading = false;
    },
    updateUser(state, action) {
      state[action.payload.collection].data = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchUser, "current");
    loadDataExtraReducer(builder, thunks.login, "current");
  },
});

export const { loadingFinished, updateUser } = userSlice.actions;
export default userSlice.reducer;
