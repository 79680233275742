import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, withFormik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Stack, useMediaQuery } from "@mui/material";

import store from "store";
import { dictionarySelectors } from "store/ducks/dictionary";
import { pipelineSelectors, pipelineThunks } from "store/ducks/pipeline";

import { FormColumnTitle, FormColumnWrapper, PageWrapper } from "views/common/styledComponents";
import HeaderForm from "views/common/HeaderForm";
import FormikTextField from "views/form/FormikTextField";
import FormikAutocomplete from "views/form/FormikAutocomplete";
import FormikArrayTextField from "views/form/FormikArrayTextField";
import FormikCheckbox from "views/form/FormikCheckbox";
import StagesList from "./StagesList";

import { MOBILE_VIEW } from "utils/constants";

import useQuickAddInput from "hooks/useQuickAddInput";
import { pipelineFormSchema } from "utils/schema";
import { leadThunks } from "store/ducks/lead";
import { isPipelineStatusChangedToArchived } from "utils/helpers";

const PipelineForm = ({
  pipelineData,
  values,
  modalOpen,
  closeModal,
  openModal,
  isSubmitting,
  setFieldValue,
  setSubmitting,
  dirty,
}) => {
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const { t } = useTranslation();

  const enums = useSelector(dictionarySelectors.getEnums());
  const { activeStatuses: statuses } = enums;

  const [editMode, newValue, setNewValue, enableEditMode, disableEditMode, isLoading, setIsLoading] =
    useQuickAddInput();

  const pipelines = useSelector(pipelineSelectors.getPipelines());

  const isExistMainPipeline = pipelines.some((pipeline) => pipeline.main === true);
  const mainPipeline = pipelines.find((pipeline) => pipeline.main === true);
  const isCurrentPipelineMain = mainPipeline?.id === pipelineData?.id;

  useEffect(() => {
    pipelines.length === 0 && store.dispatch(pipelineThunks.fetchPipelines());
  }, [pipelines]);

  const handleAddNewStage = async () => {
    /** -- if it is existing pipeline we create stage separately from pipeline,
     * otherwise we set values to the form and create stages with pipeline
     */
    if (pipelineData) {
      setIsLoading(true);
      const response = await store.dispatch(
        pipelineThunks.createStage({
          id: pipelineData.id,
          stageData: {
            name: newValue,
          },
        })
      );

      if (!response.error) {
        setIsLoading(false);
        toast.success(t("messages.success.toast.createStage"));
      }
    } else setFieldValue("stages", [...values.stages, { name: newValue }]);
  };

  const isChangedToArchive = pipelineData && isPipelineStatusChangedToArchived(pipelineData?.status, values?.status);

  const newPipelineValues = {
    id: values.id,
    name: values.name,
    description: values.description,
    status: values.status,
    pageLinks: values.pageLinks,
    main: values.main,
  };

  const handleArchivePipeline = async () => {
    setSubmitting(true);
    const pipeLineResp = await store.dispatch(pipelineThunks.updatePipeline(newPipelineValues));
    if (!pipeLineResp.error) {
      toast.success(t("messages.success.toast.updatePipeline"));
      closeModal();
      setSubmitting(false);
    }
  };

  const handleArchivePipelineAndAllLeads = async (closeReason) => {
    setSubmitting(true);
    const pipeLineResp = await store.dispatch(pipelineThunks.updatePipeline(newPipelineValues));

    if (pipeLineResp.meta.requestStatus === "fulfilled") {
      const archiveLeadsResp = await store.dispatch(
        leadThunks.archiveLeads({ id: pipelineData.id, closeReason: closeReason })
      );

      if (!archiveLeadsResp.error) {
        toast.success(t("messages.success.toast.archivePipelineWithLeads"));
        closeModal();
        setSubmitting(false);
      }
    }
  };

  return (
    <PageWrapper>
      <Form id="pipelineForm" style={{ height: "inherit" }}>
        <HeaderForm
          title={pipelineData ? pipelineData?.name : t("types.pipeline.new")}
          data={pipelineData}
          isSubmitting={isSubmitting}
          formId="pipelineForm"
          modalOpen={modalOpen}
          closeModal={closeModal}
          openModal={openModal}
          isFormChanged={dirty}
          isChangedToArchive={isChangedToArchive}
          handleArchivePipeline={handleArchivePipeline}
          handleArchivePipelineAndAllLeads={handleArchivePipelineAndAllLeads}
        />
        <Stack height="calc(100% - 60px)" px={isMobile ? 1 : 0}>
          <Stack direction={isMobile ? "column" : "row"} gap={isMobile ? 1 : 2} mt={isMobile ? 0 : 2} width="100%">
            {/* --- First column --- */}
            <FormColumnWrapper sx={{ width: isMobile ? "100%" : "50%" }}>
              <FormColumnTitle>{t("types.pipeline.about")}</FormColumnTitle>
              <FormikTextField name="name" placeholder={t("base.placeholders.name")} label={t("base.labels.name")} />
              <FormikTextField
                name="description"
                placeholder={t("base.placeholders.description")}
                label={t("base.labels.description")}
              />
            </FormColumnWrapper>
            {/* --- Second column --- */}
            <FormColumnWrapper sx={{ width: isMobile ? "100%" : "50%" }}>
              <FormColumnTitle>{t("types.pipeline.info")}</FormColumnTitle>
              <FormikAutocomplete name="status" label={t("base.labels.status")} options={statuses || []} />
              <FormikArrayTextField
                name="pageLinks"
                placeholder={t("base.placeholders.pageLink")}
                label={t("base.labels.pageLink")}
                values={values.pageLinks || []}
              />
              <FormikCheckbox
                name="main"
                label={t("base.labels.main")}
                size="small"
                disabled={isExistMainPipeline && !isCurrentPipelineMain}
              />
            </FormColumnWrapper>
          </Stack>

          {/* --- Stages  --- */}
          <FormColumnWrapper width="100%" mt={isMobile ? 1 : 2} sx={{ overflowY: "auto" }}>
            <FormColumnTitle>{t("types.pipeline.stagePlural")}</FormColumnTitle>
            <StagesList
              pipeline={pipelineData ? pipelineData : values}
              handleActionSubmit={handleAddNewStage}
              editMode={editMode}
              enableEditMode={enableEditMode}
              newValue={newValue}
              setNewValue={setNewValue}
              disableEditMode={disableEditMode}
              isLoading={isLoading}
              size={isMobile ? "smallSize": "largeSize"}
            />
          </FormColumnWrapper>
        </Stack>
      </Form>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ pipelineData = {} }) => ({
      ...pipelineData,
      id: pipelineData?.id,
      name: pipelineData?.name || "",
      description: pipelineData?.description || "",
      status: pipelineData?.status || null,
      pageLinks: pipelineData?.pageLinks || [],
      stages: pipelineData?.stages || [],
      main: pipelineData.main || false,
    }),
    validationSchema: pipelineFormSchema,
    handleSubmit: async (values, { props, setSubmitting }) => {
      const isChangedToArchive = isPipelineStatusChangedToArchived(props.pipelineData?.status, values.status);

      /** --- values for update pipeline w/o stages value ---*/
      const newPipelineValues = {
        id: values.id,
        name: values.name,
        description: values.description,
        status: values.status,
        pageLinks: values.pageLinks,
        main: values.main,
      };

      if (isChangedToArchive) {
        return;
      } else {
        const pipeLineResp = props.pipelineData
          ? await store.dispatch(pipelineThunks.updatePipeline(newPipelineValues)) /** update existing pipeline */
          : await store.dispatch(pipelineThunks.createPipeline(values)); /** create new pipeline */

        if (!pipeLineResp.error) {
          toast.success(
            props.pipelineData
              ? props.t("messages.success.toast.updatePipeline")
              : props.t("messages.success.toast.createPipeline")
          );
          setSubmitting(false);

          if (props.pipelineData) {
            props.closeModal();
          } else props.navigate(`/pipelines/${pipeLineResp.payload.id}`);
        }
      }
    },
    enableReinitialize: true,
  })(PipelineForm)
);
