import FormikTextField from "./FormikTextField";
import { FieldArray } from "formik";
import { Fragment, memo } from "react";
import AddInputAdornment from "./AddInputAdornment";
import RemoveInputAdornment from "./RemoveInputAdornment";
import CopyInputAdornment from "./CopyInputAdornment";
// import { usePermissionContext } from 'services/context/permissionContext';

const FormikArrayTextField = ({
  name,
  values,
  label,
  placeholder,
  InputProps = {},
  copyInputAdornment,
  checkIfContactExist,
}) => {
  const { readOnly } = InputProps;
  //TODO: permission?
  // const hasPermission = usePermissionContext();

  const handleOnBlur = () => {
    if (checkIfContactExist) checkIfContactExist();
  };
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {values && values.length > 0 ? (
            values.map((option, index) => (
              <Fragment key={index}>
                <FormikTextField
                  name={`${name}.${index}`}
                  label={label}
                  placeholder={placeholder}
                  onBlur={() => handleOnBlur(index, option)}
                  InputProps={{
                    ...InputProps,
                    endAdornment: (
                      <>
                        {copyInputAdornment && <CopyInputAdornment value={option} />}
                        {
                          // hasPermission &&
                          !readOnly && <AddInputAdornment add={() => arrayHelpers.push("")} name={name} />
                        }
                        {values.length > 1 && (
                          <RemoveInputAdornment remove={() => arrayHelpers.remove(index)} name={name} />
                        )}
                      </>
                    ),
                  }}
                />
              </Fragment>
            ))
          ) : (
            <FormikTextField
              label={label}
              name={`${name}`}
              placeholder={placeholder}
              onFocus={() => arrayHelpers.push("")}
            />
          )}
        </>
      )}
    />
  );
};

export default memo(FormikArrayTextField);
