import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";

import store from "store";
import { taskSelectors, taskThunks } from "store/ducks/task";

import Loader from "views/common/Loader";
import Error from "views/common/Error";
import { PageWrapper } from "views/common/styledComponents";
import TaskForm from "./components/TaskForm";
import useModal from "hooks/useModal";

const TaskPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [modalOpen, openModal, closeModal] = useModal();

  /* --- state  to handle files uploading (file attachments to task) --- */
  const [files, setFiles] = useState([""]);

  const task = useSelector(taskSelectors.getTask());
  const taskLoading = useSelector(taskSelectors.taskLoading());

  useEffect(() => {
    store.dispatch(taskThunks.fetchTask(id));
  }, [id]);

  if (taskLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper>
      <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
        <TaskForm
          t={t}
          taskData={task}
          files={files}
          setFiles={setFiles}
          modalOpen={modalOpen}
          openModal={openModal}
          closeModal={closeModal}
        />
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default memo(TaskPage);
