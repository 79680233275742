import { memo } from "react";
import { useField } from "formik";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import filters from "filters";
import { CalendarIcon } from "theme/icons";
// import { usePermissionContext } from 'services/context/permissionContext';

const FormikDatePicker = ({ name, disableFuture, disableFormatDate, ...rest }) => {
  const [field, meta, helpers] = useField(name);
  const { t } = useTranslation();
  // const hasPermission = usePermissionContext();
  //TODO: permissions
  const handleDateChange = (date) => {
    if (!date) {
      helpers.setValue(null);
      return;
    }
    const formattedDate = date ? filters.date(date.ts, "yyyy-MM-dd") : null;
    helpers.setValue(disableFormatDate ? date : formattedDate);
  };

  const error = !!meta.error && meta.touched;
  const helperText = !!meta.error && meta.touched && meta.error;

  return (
    <DesktopDatePicker
      {...field}
      {...rest}
      disableFuture={disableFuture}
      // readOnly={!hasPermission}
      format="dd.MM.yyyy"
      value={field.value ? DateTime.fromISO(field.value) : null}
      onChange={handleDateChange}
      slots={{
        openPickerIcon: CalendarIcon,
      }}
      slotProps={{
        textField: {
          placeholder: t("base.placeholders.dateFormat"),
          InputLabelProps: { shrink: true },
          error: error,
          helperText: helperText,
        },
      }}
    />
  );
};

export default memo(FormikDatePicker);
