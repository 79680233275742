import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api";
import { addItem, replaceItem, updateItem } from "./slice";

/**------------- PIPELINES ------------------- */
const fetchPipelines = createAsyncThunk("pipeline/fetchPipelines", () => {
  return api.get("/pipelines");
});

const fetchPipeline = createAsyncThunk("pipeline/fetchPipeline", (id) => {
  return api.get(`/pipelines/${id}`);
});

const createPipeline = createAsyncThunk("pipeline/createPipeline", (data, { dispatch }) => {
  return api.post("/pipelines", data).then((res) => {
    dispatch(addItem({ collection: "pipelines", data: res }));
    return res;
  });
});

const updatePipeline = createAsyncThunk("pipeline/updatePipeline", (data, { dispatch }) => {
  return api.put(`/pipelines/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "pipelines", data: res }));
    return res;
  });
});
/**------------- STAGES ------------------- */

const createStage = createAsyncThunk("pipeline/createStage", (data, { dispatch }) => {
  return api.post(`/pipelines/${data.id}/stages`, data.stageData).then((res) => {
    dispatch(replaceItem({ collection: "pipelines", data: res }));
    dispatch(updateItem({ collection: "pipeline", data: res }));
    return res;
  });
});

const updateStage = createAsyncThunk("pipeline/updateStage", (data, { dispatch }) => {
  return api.put(`/pipeline-stages/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "pipelines", data: res }));
    dispatch(updateItem({ collection: "pipeline", data: res }));
    return res;
  });
});

const deleteStage = createAsyncThunk("pipeline/deleteStage", (data) => {
  return api.delete(`/pipeline-stages/${data.id}`).then((res) => {
    return res;
  });
});

const sortStages = createAsyncThunk("pipeline/sortStages", (data) => {
  return api.put("pipeline-stages/sort", data).then((res) => {
    return res;
  });
});

/**------------- LEADS ------------------- */

const fetchStagesLeads = createAsyncThunk("pipeline/fetchStagesLeads", async ({ stageId, page, filter }) => {
  const response = await api.get(`/pipelines/leads/${stageId}?page=${page}&my=${filter.my}`);
  return { stageId, leadsData: response, page };
});


export default {
  fetchPipelines,
  fetchPipeline,
  createPipeline,
  updatePipeline,
  createStage,
  updateStage,
  deleteStage,
  sortStages,
  fetchStagesLeads,
};
