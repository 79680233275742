import { memo, useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";

import { Stack, useMediaQuery } from "@mui/material";

import store from "store";
import { taskSelectors, taskThunks } from "store/ducks/task";

import Error from "views/common/Error";
import HeaderPage from "views/common/HeaderPage";
import Loader from "views/common/Loader";
import { PageWrapper } from "views/common/styledComponents";
import TasksColumn from "./TasksColumn";
import { DESKTOP_VIEW, MOBILE_VIEW } from "utils/constants";
import HeaderPageMobile from "views/common/HeaderPageMobile";

const TasksGrid = ({ pageView, togglePageView, createNewItemAction, tasksFilter, handleFilterChange }) => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const tasks = useSelector(taskSelectors.getActiveTasks());
  const tasksLoading = useSelector(taskSelectors.activeTasksLoading());

  const filterTasksByType = tasksFilter.type ? tasks.filter((task) => task.type?.id === tasksFilter?.type?.id) : tasks;

  const query = searchParams.toString();

  const today = DateTime.local().startOf("day");
  const toDoTodayList = filterTasksByType
    .filter((task) => {
      const dueDateTime = task.dueTime
        ? DateTime.fromISO(task.dueDate, { zone: task.timeZone }).set({
            hour: DateTime.fromISO(task.dueTime, { zone: task.timeZone }).hour,
            minute: DateTime.fromISO(task.dueTime, { zone: task.timeZone }).minute,
          })
        : DateTime.fromISO(task.dueDate, { zone: task.timeZone }).endOf("day");

      const nowInTaskTimeZone = DateTime.now().setZone(task.timeZone);
      return DateTime.fromISO(task.dueDate).equals(today) && dueDateTime >= nowInTaskTimeZone;
    })
    .sort((a, b) => {
      return (
        (a.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity) -
        (b.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity)
      );
    });

  const toDoTomorrowList = filterTasksByType
    .filter((task) => DateTime.fromISO(task.dueDate).equals(today.plus({ days: 1 })))
    .sort((a, b) => {
      return (
        (a.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity) -
        (b.dueDate ? DateTime.fromISO(b.dueDate).valueOf() : Infinity)
      );
    });

  const overdueList = filterTasksByType.filter((task) => {
    const dueDateTime = task.dueTime
      ? DateTime.fromISO(task.dueDate, { zone: task.timeZone }).set({
          hour: DateTime.fromISO(task.dueTime, { zone: task.timeZone }).hour,
          minute: DateTime.fromISO(task.dueTime, { zone: task.timeZone }).minute,
        })
      : DateTime.fromISO(task.dueDate, { zone: task.timeZone });

    // Convert the current time to the task's time zone
    const nowInTaskTimeZone = DateTime.now().setZone(task.timeZone);

    return (task.dueTime ? dueDateTime < nowInTaskTimeZone : dueDateTime < today) || !task.dueDate;
  });

  const sortedOverdueList = overdueList.sort((a, b) => {
    const dateA = a.dueTime
      ? DateTime.fromISO(a.dueDate, { zone: a.timeZone }).set({
          hour: DateTime.fromISO(a.dueTime, { zone: a.timeZone }).hour,
          minute: DateTime.fromISO(a.dueTime, { zone: a.timeZone }).minute,
        })
      : DateTime.fromISO(a.dueDate, { zone: a.timeZone });

    const dateB = b.dueTime
      ? DateTime.fromISO(b.dueDate, { zone: b.timeZone }).set({
          hour: DateTime.fromISO(b.dueTime, { zone: b.timeZone }).hour,
          minute: DateTime.fromISO(b.dueTime, { zone: b.timeZone }).minute,
        })
      : DateTime.fromISO(b.dueDate, { zone: b.timeZone });

    return dateA.valueOf() - dateB.valueOf();
  });

  const toDoFutureList = filterTasksByType
    .filter((task) => DateTime.fromISO(task.dueDate) > today.plus({ days: 1 }))
    .sort((a, b) => {
      return (
        (a.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity) -
        (b.dueDate ? DateTime.fromISO(b.dueDate).valueOf() : Infinity)
      );
    });

  const TASKS_COLUMNS = [
    { title: t("types.task.overdue"), tasksList: sortedOverdueList },
    { title: t("types.task.today"), tasksList: toDoTodayList },
    { title: t("types.task.tomorrow"), tasksList: toDoTomorrowList },
    { title: t("types.task.future"), tasksList: toDoFutureList },
  ];

  const scrollColumnContainerRef = useRef(null);
  const [currentColumnIndex, setCurrentColumnIndex] = useState(0);

  const handleScroll = () => {
    if (!scrollColumnContainerRef.current) return;

    const scrollLeft = scrollColumnContainerRef.current.scrollLeft;
    const columnWidth = scrollColumnContainerRef.current.scrollWidth / TASKS_COLUMNS.length;

    const newIndex = Math.round(scrollLeft / columnWidth);

    if (newIndex !== currentColumnIndex) {
      setCurrentColumnIndex(newIndex);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollColumnContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [TASKS_COLUMNS]);

  useEffect(() => {
    let params = {};

    if (tasksFilter?.byMe) {
      params.byMe = true;
    }
    if (tasksFilter) {
      params.my = tasksFilter?.my;
    }

    if (tasksFilter) {
      params.notMy = tasksFilter.notMy;
    }

    setSearchParams(params);
  }, [setSearchParams, tasksFilter]);

  useEffect(() => {
    query && store.dispatch(taskThunks.fetchActiveTasks(`?${query}`));
  }, [query]);

  return (
    <PageWrapper>
      {isDesktop ? (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPage
            title={t("types.task.namePlural")}
            pageView={pageView}
            togglePageView={togglePageView}
            createNewItemBtnTitle={"+ " + t("base.buttons.createTask")}
            createNewItemAction={createNewItemAction}
            tasksFilter={tasksFilter}
            handleFilterChange={handleFilterChange}
          />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPageMobile
            pageView={pageView}
            togglePageView={togglePageView}
            createNewItemAction={createNewItemAction}
            tasksFilter={tasksFilter}
            handleFilterChange={handleFilterChange}
            columnIndicator
            currentColumnIndex={currentColumnIndex}
            indicatorColumnsList={TASKS_COLUMNS}
          />
        </ErrorBoundary>
      )}

      {tasksLoading ? (
        <Loader />
      ) : (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <Stack
            ref={scrollColumnContainerRef}
            direction="row"
            height={"100%"}
            gap={2}
            width="100%"
            sx={{
              height: { sm: "calc(100% - 118px)", md: "calc(100% - 92px)", lg: "calc(100% - 76px)" },
              ...(isMobile && {
                overflowX: "scroll",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }),
            }}
          >
            {TASKS_COLUMNS.map((col) => (
              <TasksColumn key={col.title} column={col} list={col.tasksList} />
            ))}
          </Stack>
        </ErrorBoundary>
      )}
    </PageWrapper>
  );
};

export default memo(TasksGrid);
