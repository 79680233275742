import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";

import store from "store";
import { tableSelectors } from "store/ducks/table";
import { addSelected, addSearchPhrase } from "store/ducks/table/slice";

/** --- DataGrid component with handling search, pagination and sorting --- */

const DataTable = ({
  list,
  isLoading,
  totalElements,
  columns,
  columnVisibilityModel,
  totalPages,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  setSearchParams,
  hideFooter,
  filterByResponsible,
  filterByAuthor,
  filterNotMyTasks,
  autoPageSize,
}) => {
  /** --- search phrase for exchanging between SearchBar and instances pages */
  const searchPhrase = useSelector(tableSelectors.getSearchPhrase());

  /** --- state for handling Selected items */
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  /** --- State for handling Pagination Model--- */
  const [paginationModel, setPaginationModel] = useState({
    page: pageQuery ? pageQuery : 0,
    pageSize: pageSizeQuery ? pageSizeQuery : 5,
  });

  /** --- State for handling Sort Model--- */
  const [sortModel, setSortModel] = useState({
    field: sortByQuery ? sortByQuery : "",
    sort: sortDirectionQuery,
  });

  /** ---  Handling Sort Model ---*/
  const handleSortModelChange = useCallback((sortModel) => {
    setSortModel(
      /** -- check if the sorting is applied or reset ---*/
      sortModel.length !== 0 ? { field: sortModel[0].field, sort: sortModel[0].sort } : { field: "", sort: "" }
    );
  }, []);
  /** --- Handling selected rows --- */
  const handleGetSelectedRows = useCallback(() => {
    const selectedData = rowSelectionModel.map((selectedId) => {
      const selectedRow = list.find((row) => row.id === selectedId);
      return selectedRow;
    });
    store.dispatch(addSelected(selectedData));
  }, [list, rowSelectionModel]);

  /** --- Subscribe for selected rows changes --- */
  useEffect(() => {
    handleGetSelectedRows();
  }, [rowSelectionModel, handleGetSelectedRows]);

  /** --- Changing params for url depending on queries --- */
  useEffect(() => {
    let params = {};

    if (totalPages) {
      params.page = paginationModel.page;
      params.size = paginationModel.pageSize;
    }

    if (sortModel.field !== "") {
      params.sortBy = sortModel.field;
      params.direction = sortModel.sort;
    }

    if (filterByAuthor) {
      params.byMe = true;
    }
    if (filterByResponsible) {
      params.my = true;
    }
    if (filterNotMyTasks) {
      params.notMy = true;
    }

    params.q = searchPhrase;

    if (totalPages >= 0 && setSearchParams) {
      setSearchParams(params);
    }
  }, [
    setSearchParams,
    paginationModel,
    totalPages,
    sortModel,
    searchPhrase,
    sortByQuery,
    sortDirectionQuery,
    filterByAuthor,
    filterByResponsible,
    filterNotMyTasks,
  ]);

  /**  --- Dispatching searchPhrase to state --- */
  useEffect(() => {
    store.dispatch(addSearchPhrase(searchQuery));
  }, [searchQuery]);

  return (
    <DataGrid
      rows={list}
      rowCount={totalElements}
      columns={columns}
      loading={isLoading}
      slots={{
        loadingOverlay: LinearProgress,
      }}
      initialState={{
        columns: {
          columnVisibilityModel: columnVisibilityModel,
        },
      }}
      pagination
      paginationMode="server"
      pageSizeOptions={[5, 15, 50, 100]}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd")}
      checkboxSelection
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      hideFooter={hideFooter}
      autoPageSize={autoPageSize}
    />
  );
};

export default memo(DataTable);
