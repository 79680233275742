import { memo } from "react";
import { Box, Button, CircularProgress, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const ModalActions = ({ onClose, submitForm, formId, submitTitle = "Save", saveDisabled = false }) => {
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      flexDirection={mobileView ? "column" : "row"}
      flexWrap="wrap"
      width="100%"
      gap={2}
    >
      <Button sx={{ minWidth: "150px" }} fullWidth={mobileView} onClick={onClose}>
        {t("base.buttons.cancel")}
      </Button>
      <Button
        variant="contained"
        sx={{ minWidth: "150px" }}
        type={formId ? "submit" : "button"}
        form={formId}
        fullWidth={mobileView}
        onClick={submitForm}
        disabled={saveDisabled}
        startIcon={saveDisabled ? <CircularProgress size={16} sx={{ mr: 1, color: "extra.white" }} /> : null}
      >
        {submitTitle}
      </Button>
    </Box>
  );
};

export default memo(ModalActions);
