import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { AssignmentOutlined, CommentOutlined } from "@mui/icons-material";

import { INTERACTION_ACTIONS_MENU } from "utils/constants";

const InteractionActionSelector = ({ selectedItem, setSelectedItem }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t("base.tooltips.actionsMenu")}>
        <IconButton
          id="action"
          aria-controls={open ? "action-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickOpen}
          sx={{ p: 0.5 }}
        >
          {selectedItem === "comment" ? (
            <CommentOutlined sx={{ color: "primary.main" }} />
          ) : (
            <AssignmentOutlined sx={{ color: "primary.main" }} />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "action",
          role: "listbox",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            px: 1,

            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              bottom: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {INTERACTION_ACTIONS_MENU.map((item) => {
          const itemColor = selectedItem === item.key ? "extra.white" : "primary.main";
          const itemBgColor = selectedItem === item.key ? "primary.main" : "transparent";
          const itemHoverBgColor = selectedItem === item.key ? "rgba(77, 84, 201, 0.8)" : "rgba(0, 0, 0, 0.1)";

          return (
            <MenuItem
              key={item.key}
              sx={{
                px: 1,
                color: itemColor,
                backgroundColor: itemBgColor,
                gap: 1,
                borderRadius: 1,
                transition: "color, 0.3s",
                "&:hover": {
                  backgroundColor: itemHoverBgColor,
                  color: itemColor,
                },
              }}
              onClick={() => setSelectedItem(item.key)}
            >
              {item.icon}
              <Typography>{t(`types.lead.interactionActionsMenu.${item.key}`)}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default memo(InteractionActionSelector);
