import { memo } from "react";

import { Box, IconButton, Tooltip } from "@mui/material";
import { RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const AddRemoveInputAdornment = ({ name, remove }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Tooltip title={t("base.tooltips.removeField") + " " + name }>
        <IconButton sx={{ p: 0.25, color: "primary.main" }} onClick={remove}>
          <RemoveCircleOutlineOutlined />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default memo(AddRemoveInputAdornment);
