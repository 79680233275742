import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api";

const fetchRecord = createAsyncThunk("binotel/fetchRecord", (id) => {
  return api.get(`/binotel/call-record/${id}`);
});

export default {
  fetchRecord,
};
