import { Fragment, memo, useEffect } from "react";
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";

import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import store from "store";
import { taskSelectors, taskThunks } from "store/ducks/task";

import Error from "views/common/Error";
import HeaderPage from "views/common/HeaderPage";
import DataTable from "views/common/DataTable";

import { PageWrapper } from "views/common/styledComponents";

import filters from "filters";
import { getLastNameAndNameLetter } from "utils/helpers";
import { DESKTOP_VIEW, TASK_STATUS_COLOR } from "utils/constants";
import HeaderPageMobile from "views/common/HeaderPageMobile";

const TasksList = ({ pageView, togglePageView, createNewItemAction, tasksFilter, handleFilterChange }) => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const { t } = useTranslation();
  const theme = useTheme();

  const tasks = useSelector(taskSelectors.getTasks());
  const tasksLoading = useSelector(taskSelectors.tasksLoading());

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get("page") || 0);
  const pageSizeQuery = parseInt(searchParams.get("size") || 15);
  const searchQuery = searchParams.get("q") || "";
  const sortByQuery = searchParams.get("sortBy") || "";
  const sortDirectionQuery = searchParams.get("direction") || "";

  useEffect(() => {
    query && store.dispatch(taskThunks.fetchTasks(`?${query}`));
  }, [query]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      filterable: false,
      minWidth: 80,
      flex: 0.3,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/tasks/${id}`}
            style={{ textDecoration: "none", color: theme.palette.text.dark, width: "100%", cursor: "pointer" }}
          >
            {id}
          </Link>
        );
      },
    },
    {
      field: "name",
      headerName: t("base.dictionary.name"),
      filterable: false,
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: "responsible",
      headerName: t("types.task.responsible"),
      filterable: false,
      renderCell: ({ row: { responsible } }) => (
        <Stack direction="row" spacing={0.5} flexWrap="nowrap">
          {responsible.map((option) => {
            return (
              <Fragment key={option.id}>
                <Typography>{getLastNameAndNameLetter(option)}</Typography>
              </Fragment>
            );
          })}
        </Stack>
      ),
      minWidth: 160,
      flex: 0.25,
    },
    {
      field: "status",
      headerName: t("base.labels.status"),
      filterable: false,
      renderCell: ({ row: { status } }) => {
        const statusColor = TASK_STATUS_COLOR(theme)[status.name] || "text.main";
        return <Typography color={statusColor}>{status.label}</Typography>;
      },
      minWidth: 120,
      flex: 0.3,
    },
    {
      field: "dueDate",
      headerName: t("base.labels.dueDate"),
      filterable: false,
      renderCell: ({ row: { dueDate } }) => {
        const shortDate = filters.date(dueDate);
        return <Typography>{shortDate}</Typography>;
      },
      minWidth: 120,
      flex: 0.3,
    },
    {
      field: "createdAt",
      headerName: t("types.task.dateOfTask"),
      filterable: false,
      renderCell: ({ row: { createdAt } }) => {
        const formattedDate = filters.date(createdAt, "dd.MM.yyyy");
        return <Typography>{formattedDate}</Typography>;
      },
      minWidth: 120,
      flex: 0.3,
    },
  ];

  return (
    <PageWrapper>
      {isDesktop ? (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPage
            title={t("types.task.namePlural")}
            isSearchbarShow
            pageView={pageView}
            togglePageView={togglePageView}
            createNewItemBtnTitle={"+ " + t("base.buttons.createTask")}
            createNewItemAction={createNewItemAction}
            tasksFilter={tasksFilter}
            handleFilterChange={handleFilterChange}
          />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPageMobile
            isSearchbarShow
            pageView={pageView}
            togglePageView={togglePageView}
            createNewItemAction={createNewItemAction}
            tasksFilter={tasksFilter}
            handleFilterChange={handleFilterChange}
          />
        </ErrorBoundary>
      )}
      <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
        <Box
          height={isDesktop ? "calc(100% - 76px)" : "calc(100% - 106px)"}
          sx={{ backgroundColor: "extra.white", p: 2, borderRadius: 2 }}
        >
          <DataTable
            list={tasks.content}
            totalElements={tasks.totalElements}
            columns={columns}
            isLoading={tasksLoading}
            totalPages={tasks.totalPages}
            setSearchParams={setSearchParams}
            pageQuery={pageQuery}
            pageSizeQuery={pageSizeQuery}
            searchQuery={searchQuery}
            sortByQuery={sortByQuery}
            sortDirectionQuery={sortDirectionQuery}
            filterByAuthor={tasksFilter.byMe}
            filterByResponsible={tasksFilter.my}
            filterNotMyTasks={tasksFilter.notMy}
          />
        </Box>
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default memo(TasksList);
