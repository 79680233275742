import { memo } from "react";
import { Drawer } from "@mui/material";

const DrawerMobile = ({ children, onClose, open }) => {
  return (
    <Drawer
      anchor="top"
      open={open}
      onClose={onClose}
      sx={{ top: "56px" }}
      PaperProps={{
        style: {
          top: "56px",
          maxWidth: "100%",
          borderRadius: 0,
          padding: 0,
          borderBottomLeftRadius: 16,
          borderBottomRightRadius: 16,
          boxShadow: "none",
        },
      }}
      slotProps={{
        backdrop: {
          style: { top: 56 },
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default memo(DrawerMobile);
