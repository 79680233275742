import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { AsYouType } from "libphonenumber-js";
import { DateTime } from "luxon";

import { Button as MuiButton, Divider, IconButton, Stack, Typography, Tooltip, useTheme, Avatar } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";

import { InProgressIcon } from "theme/icons";

import store from "store";
import { pipelineThunks } from "store/ducks/pipeline";

import filters from "filters";
import QuickAddInput from "views/common/QuickAddInput";

import useQuickAddInput from "hooks/useQuickAddInput";
import MenuLeadCard from "./MenuLeadCard";
import { defineTaskStatus, getLastNameAndFirstNameAndSecondNameLetter } from "utils/helpers";
import { TASK_PERFORMANCE_DEADLINE_COLOR } from "utils/constants";

export const Button = styled(MuiButton)({
  border: "none",
  fontSize: 14,
  minHeight: "fit-content",
  minWidth: "fit-content",
  "&:hover": { border: "none" },
});

const LeadCard = ({ lead }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const lastComment = lead.interactions?.at(-1)?.comment || null;

  const taskStatus = defineTaskStatus(lead?.lastTaskDueDate);
  const statusColor = TASK_PERFORMANCE_DEADLINE_COLOR(theme)[taskStatus]?.color;
  const statusTooltip = TASK_PERFORMANCE_DEADLINE_COLOR(theme)[taskStatus]?.key;

  const formattedPhoneNumber = lead.contact?.phones[0] ? new AsYouType().input(lead.contact?.phones[0]) : "-";

  const dateTimeString = `${lead.lastTaskDueDate}T${lead.lastTaskDueTime || ""}`;
  const taskDateTime = lead.lastTaskDueTime
    ? DateTime.fromISO(dateTimeString, { zone: lead.lastTaskTimezone }).setZone(DateTime.local().zoneName)
    : DateTime.fromISO(lead.lastTaskDueDate, { zone: lead.lastTaskTimezone }).setZone(DateTime.local().zoneName);

  const formattedDateTime = taskDateTime.toFormat(`dd.MM.yyyy${lead.lastTaskDueTime ? " / HH:mm" : " / --:--"}`);

  const [editMode, newValue, setNewValue, enableEditMode, disableEditMode, isLoading, setIsLoading] =
    useQuickAddInput();

  const handleEditClick = () => {
    enableEditMode("");
  };

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: lead.id,
    data: {
      type: "Lead",
      lead,
    },
    editMode,
    disabled: editMode,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleAddNewComment = async () => {
    setIsLoading(true);

    const response = await store.dispatch(
      pipelineThunks.addComment({
        id: lead.id,
        comment: newValue,
      })
    );

    if (!response.error) {
      setIsLoading(false);
      toast.success(t("messages.success.toast.addComment"));
    }
  };

  if (isDragging) {
    return (
      <Stack
        ref={setNodeRef}
        style={style}
        sx={{ backgroundColor: "extra.white", border: "1px solid", borderColor: "primary.light" }}
        p={1}
        borderRadius={1}
        height="105px"
        minHeight="105px"
      />
    );
  }

  return (
    <Stack
      sx={{ backgroundColor: "extra.white" }}
      p={1}
      borderRadius={1}
      gap={1}
      flex={1}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Stack
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ cursor: "grab" }}
      >
        <Typography
          variant="body2"
          color="text.main"
          fontWeight={600}
          component={Link}
          to={`/leads/${lead.id}`}
          sx={{
            textDecoration: "none",
            "&:hover": { borderBottom: "1px solid", color: "text.dark" },
          }}
        >
          {getLastNameAndFirstNameAndSecondNameLetter(lead)}
        </Typography>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Avatar sx={{ width: 24, height: 24, backgroundColor: "primary.main" }} src={lead.responsible?.avatar}>
            {lead.responsible && (
              <Typography fontSize={12} fontWeight={500} color="extra.white">
                {lead.responsible?.contact.name?.charAt(0) + lead.responsible?.contact?.lastName?.charAt(0)}
              </Typography>
            )}
          </Avatar>

          <MenuLeadCard lead={lead} />
        </Stack>
      </Stack>
      <Stack>
        {editMode ? (
          <QuickAddInput
            newValue={newValue}
            setNewValue={setNewValue}
            disableEditMode={disableEditMode}
            handleActionSubmit={handleAddNewComment}
            placeholder={t("base.placeholders.comment")}
            isLoading={isLoading}
            size="mediumSize"
          />
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="space-between" position="relative">
            {lastComment ? (
              <Typography variant="body2" color="primary.main" mr={3} sx={{ overflowX: "hidden", wordWrap: "no-wrap" }}>
                {lastComment}
              </Typography>
            ) : (
              <Typography variant="body2" color="text.light">
                {t("messages.warning.noComments")}
              </Typography>
            )}
            {isHovered && (
              <Tooltip title={t("base.tooltips.addComment")}>
                <IconButton
                  onClick={handleEditClick}
                  sx={{
                    p: 0,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: "primary.lighter",
                    transition: "0.3s",
                    "&:hover": { color: "primary.main" },
                  }}
                >
                  <EditOutlinedIcon sx={{ width: 20, height: 20 }} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        )}
        <Divider sx={{ borderColor: "background.default", mt: 1 }} />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <PhoneOutlinedIcon
            fontSize="small"
            sx={{
              color: "primary.lighter",
              // transition: "0.3s",
              //TODO: temporary hidden until action will be added
              //"&:hover": { color: "primary.main" }
            }}
          />
          <Typography variant="capture" color="text.main">
            {formattedPhoneNumber}
          </Typography>
        </Stack>
        {lead?.lastTaskDueDate && (
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="capture" color="text.main">
              {formattedDateTime}
            </Typography>
            <Tooltip title={t(`base.tooltips.${statusTooltip}`)}>
              <InProgressIcon fill={statusColor} />
            </Tooltip>
          </Stack>
        )}
      </Stack>
      <Divider
        sx={{
          borderColor: "background.default",
        }}
      />
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="capture" color="text.light">
            {filters.date(lead.createdAt, "dd.MM.yyyy / HH:mm")}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(LeadCard);
