import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";

import { PageWrapper } from "views/common/styledComponents";
import { DICTIONARIES_LIST } from "utils/constants";
import LocationDictionary from "./components/locationDictionary/LocationDictionary";
import SingleDictionary from "./components/SingleDictionary";

const DictionariesPage = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);
  const hasSubList = selected?.subList;

  //TODO: ADAPTIVE. DESIGN IS NEEDED

  const handleChange = (newValue) => {
    setSelected(newValue);
  };

  useEffect(() => {
    setSelected(DICTIONARIES_LIST[0]);
  }, []);

  return (
    <PageWrapper sx={{ height: "calc(100dvh - 79px)" }}>
      <Stack direction="row" spacing={2} sx={{ height: "100%", mt: 2, width: "100%" }}>
        <Stack
          sx={{
            backgroundColor: "extra.white",
            borderRadius: 2,
            width: hasSubList ? "33.33%" : "50%",
            px: 2,
            py: 1,
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="center" minHeight="36px">
            <Typography textAlign="center" variant="h6" color="primary.main">
              {t("types.settings.dictionaries.namePlural")}
            </Typography>
          </Stack>
          <Divider sx={{ borderColor: "secondary.light" }} />
          <List sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2, overflowY: 'auto' }}>
            {DICTIONARIES_LIST.map((dictionary) => {
              const menuItemColor = dictionary.name === selected?.name ? "extra.white" : "text.dark";
              const menuItemBgColor = dictionary.name === selected?.name ? "primary.main" : "transparent";
              return (
                <ListItem key={dictionary.name} disableGutters sx={{ p: 0 }}>
                  <ListItemButton
                    sx={{
                      py: 1,
                      px: 2,
                      backgroundColor: menuItemBgColor,
                      borderRadius: 1,
                      border: "1px solid",
                      borderColor: "primary.lighter",
                      justifyContent: "center",
                      transition: "all 0.3s",
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: "primary.light",
                        boxShadow: "0px 0px 4px 0px #4D54C91A",
                        "& .MuiListItemText-root": { color: "primary.main" },
                      },
                    }}
                    onClick={() => handleChange(dictionary)}
                    disableGutters
                  >
                    <ListItemText
                      primary={dictionary.label}
                      sx={{
                        color: menuItemColor,
                        "&>.MuiTypography-root": {
                          fontSize: 16,
                          fontWeight: 500,
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Stack>
        {!hasSubList && selected && (
          <Stack
            sx={{
              backgroundColor: "extra.white",
              borderRadius: 2,
              width: hasSubList ? "33.33%" : "50%",
              py: 1,
              px: 2,
            }}
          >
            <SingleDictionary selectedDictionary={selected} />
          </Stack>
        )}
        {hasSubList && selected && <LocationDictionary selectedDictionary={selected} />}
      </Stack>
    </PageWrapper>
  );
};

export default memo(DictionariesPage);
