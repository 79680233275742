import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api";
import { addItem, replaceItem, updateItem } from "./slice";

/**------------- LEADS ------------------- */
const fetchLeads = createAsyncThunk("lead/fetchLeads", (query) => {
  return api.get(`/leads${query}`);
});

const fetchLead = createAsyncThunk("lead/fetchLead", (id) => {
  return api.get(`/leads/${id}`);
});

const createLead = createAsyncThunk("lead/createLead", (data, { dispatch }) => {
  return api.post("/leads", data).then((res) => {
    dispatch(addItem({ collection: "leads", data: res }));
    return res;
  });
});

const updateLead = createAsyncThunk("lead/updateLead", (data, { dispatch }) => {
  return api.put(`/leads/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "leads", data: res }));
    dispatch(updateItem({ collection: "lead", data: res }));
    return res;
  });
});

const changeStage = createAsyncThunk("lead/changeStage", (data) => {
  return api.put(`/leads/${data.id}/stage`, data.stage).then((res) => {
    return res;
  });
});

const closeLead = createAsyncThunk("lead/closeLead", (data) => {
  return api.put(`/leads/${data.id}/status`, data).then((res) => {
    return res;
  });
});

const addComment = createAsyncThunk("lead/createComment", (data) => {
  return api.post(`/leads/${data.id}/comment`, data.comment, { "Content-Type": "text/plain" }).then((res) => {
    return res;
  });
});

const archiveLeads = createAsyncThunk("lead/archiveLeads", (data) => {
  return api.put(`/leads/archive/${data.id}`, data.closeReason).then((res) => {
    return res;
  });
});

const filteredLeads = createAsyncThunk("lead/filteredLeads", (data, { dispatch }) => {
  return api.post(`/leads/filtered${data.query}`, data.body).then((res) => {
    dispatch(updateItem({ collection: "leads", data: res }));
    return res;
  });
});

const fetchContactLeads = createAsyncThunk("lead/fetchContactLeads", (id) => {
  return api.get(`/leads/contacts/${id}`);
});

const updateComment = createAsyncThunk("lead/updateComment", (data) => {
  return api.put(`/lead-interactions/${data.commentId}`, data.body).then((res) => {
    return res;
  });
});

/** -------- BULK ACTIONS --------  */

const updateBulkLeadsStage = createAsyncThunk("lead/updateBulkLeadsStage", (data) => {
  return api.post(`/leads/batch/stage`, data).then((res) => {
    return res;
  });
});

const updateBulkLeadsStatus = createAsyncThunk("lead/updateBulkLeadsStatus", (data) => {
  return api.post(`/leads/batch/status`, data).then((res) => {
    return res;
  });
});

const addBulkLeadsTask = createAsyncThunk("lead/addBulkLeadsTask", (data) => {
  return api.post(`/leads/batch/task`, data).then((res) => {
    return res;
  });
});

export default {
  fetchLeads,
  fetchLead,
  createLead,
  updateLead,
  changeStage,
  closeLead,
  addComment,
  archiveLeads,
  filteredLeads,
  fetchContactLeads,
  updateComment,
  updateBulkLeadsStage,
  updateBulkLeadsStatus,
  addBulkLeadsTask,
};
