import { memo, useState } from "react";
import { useSelector } from "react-redux";

import { Stack } from "@mui/material";

import DictionaryItemsList from "../DictionaryItemsList";

const LocationDictionary = ({ selectedDictionary }) => {
  const countryDictionary = selectedDictionary.subList[0];
  const cityDictionary = selectedDictionary.subList[1];

  const [selectedCountry, setSelectedCountry] = useState(null);

  const countriesList = useSelector(countryDictionary.selector);
  const citiesList = useSelector(cityDictionary.selector);

  const countryCityList = citiesList.filter((city) => city?.country?.id === selectedCountry?.id);

  return (
    <>
      <Stack
        sx={{
          backgroundColor: "extra.white",
          borderRadius: 2,
          width: "33.33%",
          py: 1,
          px: 2,
        }}
      >
        <DictionaryItemsList
          selectedDictionary={countryDictionary}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          showItemButton
          list={countriesList}
        />
      </Stack>
      <Stack
        sx={{
          backgroundColor: "extra.white",
          borderRadius: 2,
          width: "33.33%",
          py: 1,
          px: 2,
        }}
      >
        {selectedCountry && (
          <DictionaryItemsList
            selectedDictionary={cityDictionary}
            list={countryCityList}
            selectedCountry={selectedCountry}
          />
        )}
      </Stack>
    </>
  );
};

export default memo(LocationDictionary);
