import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import { useSelector } from "react-redux";
import { dictionarySelectors } from "store/ducks/dictionary";
import CustomAutocomplete from "views/filter/CustomAutocomplete";
import { CloseOutlined } from "@mui/icons-material";

const TasksFilter = ({ filter, handleFilter, pageView }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const taskTypes = useSelector(dictionarySelectors.getTaskTypes());

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t("base.tooltips.taskFilter")}>
        <IconButton
          id="tasks-filter-button"
          aria-controls={open ? "tasks-filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickOpen}
        >
          {Object.values(filter).some((item) => item === true) ? (
            <FilterAltOffOutlinedIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          ) : (
            <FilterAltOutlinedIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="tasks-filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "filter-tasks",
          role: "listbox",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Stack direction="row" px={2} justifyContent="space-between" alignItems="center" mb={1}>
          <Typography color="text.main" fontWeight={600}>
            {t("types.task.tasksFilter")}
          </Typography>
          <IconButton sx={{ color: "primary.main", p: 0 }} onClick={handleClose}>
            <CloseOutlined fontSize="small" />
          </IconButton>
        </Stack>
        <Divider px={2} />
        <Stack padding={2} gap={1} alignItems="center" justifyContent="flex-start" minWidth={280}>
          <FormGroup sx={{ width: "100%" }}>
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" width="100%">
              <FormControlLabel
                control={
                  <Checkbox sx={{ color: "primary.main" }} checked={filter.my} onChange={handleFilter} name="my" />
                }
                label={t("types.task.myTasks")}
              />
              <FormControlLabel
                control={
                  <Checkbox sx={{ color: "primary.main" }} checked={filter.byMe} onChange={handleFilter} name="byMe" />
                }
                label={t("types.task.createdByMe")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: "primary.main" }}
                    checked={filter.notMy}
                    onChange={handleFilter}
                    name="notMy"
                  />
                }
                label={t("types.task.notMy")}
              />
            </Stack>
          </FormGroup>
          {pageView === "col" && (
            <CustomAutocomplete
              value={filter.type}
              options={taskTypes}
              label={t("base.labels.type")}
              onChange={(value) => handleFilter(value, "type")}
            />
          )}
        </Stack>
      </Menu>
    </>
  );
};

export default memo(TasksFilter);
