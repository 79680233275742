import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Popover,
  Radio,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import store from "store";
import { keyCallThunks } from "store/ducks/keyCall";
import { tableSelectors } from "store/ducks/table";
import { leadThunks } from "store/ducks/lead";

import Modal from "views/common/modal/Modal";
import { AttentionIcon, SendIcon } from "theme/icons";
import useModal from "hooks/useModal";
import { CloseOutlined } from "@mui/icons-material";
import { MOBILE_VIEW } from "utils/constants";

const KeyCall = ({ query, isAllLeadsFilterApplied, appliedAllLeadsFilter }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [modalOpen, openModal, closeModal] = useModal();

  const isMobile = useMediaQuery(MOBILE_VIEW);

  const selectedLeads = useSelector(tableSelectors.getSelected());
  const leadIds = selectedLeads.length > 0 && selectedLeads?.map((lead) => lead?.id);

  const [onlyInfo, setOnlyInfo] = useState(true);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApplyAndClose = async () => {
    const response = await store.dispatch(
      keyCallThunks.uploadLeadsByIds({ query: `?onlyInfo=${onlyInfo}`, body: leadIds })
    );

    if (!response.error) {
      toast.success(t("messages.success.toast.keyCall"));
      setAnchorEl(null);
      closeModal();

      if (!onlyInfo) {
        if (isAllLeadsFilterApplied) {
          store.dispatch(leadThunks.filteredLeads({ query: `?${query}`, body: appliedAllLeadsFilter }));
        } else query && store.dispatch(leadThunks.fetchLeads(`?${query}`));
      }
    }
  };

  const handleChange = (event) => {
    setOnlyInfo(event.target.value === "true");
  };

  return (
    <>
      <Tooltip title={t("base.tooltips.keyCall")}>
        <IconButton
          id="keyCall-button"
          aria-controls={open ? "keyCall-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickOpen}
        >
          <SendIcon fill={theme.palette.primary.main} />
        </IconButton>
      </Tooltip>
      <Popover
        id="keyCall-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          width: "100%",
        }}
        MenuListProps={{
          "aria-labelledby": "keyCall",
          role: "listbox",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            ...(isMobile && { width: "100%" }),
            ...(!isMobile && {
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 10,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            }),
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Stack direction="row" px={2} pt={1} justifyContent="space-between" alignItems="center" mb={1}>
          <Typography color="text.main" fontWeight={600}>
            {t("types.lead.bulkActions.keyCallTitle")}
          </Typography>
          <IconButton sx={{ color: "primary.main", p: 0 }} onClick={handleClose}>
            <CloseOutlined fontSize="small" />
          </IconButton>
        </Stack>
        <Divider sx={{ borderColor: "background.default", mt: 1, mx: 2 }} />
        <Stack p={2} pt={1} width="100%" minWidth={300}>
          <MenuItem onClick={() => setOnlyInfo(true)} sx={{ p: 0 }}>
            <FormControlLabel
              control={<Radio checked={onlyInfo === true} onChange={handleChange} value="true" />}
              label={t("Send for notification")}
            />
          </MenuItem>
          <MenuItem onClick={() => setOnlyInfo(false)} sx={{ p: 0 }}>
            <FormControlLabel
              control={<Radio checked={onlyInfo === false} onChange={handleChange} value="false" />}
              label={t("Send for call")}
            />
          </MenuItem>
          <Divider sx={{ width: "100%", my: 1 }} />
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
            <Button onClick={handleClose} sx={{ width: "100%" }}>
              {t("base.buttons.cancel")}
            </Button>
            <Button onClick={openModal} sx={{ width: "100%" }} variant="contained">
              {t("base.buttons.apply")}
            </Button>
          </Stack>
        </Stack>
      </Popover>
      <Modal
        title={`${t("types.lead.bulkActions.keyCallTitle")}`}
        open={modalOpen}
        onClose={closeModal}
        icon={<AttentionIcon />}
        actions={
          <>
            <Button sx={{ px: 8 }} onClick={closeModal}>
              {t("base.buttons.cancel")}
            </Button>
            <Button variant="contained" sx={{ px: 8 }} type="button" onClick={handleApplyAndClose}>
              {t("base.buttons.apply")}
            </Button>
          </>
        }
      >
        <Typography textAlign="center" color="text.main" fontWeight={600}>
          {onlyInfo ? t("types.lead.bulkActions.keyCallMessageInfo") : t("types.lead.bulkActions.keyCallMessageCall")}{" "}
          {selectedLeads.length} {t("types.lead.namePlural").toLowerCase()}?
        </Typography>
      </Modal>
    </>
  );
};

export default memo(KeyCall);
