import { memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, CircularProgress, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import ConfirmModal from "./modal/ConfirmModal";
import { QuestionIcon } from "theme/icons";
import ArchivePipelineLeadsModal from "views/pages/pipelines/components/ArchivePipelineLeadsModal";
import { DESKTOP_VIEW } from "utils/constants";
import { ChevronLeftOutlined } from "@mui/icons-material";

/** --- PROPS DESCRIPTION ---
 * title -  title of the page
 * data - data of entity's item
 * formId - form id
 * isSubmitting - prop from formik bag
 * modalOpen, closeModal, openModal - comes from useModal hook
 * isFormChanged - dirty properties from formik, define if the values were changed
 * isChangedToArchive - condition if pipeline status changed to "Archived"
 * handleArchivePipeline - function that update only pipeline
 * handleArchivePipelineAndAllLeads - function that update pipeline and does archive of all its leads
 * ---------------------------- */

const HeaderForm = ({
  title,
  data,
  formId,
  modalOpen,
  closeModal,
  openModal,
  isSubmitting,
  isFormChanged,

  isChangedToArchive,
  handleArchivePipeline,
  handleArchivePipelineAndAllLeads,
  //TODO: hasPermission,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const goBack = () => navigate(-1);
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      px={2}
      sx={{
        borderBottomLeftRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        ...(isDesktop && { backgroundColor: "extra.white" }),
      }}
    >
      {isDesktop && (
        <Stack direction="row" alignItems="center" gap={2}>
          <Button variant="contained" startIcon={<ArrowBackIosNewOutlinedIcon />} LinkComponent={Link} onClick={goBack}>
            {t("base.buttons.back")}
          </Button>

          <Typography variant="h4" color="text.main">
            {title}
          </Typography>
        </Stack>
      )}
      {!isDesktop && (
        <>
          <IconButton LinkComponent={Link} onClick={goBack} sx={{ p: 0, color: "primary.main" }}>
            <ChevronLeftOutlined fontSize="large" />
          </IconButton>
          <Typography variant={isDesktop ? "h4" : "h6"} color="text.main" sx={{ textAlign: "center" }}>
            {title}
          </Typography>
        </>
      )}

      <Stack direction="row" alignItems="center" gap={2}>
        {/* --- Check if we create new item or edit current one --- */}
        {data && isFormChanged && (
          //TODO: && hasPermission
          <>
            <Button
              variant={isDesktop ? "contained" : "text"}
              sx={{
                justifyContent: isDesktop ? "center" : "right",
                fontWeight: isDesktop ? 400 : 700,
                ...(!isDesktop && { minWidth: 0 }),
              }}
              onClick={() => openModal()}
            >
              {t("base.buttons.update")}
            </Button>

            {isChangedToArchive ? (
              <ArchivePipelineLeadsModal
                modalOpen={modalOpen}
                closeModal={closeModal}
                handleArchivePipeline={handleArchivePipeline}
                handleArchivePipelineAndAllLeads={handleArchivePipelineAndAllLeads}
                isSubmitting={isSubmitting}
              />
            ) : (
              <ConfirmModal
                title={`${t("base.dictionary.save")} ${t("base.dictionary.changes").toLowerCase()}`}
                open={modalOpen}
                onClose={closeModal}
                formId={formId}
                saveDisabled={isSubmitting}
                confirmTitle={isSubmitting ? t("base.dictionary.saving") : t("base.dictionary.save")}
                icon={<QuestionIcon />}
              />
            )}
          </>
        )}
        {!data && (
          //TODO: && hasPermission
          <Button
            startIcon={isSubmitting ? <CircularProgress size={16} sx={{ mr: 1, color: "extra.white" }} /> : null}
            variant={isDesktop ? "contained" : "text"}
            type="submit"
            disabled={isSubmitting}
            form={formId}
            sx={{
              justifyContent: isDesktop ? "center" : "right",
              fontWeight: isDesktop ? 400 : 700,
              ...(!isDesktop && { minWidth: 0 }),
            }}
          >
            {isSubmitting ? t("base.buttons.creating") : t("base.buttons.create")}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default memo(HeaderForm);
