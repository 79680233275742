import { memo } from "react";
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";

import Error from "views/common/Error";
import NewLeadForm from "./components/NewLeadForm";
import { useNavigate } from "react-router-dom";

const NewLeadPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
      <NewLeadForm t={t} navigate={navigate}/>
    </ErrorBoundary>
  );
};

export default memo(NewLeadPage);
