import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api";

const uploadLeadsByIds = createAsyncThunk("keyCall/uploadLeadsByIds", (data) => {
  return api.post(`/keycall/upload/ids${data.query}`, data.body).then((res) => {
    return res;
  });
});

export default {
  uploadLeadsByIds,
};
