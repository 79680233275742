import { createTheme, darken, lighten } from "@mui/material/styles";

import { outlinedInputClasses } from "@mui/material/OutlinedInput";

export const theme = (colors, logo, logoShort) =>
  createTheme({
    palette: {
      primary: {
        main: colors.primaryBaseCrm,
        lightest: lighten(colors.primaryBaseCrm, 0.95),
        lighter: lighten(colors.primaryBaseCrm, 0.85),
        light: lighten(colors.primaryBaseCrm, 0.45),
        contrastText: colors.white,
      },
      secondary: {
        main: colors.secondaryBaseCrm,
        lightest: lighten(colors.secondaryBaseCrm, 0.95),
        lighter: lighten(colors.secondaryBaseCrm, 0.9),
        light: lighten(colors.secondaryBaseCrm, 0.85),
        contrastText: colors.grey,
      },
      info: {
        main: colors.infoBase,
      },
      warning: {
        main: colors.warningBase,
      },
      success: {
        main: colors.successBase,
        light: lighten(colors.successBase, 0.7),
      },
      extra: {
        changed: colors.changed,
        white: colors.white,
        grey: colors.grey,
        gradient: `linear-gradient(100.95deg, ${colors.primaryBaseCrm} 15.41%, ${colors.secondaryBaseCrm} 100%)`,
      },
      background: {
        default: lighten(colors.secondaryBaseCrm, 0.85),
        paper: colors.white,
      },
      text: {
        main: colors.textBase,
        dark: darken(colors.textBase, 0.6),
        light: lighten(colors.textBase, 0.5),
      },
      error: {
        main: colors.errorBase,
        light: lighten(colors.errorBase, 0.6),
        dark: darken(colors.errorBase, 0.15),
      },
      divider: lighten(colors.primaryBaseCrm, 0.85),

      dashboardChart: {
        start: colors.primaryBaseCrm,
        finish: colors.secondaryBaseCrm,
      },
    },
    typography: {
      allVariants: {
        textTransform: "none",
      },
      fontFamily: ["Manrope", "sans-serif"].join(","),

      h1: {
        fontSize: 30,
        fontWeight: 700,
      },
      h2: {
        fontSize: 28,
        fontWeight: 700,
      },
      h3: {
        fontSize: 26,
        fontWeight: 700,
      },
      h4: {
        fontSize: 22,
        fontWeight: 700,
      },
      h5: {
        fontSize: 20,
        fontWeight: 700,
      },
      h6: {
        fontSize: 18,
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: 20,
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: 18,
        fontWeight: 500,
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
      },

      capture: {
        fontSize: 12,
        fontWeight: 400,
      },
    },
    shape: {
      borderRadius: 8,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 900,
        lg: 1200,
        xl: 1920,
      },
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "*::-webkit-scrollbar": {
            width: "6px",
          },
          "*::-webkit-scrollbar:horizontal": {
            height: "6px",
          },
          "*::-webkit-scrollbar-track": {
            background: "transparent",
            borderRadius: 20,
          },
          "*::-webkit-scrollbar-thumb": {
            background: lighten(colors.primaryBaseCrm, 0.45),
            borderRadius: 20,
            transition: "background 0.5s ease",
          },
          "*::-webkit-scrollbar-thumb:hover": {
            background: lighten(colors.primaryBaseCrm, 0.2),
            borderRadius: 20,
            transition: "background 0.5s ease",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "outlined",
        },
        styleOverrides: {
          root: {
            fontSize: 16,
            fontWeight: 500,
            boxShadow: "none",
            minHeight: "44px",
            minWidth: "124px",
          },
          contained: {
            "&:disabled": {
              backgroundColor: lighten(colors.primaryBaseCrm, 0.45),
              color: colors.white,
            },
          },
        },
      },

      MuiToggleButton: {
        styleOverrides: {
          root: {
            backgroundColor: lighten(colors.primaryBaseCrm, 0.95),
            color: lighten(colors.primaryBaseCrm, 0.45),
            minHeight: "44px",
            minWidth: "124px",
            fontSize: 18,
            paddingTop: 6,
            paddingBottom: 6,
            border: "none",
            fontWeight: 600,
            "&.Mui-selected": {
              backgroundColor: colors.white,
              color: colors.primaryBaseCrm,
            },
            "&.Mui-selected:hover, &:hover": {
              backgroundColor: lighten(colors.primaryBaseCrm, 0.85),
              color: colors.primaryBaseCrm,
            },
            [createTheme().breakpoints.down("sm")]: { fontSize: 16, minWidth: "fit-content", flex: 1 },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            "&.MuiDrawer-paper": {
              background: `linear-gradient(100.95deg, ${colors.primaryBaseCrm} 15.41%, ${colors.secondaryBaseCrm} 100%)`,
              borderRadius: "0 16px 16px 0",
              border: "none",
              padding: "16px 8px 16px 8px",
            },
            "&.MuiPickersPopper-paper": {
              background: lighten(colors.secondaryBaseCrm, 0.9),
            },
            "&.MuiAccordion-root": {
              margin: 0,
              border: "none",
              boxShadow: "none",
              "&:before": { content: "none" },
            },
          },
        },
      },
      MuiAvatar: {
        defaultProps: {
          variant: "rounded",
        },
        styleOverrides: {
          root: {
            backgroundColor: lighten(colors.primaryBaseCrm, 0.45),
            border: "none",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: 14,
            color: lighten(colors.textBase, 0.5),
            "&.MuiInputLabel-root": {
              top: 4,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            backgroundColor: colors.white,
            borderRadius: 4,
            margin: "4px 8px",
            color: darken(colors.textBase, 0.6),
            [createTheme().breakpoints.down("md")]: {
              fontSize: 14,
            },
          },
          paper: {
            backgroundColor: lighten(colors.secondaryBaseCrm, 0.9),
          },
          tag: {
            margin: 0,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: colors.textBase,
            "&::before": {
              borderBottom: `1px solid ${lighten(colors.primaryBaseCrm, 0.85)}`,
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: `1px solid ${lighten(colors.primaryBaseCrm, 0.45)}`,
            },
            "&.Mui-focused:after": {
              borderBottom: `1px solid ${colors.primaryBaseCrm}`,
            },
            "& input::placeholder": {
              color: lighten(colors.primaryBaseCrm, 0.45),
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: lighten(colors.primaryBaseCrm, 0.85),
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: lighten(colors.primaryBaseCrm, 0.45),
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              border: `1px solid ${colors.primaryBaseCrm}`,
            },
            "& input::placeholder": {
              color: lighten(colors.primaryBaseCrm, 0.45),
            },
            "& input": {
              padding: "10px 0 10px 16px",
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            fontSize: 16,
            color: colors.textBase,
            border: "none",
          },
          columnHeader: {
            "&:focus-within": {
              outline: "none",
            },
          },
          columnHeaders: {
            borderRadius: 16,
            color: darken(colors.textBase, 0.6),
            fontWeight: 600,
            borderBottom: "none",
            backgroundColor: lighten(colors.secondaryBaseCrm, 0.9),
          },
          row: {
            borderRadius: 16,
            alignItems: "center",
            "&.Mui-odd": {
              backgroundColor: lighten(colors.secondaryBaseCrm, 0.95),
            },
          },
          cell: {
            borderBottom: "none",
            lineHeight: 2,
          },
          footerContainer: {
            borderTop: "none",
            borderRadius: 16,
            justifyContent: "center",
            backgroundColor: lighten(colors.secondaryBaseCrm, 0.9),
          },
          checkboxInput: {
            color: lighten(colors.primaryBaseCrm, 0.85),
          },
          virtualScroller: {
            minHeight: 24,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: lighten(colors.primaryBaseCrm, 0.85),
            padding: 4,
          },
          label: {
            color: colors.textBase,
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            color: lighten(colors.textBase, 0.5),
          },
          select: {
            color: colors.primaryBaseCrm,
          },
          selectIcon: {
            color: colors.primaryBaseCrm,
          },
          actions: {
            color: colors.primaryBaseCrm,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: colors.primaryBaseCrm,
            fontWeight: 500,
            fontSize: 14,
            "&.Mui-selected": {
              borderRadius: 8,
            },
            "&:not(.Mui-selected)": {
              border: "none",
            },
          },
          today: {
            color: colors.white,
            backgroundColor: lighten(colors.successBase, 0.7),
            borderRadius: 8,
            "&:hover": {
              backgroundColor: colors.successBase,
            },
          },
        },
      },
      MuiDayCalendar: {
        styleOverrides: {
          weekDayLabel: {
            color: darken(colors.textBase, 0.6),
            fontSize: 14,
          },
        },
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          button: {
            color: colors.primaryBaseCrm,
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            color: colors.primaryBaseCrm,
            fontSize: 16,
          },
          switchViewIcon: {
            color: colors.primaryBaseCrm,
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            "&.MuiButtonBase-root": {
              padding: 0,
              minHeight: 0,
              justifyContent: "flex-start",
              gap: 4,
            },
          },
          content: {
            flexGrow: 0,
            margin: 0,
            "&.Mui-expanded": {
              margin: 0,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: darken(colors.textBase, 0.6),
            paddingLeft: 16,
            paddingRight: 16,
            "&:hover": {
              backgroundColor: "transparent",
              color: colors.primaryBaseCrm,
              transition: "color 0.3s",
            },
            "&.Mui-selected": {
              backgroundColor: lighten(colors.primaryBaseCrm, 0.85),
              borderRadius: 8,
              transition: "background-color 0.3s, color 0.3s",
              "&:hover": { color: colors.primaryBaseCrm, backgroundColor: lighten(colors.primaryBaseCrm, 0.85) },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "transparent",
          },
          flexContainer: {
            gap: 16,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: lighten(colors.secondaryBaseCrm, 0.9),
            fontSize: 16,
            maxWidth: "100%",
            borderRadius: "16px 16px 0px 0px",
            "&.Mui-selected": {
              backgroundColor: colors.white,
              fontWeight: 700,
            },
            "&:hover": {
              backgroundColor: lighten(colors.secondaryBaseCrm, 0.95),
              fontWeight: 700,
            },
          },
        },
      },
    },
    logo,
    logoShort,
  });
