import { Fragment, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Avatar,
  Stack,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  IconButton,
  Box,
  styled,
  Tooltip,
  useTheme,
} from "@mui/material";

import { MenuClosedIcon, MenuOpenIcon } from "theme/icons";
import { userSelectors } from "store/ducks/user";
import { getLastNameAndNameLetter } from "utils/helpers";
import { MAIN_MENU } from "utils/constants";

import defaultLogo from "../../theme/icons/general-dan-logo-white.svg";

const DRAWER_WIDTH = 224;

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: theme.spacing(10),
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const theme = useTheme();
  const user = useSelector(userSelectors.getCurrentUser());
  const [open, setOpen] = useState(true);

  const rolesList = user.roles
    .map((role) => role.label)
    .slice(0, -1)
    .join(" | ");

  const location = useLocation();
  const path = location.pathname.split("/");
  const rootPath = "/" + path[1];

  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Stack direction="row" alignItems="center" justifyContent={open ? "space-between" : "center"}>
        {open && <img style={{ width: "calc(100% - 60px)" }} src={theme.logo || defaultLogo} />}
        <IconButton onClick={handleDrawerOpen} sx={{ color: "extra.white" }}>
          {open ? <MenuOpenIcon /> : <MenuClosedIcon />}
        </IconButton>
      </Stack>
      {/* --- AVATAR --- */}
      <Stack
        flexDirection={open ? "row" : "column"}
        gap={open ? 2 : 0.5}
        alignItems="center"
        my={5}
        ml={open ? 2.5 : 0}
      >
        <Avatar sx={{ width: 56, height: 56 }} alt={user.name} src={user.avatar} />
        <Box>
          <Typography component={"span"} fontSize={18} color="extra.white" display={open ? "flex" : "none"}>
            {getLastNameAndNameLetter(user)}
          </Typography>
          <Typography variant="capture" color="extra.white" display={open ? "flex" : "none"}>
            {user.roles?.at(-1).label}
          </Typography>
          {/** //TODO: is it needed?  */}
          {user.roles.length > 1 && (
            <Tooltip title={rolesList}>
              <Typography
                sx={{ cursor: "pointer" }}
                variant="capture"
                color="extra.white"
                display={open ? "flex" : "none"}
              >
                +{user.roles.length - 1} {t("base.dictionary.more").toLowerCase()}
              </Typography>
            </Tooltip>
          )}
        </Box>
      </Stack>
      <Stack display="flex" direction="column" alignItems="center" justifyContent="flex-start" gap={1} height="100%">
        {MAIN_MENU.map((menuItem) => {
          const menuItemColor = menuItem.to === rootPath ? "primary.main" : "extra.white";
          const menuItemBgColor = menuItem.to === rootPath ? "extra.white" : "transparent";
          const hoverItemBgColor = menuItem.to === rootPath ? "rgba(224, 224, 224, 0.9)" : "rgba(0, 0, 0, 0.1)";

          return (
            <Fragment key={menuItem.key}>
              {/*
               //TODO: permissions in CRM ?  
               {hasPermission(menuItem.permission) && ( */}
              <ListItem disableGutters sx={{ p: 0, "&:last-child": { mt: "auto", mb: 3 } }}>
                <ListItemButton
                  sx={{
                    py: 1,
                    backgroundColor: menuItemBgColor,
                    borderRadius: 2,
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: hoverItemBgColor,
                    },
                  }}
                  component={Link}
                  to={menuItem.to}
                  disableGutters
                >
                  <ListItemIcon
                    sx={{
                      justifyContent: "center",
                      color: menuItemColor,
                    }}
                  >
                    {menuItem.icon}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary={t(`mainMenu.${menuItem.key}`)}
                      sx={{
                        color: menuItemColor,
                        "&>.MuiTypography-root": {
                          fontSize: 18,
                        },
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            </Fragment>
          );
        })}
      </Stack>
    </Drawer>
  );
};

export default memo(Sidebar);
