import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { dictionarySelectors } from "store/ducks/dictionary";

import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { AttentionIcon } from "theme/icons";
import Modal from "views/common/modal/Modal";
import CustomAutocomplete from "views/filter/CustomAutocomplete";

const ArchivePipelineLeadsModal = ({
  modalOpen,
  closeModal,
  handleArchivePipeline,
  handleArchivePipelineAndAllLeads,
  isSubmitting,
}) => {
  const leadCloseReasons = useSelector(dictionarySelectors.getLeadCloseReasons());
  const [closeReason, setCloseReason] = useState(null);
  const { t } = useTranslation();
  return (
    <Modal
      icon={<AttentionIcon />}
      open={modalOpen}
      onClose={closeModal}
      title={t("types.pipeline.archiveModal.title")}
      actions={
        <Stack gap={3} direction="row" alignItems="center" justifyContent="center">
          <Button
            onClick={() => handleArchivePipeline()}
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={16} sx={{ mr: 1, color: "primary.main" }} /> : null}
          >
            {t("types.pipeline.archiveModal.btnArchivePipeline")}
          </Button>
          <Button
            variant="contained"
            disabled={closeReason === null || isSubmitting}
            onClick={() => handleArchivePipelineAndAllLeads(closeReason)}
            startIcon={isSubmitting ? <CircularProgress size={16} sx={{ mr: 1, color: "extra.white" }} /> : null}
          >
            {t("types.pipeline.archiveModal.btnArchiveLeads")}
          </Button>
        </Stack>
      }
    >
      <Stack mx={3} my={2} justifyContent="center" alignItems="center" gap={1}>
        <Typography variant="h6" color="warning.main">
          {t("types.pipeline.archiveModal.message")}
        </Typography>
        <CustomAutocomplete
          options={leadCloseReasons}
          value={closeReason}
          label={t("base.labels.reasonOfClosing")}
          onChange={(value) => setCloseReason(value)}
        />

      </Stack>
    </Modal>
  );
};

export default memo(ArchivePipelineLeadsModal);
