import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Button, IconButton, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { AddCircleOutlineOutlined, ChevronLeftOutlined } from "@mui/icons-material";

import { ColumnsIcon, ExportIcon, ListIcon } from "theme/icons";
import SearchBar from "./SearchBar";
import ColumnIndicators from "./ColumnIndicators";
import PipelineSelector from "./topbar/PipelineSelector";
import TasksFilter from "views/pages/tasks/components/TasksFilter";
import MyLeadsFilter from "views/pages/leads/components/MyLeadsFilter";
import AllLeadsFilter from "views/pages/leads/components/AllLeadsFilter";

import KeyCall from "views/pages/leads/components/KeyCall";
import BulkLeadsStage from "views/pages/leads/components/BulkLeadsStage";
import BulkLeadsStatus from "views/pages/leads/components/BulkLeadsStatus";
import BulkLeadsTask from "views/pages/leads/components/BulkLeadsTask";

import { isFilterFieldFilled } from "utils/helpers";
import { MOBILE_VIEW } from "utils/constants";

const HeaderPageMobile = ({
  isSearchbarShow,
  onSearch,
  pageView,
  setPageView,
  togglePageView,
  createNewItemAction,
  pipelinesList,
  selectedPipeline,
  setSelectedPipeline,
  tasksFilter,
  handleFilterChange,
  myLeadsFilter,
  handleMyLeadsFilterChange,
  allLeadsFilter,
  handleAllLeadsFilter,
  initialAllLeadsFilterValues,
  handleApplyAllLeadsFilter,
  setIsAllLeadsFilterApplied,
  appliedAllLeadsFilter,
  setAppliedAllLeadsFilter,
  handleExportToExcel,
  currentColumnIndex,
  columnIndicator,
  indicatorColumnsList,
  selectedLeads,
  isAllLeadsFilterApplied,
  query,
  closeLead,
  openModal,
}) => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const theme = useTheme();
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const { t } = useTranslation();
  return (
    <Stack px={1} pt={1} pb={0} width="100%" gap={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <IconButton LinkComponent={Link} onClick={goBack} sx={{ p: 0, color: "primary.main" }}>
          <ChevronLeftOutlined fontSize="large" />
        </IconButton>
        {isSearchbarShow && <SearchBar onSearch={onSearch} />}
        {pageView && (
          <Tooltip
            title={pageView === "list" ? t("base.tooltips.switchToColView") : t("base.tooltips.switchToListView")}
          >
            <IconButton onClick={togglePageView}>
              {pageView === "list" ? (
                <ListIcon fill={theme.palette.primary.main} />
              ) : (
                <ColumnsIcon fill={theme.palette.primary.main} />
              )}
            </IconButton>
          </Tooltip>
        )}
        {createNewItemAction && !tasksFilter && !myLeadsFilter && !allLeadsFilter && (
          <IconButton onClick={createNewItemAction} sx={{ color: "primary.main" }}>
            <AddCircleOutlineOutlined />
          </IconButton>
        )}
        {closeLead && (
          <Button onClick={openModal} variant="text" color="error" sx={{ fontWeight: 600 }}>
            {t("types.lead.closeLead")}
          </Button>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {createNewItemAction && (tasksFilter || myLeadsFilter || allLeadsFilter) && (
          <IconButton onClick={createNewItemAction} sx={{ color: "primary.main" }}>
            <AddCircleOutlineOutlined />
          </IconButton>
        )}
        {pipelinesList && (
          <PipelineSelector
            pipelinesList={pipelinesList}
            pageView={pageView}
            setPageView={setPageView}
            selectedPipeline={selectedPipeline}
            setSelectedPipeline={setSelectedPipeline}
          />
        )}
        <Stack direction="row" alignItems="center">
          {tasksFilter && <TasksFilter filter={tasksFilter} handleFilter={handleFilterChange} pageView={pageView} />}
          {myLeadsFilter && <MyLeadsFilter filter={myLeadsFilter} handleFilter={handleMyLeadsFilterChange} />}
          {allLeadsFilter && (
            <>
              <AllLeadsFilter
                filter={allLeadsFilter}
                handleFilter={handleAllLeadsFilter}
                initialAllLeadsFilterValues={initialAllLeadsFilterValues}
                handleApplyAllLeadsFilter={handleApplyAllLeadsFilter}
                setIsAllLeadsFilterApplied={setIsAllLeadsFilterApplied}
                setAppliedAllLeadsFilter={setAppliedAllLeadsFilter}
              />
              <Tooltip title={t("base.tooltips.export")}>
                <span>
                  <IconButton disabled={!isFilterFieldFilled(appliedAllLeadsFilter)} onClick={handleExportToExcel}>
                    <ExportIcon
                      fill={
                        !isFilterFieldFilled(appliedAllLeadsFilter)
                          ? theme.palette.action.disabled
                          : theme.palette.primary.main
                      }
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
          {selectedLeads?.length > 0 && (
            <>
              <KeyCall
                isAllLeadsFilterApplied={isAllLeadsFilterApplied}
                query={query}
                appliedAllLeadsFilter={appliedAllLeadsFilter}
              />
              <BulkLeadsStage
                isAllLeadsFilterApplied={isAllLeadsFilterApplied}
                query={query}
                appliedAllLeadsFilter={appliedAllLeadsFilter}
              />
              <BulkLeadsStatus
                isAllLeadsFilterApplied={isAllLeadsFilterApplied}
                query={query}
                appliedAllLeadsFilter={appliedAllLeadsFilter}
              />
              <BulkLeadsTask />
            </>
          )}
        </Stack>
      </Stack>

      {isMobile && columnIndicator && (
        <ColumnIndicators columns={indicatorColumnsList} currentIndex={currentColumnIndex} />
      )}
    </Stack>
  );
};

export default memo(HeaderPageMobile);
