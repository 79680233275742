import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FilterAltOffOutlined, FilterAltOutlined, Close } from "@mui/icons-material";

import { DueDateIcon } from "theme/icons";

import { INTERACTION_TYPES, MOBILE_VIEW } from "utils/constants";
import CustomDatePicker from "views/filter/CustomDatePicker";

const InteractionsFilter = ({
  filter,
  handleFilter,
  handleClearAll,
  handleSelectAll,
  handleDateFrom,
  handleDateTo,
  handleApplyFilter,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApplyAndClose = () => {
    handleApplyFilter();
    handleClose();
  };
  return (
    <>
      <Tooltip title={t("base.tooltips.historyFilter")}>
        <IconButton
          id="interactions-filter-button"
          aria-controls={open ? "interactions-filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickOpen}
          sx={{ color: "primary.main", p: 0, ...(isMobile && { position: "absolute", top: 8, right: 4, zIndex: 2, p: 1 }) }}
        >
          {Object.values(filter).some((item) => item === true) || (filter.from !== null && filter.to !== null) ? (
            <FilterAltOffOutlined />
          ) : (
            <FilterAltOutlined />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        id="interactions-filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "interactions-filter",
          role: "listbox",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            padding: 1,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            ml: isMobile ? 0 : 4,
            width: isMobile ? "100%" : "fit-content",
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 16,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Stack px={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            bgcolor="primary.lightest"
            borderRadius={1}
            px={2}
            py={0.5}
          >
            <Stack direction="row" alignItems="center" gap={2} width="100%">
              <DueDateIcon />
              <Typography color="text.dark">{t("types.lead.interactionsFilter.datePeriod")}</Typography>
            </Stack>
            <IconButton sx={{ color: "primary.main", p: 0 }} onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          <Divider sx={{ mt: 1, width: "100%" }} />
          <Stack direction="row" justifyContent="space-between" alignItems="center" px={1} py={1} gap={3}>
            <CustomDatePicker value={filter.from} onChange={(date) => handleDateFrom(date)} />
            <Typography>-</Typography>

            <CustomDatePicker value={filter.to} onChange={(date) => handleDateTo(date)} />
          </Stack>
          <Divider sx={{ width: "100%" }} />
        </Stack>
        <FormGroup>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" width="100%" px={1}>
            {INTERACTION_TYPES.map((type) => (
              <FormControlLabel
                key={type.type}
                sx={{
                  gap: 2,
                  ml: 0,
                  px: 1,
                  py: isMobile ? 0 : 0.5,
                  width: "100%",
                  backgroundColor: filter[type.type] === true ? "primary.lightest" : "transparent",
                  borderRadius: 1,
                }}
                control={
                  <Checkbox
                    sx={{ color: "primary.main" }}
                    size="small"
                    checked={filter[type.type]}
                    onChange={handleFilter}
                    name={type.type}
                  />
                }
                label={
                  <Stack direction="row" gap={1}>
                    {type.icon}
                    <Typography>{t(`types.lead.interactionsFilter.${type.key}`)}</Typography>
                  </Stack>
                }
              />
            ))}
          </Stack>
        </FormGroup>
        <Stack px={1} width="100%">
          <Divider sx={{ mt: 1, width: "100%" }} />
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <Button
              onClick={handleSelectAll}
              variant="text"
              sx={{
                fontSize: 14,
                px: 2,
                minHeight: 32,
                minWidth: "fit-content",
                color: "text.main",
                "&:hover": { px: 2, py: 0.5 },
              }}
            >
              {t("base.buttons.chooseAll")}
            </Button>
            <Button
              onClick={handleClearAll}
              variant="text"
              sx={{
                fontSize: 14,
                px: 2,
                minHeight: 32,
                minWidth: "fit-content",
                color: "text.main",
                "&:hover": { px: 2, py: 0.5 },
              }}
            >
              {t("base.buttons.clearAll")}
            </Button>
          </Stack>
          <Divider sx={{ mb: 1, width: "100%" }} />
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
            <Button onClick={handleApplyAndClose} sx={{ width: "100%" }} variant="contained">
              {t("base.buttons.apply")}
            </Button>
            <Button onClick={handleClose} sx={{ width: "100%" }}>
              {t("base.buttons.cancel")}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default memo(InteractionsFilter);
