import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, Divider, Menu, MenuItem, Typography, styled, Stack, useTheme } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ListIcon } from "theme/icons";

import store from "store";
import { pipelineActions } from "store/ducks/pipeline";

const SingleMenuItem = styled(MenuItem)(({theme}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme.palette.primary.main,
  fontWeight: 700,
  border: "1px solid",
  borderColor: "transparent",
  transition: "borderColor, 0.3s",
  borderRadius: 8,
  "&:hover": {
    border: "1px solid",
    borderColor: theme.palette.primary.lighter,
  },
}));

const PipelineSelector = ({ pipelinesList, pageView, setPageView, selectedPipeline, setSelectedPipeline }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetAllLeads = () => {
    setPageView("list");
    setSelectedPipeline("All");
    handleClose();
    navigate("/leads");
  };

  const handleSelectPipeline = (menuItem) => {
    store.dispatch(pipelineActions.resetStagesLeads());
    setSelectedPipeline(menuItem);
    setPageView("col");
    handleClose();
    navigate(`/leads/pipeline/${menuItem.id}`);
  };

  const menuItems = pipelinesList.map((menuItem) => (
    <MenuItem
      key={menuItem.id}
      selected={selectedPipeline?.id === menuItem.id}
      sx={{
        border: "1px solid",
        borderColor: "transparent",
        transition: "borderColor, 0.3s",
        borderRadius: 1,
        "&:hover": {
          border: "1px solid",
          borderColor: "primary.lighter",
        },
      }}
      onClick={() => handleSelectPipeline(menuItem)}
    >
      {menuItem?.name}
    </MenuItem>
  ));
  return (
    <>
      <Button
        variant="text"
        id="pipeline-select"
        aria-controls={open ? "pipeline-select-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClickOpen}
        sx={{ minWidth: 0, minHeight: 0, p: 0, color: "text.main", fontSize: 18 }}
        endIcon={
          open ? (
            <KeyboardArrowUpIcon sx={{ marginLeft: 0.5, width: 20, height: 20 }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ marginLeft: 0.5, width: 20, height: 20 }} />
          )
        }
      >
        {selectedPipeline === "All" ? "All leads" : selectedPipeline?.name}
      </Button>

      <Menu
        id="pipeline-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "pipeline-select",
          role: "listbox",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            px: 0.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {pageView === "col" && (
          <Stack>
            <SingleMenuItem onClick={handleSetAllLeads}>
              <Typography>{t("base.dictionary.all") + " " + t("types.lead.namePlural")}</Typography>
              <ListIcon fill={theme.palette.primary.main} />
            </SingleMenuItem>
            <Divider sx={{ borderColor: "background.default" }} />
          </Stack>
        )}
        {menuItems}
      </Menu>
    </>
  );
};

export default memo(PipelineSelector);
