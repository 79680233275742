import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Divider, Fade, Stack, Tooltip, Typography, useTheme } from "@mui/material";

import filters from "filters";
import { getShortName } from "utils/helpers";
import TaskCardMenu from "./TaskCardMenu";
import { AttachmentIcon } from "theme/icons";
import { DateTime } from "luxon";

const TaskCard = ({ task }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const dateTimeString = `${task.dueDate}T${task.dueTime || ""}`;
  const taskDateTime = task.dueTime
    ? DateTime.fromISO(dateTimeString, { zone: task.timezone }).setZone(DateTime.local().zoneName)
    : DateTime.fromISO(task.dueDate, { zone: task.timezone }).setZone(DateTime.local().zoneName);

  const formattedDateTime = taskDateTime.toFormat(`dd.MM.yyyy${task.dueTime ? " / HH:mm" : " / --:--"}`);

  return (
    <Stack sx={{ backgroundColor: "extra.white" }} p={1} borderRadius={1} gap={1} flex={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ cursor: "grab" }}>
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          enterDelay={500}
          title={task.name.length > 40 ? task.name : ""}
        >
          <Typography
            variant="body2"
            color="text.main"
            component={Link}
            to={`/tasks/${task.id}`}
            sx={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              wordWrap: "break-word",

              textDecoration: "none",
              "&:hover": {
                fontWeight: 600,
              },
            }}
          >
            {task.name}
          </Typography>
        </Tooltip>
        <TaskCardMenu task={task} />
      </Stack>
      <Divider sx={{ borderColor: "background.default" }} />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="capture" color="text.light">
          {task.type ? task.type?.name : "-"}
        </Typography>
        <Typography variant="capture" color="text.main">
          {task?.dueDate ? formattedDateTime : `${t('types.task.noDueDate')}`}
        </Typography>
      </Stack>
      <Divider sx={{ borderColor: "background.default" }} />
      <Stack>
        <Stack direction="column" alignItems="flex-start" position="relative">
          {task.description ? (
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              enterDelay={500}
              title={task.description.length > 40 ? task.description : ""}
            >
              <Typography
                variant="body2"
                color="primary.main"
                mr={3}
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  wordWrap: "break-word",
                }}
              >
                {task.description}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2" color="text.light">
              {t("messages.warning.noDescription")}
            </Typography>
          )}
          {task.files.map((file) => (
            <Stack key={file.fileId} direction="row" alignItems="center" gap={0.5}>
              <AttachmentIcon fill={theme.palette.info.main} />
              <Typography
                variant="capture"
                color="text.light"
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  wordWrap: "break-word",
                }}
              >
                {file.fileName}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <Divider sx={{ borderColor: "background.default", mt: 1 }} />
      </Stack>
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="capture" color="text.light">
            {getShortName(task.responsible?.[0])}
          </Typography>
          <Typography variant="capture" color="text.light">
            {filters.date(task.createdAt)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(TaskCard);
