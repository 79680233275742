const loadDataPaginatedState = (isLoading = true) => ({
  data: {
    content: [],
    pageable: 'INSTANCE',
    last: true,
    totalElements: 0,
    totalPages: 1,
    sort: { 'empty': true, 'sorted': false, 'unsorted': true },
    first: true,
    size: 0,
    number: 0,
    numberOfElements: 0,
    empty: true,
  },
  isLoading,
  error: null,
});

export default loadDataPaginatedState;

