import { useCallback, useState } from "react";

export default function useQuickAddInput() {
  const [editMode, setEditMode] = useState(false);
  const [newValue, setNewValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const enableEditMode = useCallback((initialValue = "") => {
    setNewValue(initialValue);
    setEditMode(true);
  }, []);

  const disableEditMode = () => {
    setEditMode(false);
    setNewValue("");
  };

  return [editMode, newValue, setNewValue, enableEditMode, disableEditMode, isLoading, setIsLoading];
}
