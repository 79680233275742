import { memo, useCallback, useEffect, useRef } from "react";
import { CSS } from "@dnd-kit/utilities";
import { SortableContext, horizontalListSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { useSelector } from "react-redux";

import { Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SortUpDownIcon } from "theme/icons";

import store from "store";
import { pipelineSelectors, pipelineThunks } from "store/ducks/pipeline";

import LeadCard from "./LeadCard";
import Loader from "views/common/Loader";
import { MOBILE_VIEW } from "utils/constants";

const StageCard = ({ stage, leadsIds, filter }) => {
  const leads = useSelector(pipelineSelectors.getPipelineLeads(stage.id));
  const leadsLoading = useSelector(pipelineSelectors.pipelineLeadsLoading(stage.id)) && !leads;
  const isFetchingRef = useRef(false);
  const theme = useTheme();

  const isMobile = useMediaQuery(MOBILE_VIEW);

  const handleScroll = useCallback(
    (e) => {
      const threshold = 5;
      const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= threshold;

      if (bottom && !isFetchingRef.current && !leadsLoading && !leads.last) {
        isFetchingRef.current = true;
        store
          .dispatch(
            pipelineThunks.fetchStagesLeads({
              stageId: stage.id,
              page: leads.pageable?.pageNumber + 1,
              filter: filter,
            })
          )
          .finally(() => {
            isFetchingRef.current = false;
          });
      }
    },
    [leadsLoading, leads.last, leads.pageable?.pageNumber, stage.id, filter]
  );

  useEffect(() => {
    store.dispatch(pipelineThunks.fetchStagesLeads({ stageId: stage.id, page: 0, filter }));
  }, [stage.id, filter]);

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: stage.id,
    data: {
      type: "Stage",
      stage,
    },
  });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <Stack
        ref={setNodeRef}
        px={1}
        py={2}
        height="100%"
        borderRadius={2}
        minWidth={320}
        maxWidth={360}
        sx={{ backgroundColor: "secondary.lighter", border: "1px solid", borderColor: "primary.light" }}
        style={style}
      />
    );
  }

  return (
    <Stack
      ref={setNodeRef}
      style={style}
      px={1}
      py={2}
      height="100%"
      borderRadius={2}
      minWidth={isMobile ? "100%" : 320}
      maxWidth={isMobile ? "100%" : 360}
      sx={{ backgroundColor: "secondary.lighter" }}
      boxShadow={`0px 0px 4px 0px ${theme.palette.primary.lighter}`}
    >
      <Stack
        {...attributes}
        {...listeners}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ cursor: "grab" }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="h6" color="text.dark">
            {stage.name}
          </Typography>
          <Typography color={"text.dark"}>({leads.totalElements})</Typography>
        </Stack>
        <IconButton>
          <SortUpDownIcon />
        </IconButton>
      </Stack>
      <Divider sx={{ borderColor: "background.default", borderWidth: "2px", my: 2 }} />
      <Stack sx={{ overflowY: "auto" }} gap={1} onScroll={handleScroll}>
        <SortableContext items={leadsIds} strategy={horizontalListSortingStrategy}>
          {leadsLoading ? <Loader /> : leads?.content?.map((lead) => <LeadCard key={lead.id} lead={lead} />)}
        </SortableContext>
      </Stack>
    </Stack>
  );
};

export default memo(StageCard);
