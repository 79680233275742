import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import loadDataInitialState from "store/utils/loadDataInitialState";
import loadDataExtraReducer from "store/utils/loadDataExtraReducer";

const binotel = createSlice({
  name: "binotelSlice",
  initialState: {
    record: loadDataInitialState(""),
  },
  reducers: {},
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchRecord, "record");
  },
});

export const {} = binotel.actions;
export default binotel.reducer;
