import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "services/api";
import { replaceItem  } from "./slice";

const fetchTasks = createAsyncThunk("task/fetchTasks", (query) => {
  return api.get(`/tasks${query}`);
});

const fetchTask = createAsyncThunk("task/fetchTask", (id) => {
  return api.get(`/tasks/${id}`);
});

const createTask = createAsyncThunk("task/createTask", (data) => {
  return api.post(`/tasks`, data).then((res) => {
    return res;
  });
});

const updateTask = createAsyncThunk("task/updateTask", (data, { dispatch }) => {
  return api.put(`/tasks/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: "activeTasks", data: res }));
    return res;
  });
});

const fetchActiveTasks = createAsyncThunk("task/fetchActiveTasks", (query) => {
  return api.get(`/tasks/active${query}`);
});

export default {
  fetchTasks,
  fetchTask,
  createTask,
  updateTask,
  fetchActiveTasks,
};
