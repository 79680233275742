import { memo } from "react";
import { Box, Button, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const ErrorPage = ({
  title = "Something went wrong",
  message = "It appears something went wrong on our end. We are working to resolve the problem. Please try again later.",
}) => {
  const reloadPage = () => {
    window.location.assign("/");
  };

  return (
    <Box>
      <Box pt={25} pl={22} sx={{ maxWidth: 800 }}>
        <Typography>{title}</Typography>
        <Box mt={3}>
          <Typography>{message}</Typography>
        </Box>
        <Box mt={4}>
          <Button startIcon={<ChevronLeftIcon />} onClick={reloadPage} variant="contained">
            Return to home page
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ErrorPage);
