import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import Error from "views/common/Error";
import Sidebar from "views/common/Sidebar";
import TabletTopBar from "views/common/topbar/TabletTopBar";
import MobileTopBar from "views/common/topbar/MobileTopBar";

const PageLayout = () => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));

  const { t } = useTranslation();

  return (
    <>
      <Box display="flex">
        {/* --- Aside menu  --- */}
        {isDesktop && (
          <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
            <Sidebar />
          </ErrorBoundary>
        )}
        {isDesktop ? (
          <Container sx={{ maxWidth: { xl: "none" } }}>
            <Outlet />
          </Container>
        ) : (
          <Container disableGutters>
            <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
              {/** --- Mobile view --- */}
              {isTablet ? <TabletTopBar /> : <MobileTopBar />}
            </ErrorBoundary>
            <Outlet />
          </Container>
        )}
      </Box>
    </>
  );
};

export default PageLayout;
