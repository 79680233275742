import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";

import HeaderPage from "views/common/HeaderPage";
import Error from "views/common/Error";
import { PageWrapper } from "views/common/styledComponents";
import { DESKTOP_VIEW } from "utils/constants";
import PeriodSelector from "./components/PeriodSelector";
import Indicators from "./components/Indicators";
import Chart from "./components/Chart";

const HomePage = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  return (
    <PageWrapper>
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPage title={t("mainMenu.dashboard")} />
        </ErrorBoundary>
      )}

      <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <PeriodSelector />
        </ErrorBoundary>
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <Indicators />
        </ErrorBoundary>
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <Chart />
        </ErrorBoundary>
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default memo(HomePage);
