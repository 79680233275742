import { memo } from "react";

import { useMediaQuery } from "@mui/material";

import { PageWrapper } from "views/common/styledComponents";

import { MOBILE_VIEW } from "utils/constants";

import MobileProfileView from "./components/MobileProfileView";
import DesktopProfileView from "./components/DesktopProfileView";

const ProfilePage = () => {
  const isMobile = useMediaQuery(MOBILE_VIEW);

  return (
    <PageWrapper sx={{ height: "calc(100dvh - 79px)" }}>
      {isMobile ? <MobileProfileView /> : <DesktopProfileView />}
    </PageWrapper>
  );
};

export default memo(ProfilePage);
