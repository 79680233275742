import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import loadDataInitialState from "store/utils/loadDataInitialState";
import loadDataExtraReducer from "store/utils/loadDataExtraReducer";
import loadDataPaginatedState from "store/utils/loadDataPaginatedState";

const task = createSlice({
  name: "taskSlice",
  initialState: {
    tasks: loadDataPaginatedState(),
    task: loadDataInitialState(null),
    activeTasks: loadDataInitialState([]),
  },
  reducers: {
    addItem(state, action) {
      state[action.payload.collection].data.content.push(action.payload.data);
    },
    replaceItem(state, action) {
      const index = state[action.payload.collection].data.findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data[index] = action.payload.data;
    },
    updateItem(state, action) {
      state[action.payload.collection].data = action.payload.data;
    },
    deleteItem(state, action) {
      const index = state[action.payload.collection].data.findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data.splice(index, 1);
    },

    
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchTasks, "tasks");
    loadDataExtraReducer(builder, thunks.fetchTask, "task");
    loadDataExtraReducer(builder, thunks.fetchActiveTasks, "activeTasks");
  },
});

export const { addItem, replaceItem, updateItem, deleteItem } = task.actions;
export default task.reducer;
