import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import store from "store";
import { dictionarySelectors } from "store/ducks/dictionary";
import { leadThunks } from "store/ducks/lead";
import { tableSelectors } from "store/ducks/table";

import CustomAutocomplete from "views/filter/CustomAutocomplete";
import Modal from "views/common/modal/Modal";
import { AttentionIcon, StatusChangedIcon } from "theme/icons";

import useModal from "hooks/useModal";
import { CloseOutlined } from "@mui/icons-material";
import { MOBILE_VIEW } from "utils/constants";

const BulkLeadsStatus = ({ query, isAllLeadsFilterApplied, appliedAllLeadsFilter }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isMobile = useMediaQuery(MOBILE_VIEW);

  const selectedLeads = useSelector(tableSelectors.getSelected());
  const enums = useSelector(dictionarySelectors.getEnums());
  const closeReasons = useSelector(dictionarySelectors.getLeadCloseReasons());

  const [modalOpen, openModal, closeModal] = useModal();

  const leadIds = selectedLeads.length > 0 && selectedLeads?.map((lead) => lead?.id);

  const initialStatusDataValue = { status: null, closeReason: null };

  const [statusData, setStatusData] = useState(initialStatusDataValue);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setStatusData(initialStatusDataValue);
    setAnchorEl(null);
  };

  const handleApplyAndClose = async () => {
    const response = await store.dispatch(leadThunks.updateBulkLeadsStatus({ ids: leadIds, ...statusData }));

    if (!response.error) {
      toast.success(t("messages.success.toast.bulkStatus"));
      setAnchorEl(null);
      closeModal();

      if (isAllLeadsFilterApplied) {
        store.dispatch(leadThunks.filteredLeads({ query: `?${query}`, body: appliedAllLeadsFilter }));
      } else query && store.dispatch(leadThunks.fetchLeads(`?${query}`));
    }
  };

  return (
    <>
      <Tooltip title={t("base.tooltips.changeStatus")}>
        <IconButton
          id="bulk-status-button"
          aria-controls={open ? "bulk-status-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickOpen}
        >
          <StatusChangedIcon fill={theme.palette.primary.main} />
        </IconButton>
      </Tooltip>
      <Popover
        id="bulk-status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          width: "100%",
        }}
        MenuListProps={{
          "aria-labelledby": "bulk-status",
          role: "listbox",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            ...(isMobile && { width: "100%" }),
            ...(!isMobile && {
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 10,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            }),
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Stack direction="row" px={2} pt={1} justifyContent="space-between" alignItems="center" mb={1}>
          <Typography color="text.main" fontWeight={600}>
            {t("types.lead.bulkActions.statusTitle")}
          </Typography>
          <IconButton sx={{ color: "primary.main", p: 0 }} onClick={handleClose}>
            <CloseOutlined fontSize="small" />
          </IconButton>
        </Stack>
        <Divider sx={{ borderColor: "background.default", mx: 2 }} />
        <Stack p={2} pt={1} gap={1} width="100%" minWidth={300}>
          <CustomAutocomplete
            options={enums?.activeStatuses}
            value={statusData.status}
            label={t("base.labels.status")}
            onChange={(value) => setStatusData({ ...statusData, status: value })}
          />
          {statusData?.status?.name === "ARCHIVED" && (
            <CustomAutocomplete
              options={closeReasons}
              value={statusData.closeReason}
              label={t("base.labels.reasonOfClosing")}
              onChange={(value) => setStatusData({ ...statusData, closeReason: value })}
            />
          )}
          <Divider sx={{ width: "100%", my: 1 }} />
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
            <Button onClick={handleClose} sx={{ width: "100%" }}>
              {t("base.buttons.cancel")}
            </Button>
            <Button
              onClick={openModal}
              sx={{ width: "100%" }}
              variant="contained"
              disabled={(statusData?.status?.name === "ARCHIVED" && !statusData?.closeReason) || !statusData?.status}
            >
              {t("base.buttons.apply")}
            </Button>
          </Stack>
        </Stack>
      </Popover>
      <Modal
        title={`${t("types.lead.bulkActions.statusTitle")}`}
        open={modalOpen}
        onClose={closeModal}
        icon={<AttentionIcon />}
        actions={
          <>
            <Button sx={{ px: 8 }} onClick={closeModal}>
              {t("base.buttons.cancel")}
            </Button>
            <Button variant="contained" sx={{ px: 8 }} type="button" onClick={handleApplyAndClose}>
              {t("base.buttons.apply")}
            </Button>
          </>
        }
      >
        <Typography textAlign="center" color="text.main" fontWeight={600}>
          {statusData?.status?.name === "ARCHIVED"
            ? t("types.lead.bulkActions.statusMessageArchive")
            : t("types.lead.bulkActions.statusMessageActive")}{" "}
          {selectedLeads.length} {t("types.lead.namePlural").toLowerCase()}?
        </Typography>
      </Modal>
    </>
  );
};

export default memo(BulkLeadsStatus);
