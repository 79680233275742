import { memo, useState } from "react";

import { Stack, Typography, useTheme } from "@mui/material";

import MobileMenu from "./MobileMenu";

import { useTranslation } from "react-i18next";
import defaultLogoShort from "../../../theme/icons/short-dan-logo-white.svg";

const MobileTopBar = () => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");

  const { t } = useTranslation();

  return (
    <Stack
      p={2}
      sx={{
        background: theme.palette.extra.gradient,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <img src={theme.logoShort || defaultLogoShort} />
        <Typography variant="body1" color="extra.white">
          {open ? t("base.dictionary.menu") : t(`mainMenu.${selected}`)}
        </Typography>
        <MobileMenu open={open} setOpen={setOpen} setSelected={setSelected} />
      </Stack>
    </Stack>
  );
};

export default memo(MobileTopBar);
