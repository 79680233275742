const getPipelines = () => (state) => state.pipeline.pipelines.data;
const pipelinesLoading = () => (state) => state.pipeline.pipelines.isLoading;
const getPipelinesError = () => (state) => state.pipeline.pipelines.error;

const getPipeline = () => (state) => state.pipeline.pipeline.data;
const pipelineLoading = () => (state) => state.pipeline.pipeline.isLoading;
const getPipelineError = () => (state) => state.pipeline.pipeline.error;

const getPipelineLeads = (stageId) => (state) => state.pipeline.stagesLeads[stageId]?.data || { content: [] };
const pipelineLeadsLoading = (stageId) => (state) => state.pipeline.stagesLeads[stageId]?.isLoading || false;
const getPipelineLeadsError = (stageId) => (state) => state.pipeline.stagesLeads[stageId]?.error || null;

const getStagesLeads = () => (state) => state.pipeline.stagesLeads


export default {
  getPipelines,
  pipelinesLoading,
  getPipelinesError,
  getPipeline,
  pipelineLoading,
  getPipelineError,
  getPipelineLeads,
  pipelineLeadsLoading,
  getPipelineLeadsError,
  getStagesLeads,
};
