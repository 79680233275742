export default {
  primaryBaseCrm: "#4D54C9", // --- PURPLE ---
  secondaryBaseCrm: "#CDA564", // --- YELLOW ---
  textBase: "#40404B",
  errorBase: "#BF3030", // --- RED ---
  infoBase: "#1380FF", // --- BLUE ---
  warningBase: "#CEC600", // --- YELLOW ---
  successBase: "#30BFAD", // --- CYAN ---
  changed: "#DB00FF", // --- PINK ----
  white: "#FEFEFE",
  grey: "#A9A9A9",
};
