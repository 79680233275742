import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Stack, Typography, useTheme } from "@mui/material";
import { Form, withFormik } from "formik";

import { useTranslation } from "react-i18next";

import store from "store";
import { userThunks } from "store/ducks/user";

import FormikTextField from "views/form/FormikTextField";

import { forgotPasswordSchema } from "utils/schema";
import { GradientButton } from "views/common/styledComponents";
import { CrmTextIcon } from "theme/icons";

import defaultLogo from "../../../theme/icons/dan-logo-color.svg";

const ForgotPassword = ({ isSubmitting, values }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack
      justifyContent="center"
      direction="column"
      alignItems="center"
      sx={{
        position: "fixed",
        top: "30%",
        left: "50%",
        transform: " translate(-50%, -30%)",
      }}
    >
      <Stack sx={{ mb: 6 }} alignItems="center" gap={1} width={"100%"}>
        <img style={{ width: 300 }} src={theme.logo || defaultLogo} />
        <CrmTextIcon width={300} height={16} />
      </Stack>
      {/* --- FORGOT PASSWORD FORM --- */}

      <Stack
        alignItems="center"
        boxShadow="0px 0px 12px rgba(0, 0, 0, 0.1)"
        sx={{
          minWidth: "400px",
          backgroundColor: "extra.white",
          borderRadius: 2,
          p: 2,
        }}
      >
        <Typography variant="h1" color="text.main" pt={5} pb={1.5}>
          {t("auth.forgotTitle")}
        </Typography>
        <Typography varian="body2" color="text.main" mb={4}>
          {t("auth.forgotSubTitle")}
        </Typography>

        <Box component={Form}>
          <Stack direction="column">
            <FormikTextField
              variant="outlined"
              hiddenLabel
              name="email"
              placeholder={t("base.placeholders.email")}
              autoComplete="email"
            />
          </Stack>
          <Stack justifyContent="center" alignItems="center" flexDirection="column" sx={{ p: 0, mt: 3 }}>
            <GradientButton variant="contained" type="submit" disabled={isSubmitting || !values.email}>
              {t("base.buttons.resetPassword")}
            </GradientButton>

            <Typography p="32px 0" color="text.dark">
              {t("auth.backTo")}{" "}
              <Link
                component={RouterLink}
                to="/login"
                underline="hover"
                sx={{
                  color: "primary.main",
                  fontFamily: "inherit",
                }}
              >
                {t("auth.loginLink")}
              </Link>
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: () => ({
      email: "",
    }),
    validationSchema: forgotPasswordSchema,
    handleSubmit: (data, helpers) => {
      store.dispatch(userThunks.forgotPassword(data));
      helpers.setSubmitting(false);
      helpers.resetForm();
    },
  })(ForgotPassword)
);
