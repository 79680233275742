import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import loadDataInitialState from "store/utils/loadDataInitialState";
import loadDataExtraReducer from "store/utils/loadDataExtraReducer";
import loadDataPaginatedState from "store/utils/loadDataPaginatedState";

const contact = createSlice({
  name: "contactSlice",
  initialState: {
    contacts: loadDataPaginatedState(),
    contact: loadDataInitialState(null),
  },
  reducers: {
    addItem(state, action) {
      state[action.payload.collection].data.push(action.payload.data);
    },
    replaceItem(state, action) {
      const index = state[action.payload.collection].data.content.findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data[index] = action.payload.data;
    },
    updateItem(state, action) {
      state[action.payload.collection].data = action.payload.data;
    },
    deleteItem(state, action) {
      const index = state[action.payload.collection].data.findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchContacts, "contacts");
    loadDataExtraReducer(builder, thunks.fetchContact, "contact");
  },
});

export const { addItem, replaceItem, updateItem, deleteItem } = contact.actions;
export default contact.reducer;
