import { Fragment, memo, useEffect, useMemo, useRef, useState } from "react";
import { DateTime } from "luxon";
import i18n from "i18n";
import { useTranslation } from "react-i18next";

import { Stack, Divider, Chip, Typography, useMediaQuery, IconButton } from "@mui/material";
import { AddCircleOutlineOutlined } from "@mui/icons-material";

import LeadInteractionTaskItem from "./LeadInteractionTaskItem";
import InteractionActions from "./InteractionActions";
import InteractionsFilter from "./InteractionsFilter";
import InteractionActionsMobile from "./InteractionActionsMobile";
import InteractionItem from "./InteractionItem";
import InteractionItemMobile from "./InteractionItemMobile";
import LeadInteractionTaskItemMobile from "./LeadInteractionTaskItemMobile";
import { MOBILE_VIEW } from "utils/constants";

const LeadInteractions = ({ lead }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const [isShownMobileActionsBar, setIsShownMobileActionsBar] = useState(false);

  const [filter, setFilter] = useState({
    TASK: false,
    COMMENT: false,
    FIELD_CHANGED: false,
    STAGE_CHANGED: false,
    PIPELINE_CHANGED: false,
    STATUS_CHANGED: false,
    PHONE_CALL_IN: false,
    PHONE_CALL_OUT: false,
    from: null,
    to: null,
  });

  const handleFilterChange = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSelectAll = () => {
    setFilter({
      ...filter,
      TASK: true,
      COMMENT: true,
      FIELD_CHANGED: true,
      STAGE_CHANGED: true,
      PIPELINE_CHANGED: true,
      STATUS_CHANGED: true,
      PHONE_CALL_IN: true,
      PHONE_CALL_OUT: true,
    });
  };

  const handleClearAll = () => {
    setFilter({
      TASK: false,
      COMMENT: false,
      FIELD_CHANGED: false,
      STAGE_CHANGED: false,
      PIPELINE_CHANGED: false,
      STATUS_CHANGED: false,
      PHONE_CALL_IN: false,
      PHONE_CALL_OUT: false,
      from: null,
      to: null,
    });
  };

  const handleDateFrom = (value) => {
    setFilter({
      ...filter,
      from: value,
    });
  };
  const handleDateTo = (value) => {
    setFilter({
      ...filter,
      to: value,
    });
  };

  const interactionsEndRef = useRef(null);

  const extractMonthAndYear = (dateStr) => {
    const date = DateTime.fromISO(dateStr);
    const monthName = date.setLocale(i18n.language === "UA" ? "UK" : i18n.language).toFormat("MMMM");
    const year = date.year;
    return { monthName, year };
  };

  const tasksWithTypeField = useMemo(() => {
    return lead?.tasks.length > 0 ? lead.tasks.map((task) => ({ ...task, fieldType: "TASK" })) : [];
  }, [lead]);

  const concatTasksAndInteractions = useMemo(() => {
    return [
      ...lead.interactions.map((item) => ({
        ...item,
        fieldType: item.type,
      })),
      ...tasksWithTypeField,
    ];
  }, [lead.interactions, tasksWithTypeField]);

  const sortedTasksAndInteractionsByCreatedAt = concatTasksAndInteractions.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateA - dateB;
  });

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(sortedTasksAndInteractionsByCreatedAt);
  }, [sortedTasksAndInteractionsByCreatedAt]);

  const filterItems = () => {
    const filteredItems = sortedTasksAndInteractionsByCreatedAt.filter((item) => {
      const createdAt = new Date(item.createdAt);
      const fromDate = filter.from ? new Date(filter.from) : null;
      const toDate = filter.to ? new Date(filter.to) : null;

      const isDateInRange =
        (!fromDate || createdAt >= fromDate) && (!toDate || createdAt <= new Date(toDate.setHours(23, 59, 59, 999)));

      const isTypeFiltered = filter[item.fieldType];

      if (isDateInRange && isTypeFiltered) {
        return true;
      } else if (isDateInRange && !Object.values(filter).some((val) => val === true)) {
        return true;
      } else if (isTypeFiltered && (!fromDate || !toDate)) {
        return true;
      } else {
        return false;
      }
    });

    return filteredItems;
  };

  const handleApplyFilter = () => {
    const isFilterApplied =
      Object.values(filter).some((item) => item === true) || filter.from !== null || filter.to !== null;

    const filteredItems = filterItems();

    if (isFilterApplied) {
      setFilteredData(filteredItems);
    } else setFilteredData(sortedTasksAndInteractionsByCreatedAt);
  };

  const groupedData = filteredData?.reduce((acc, item) => {
    const { monthName, year } = extractMonthAndYear(item.createdAt);
    const key = `${monthName} ${year}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  const scrollToBottom = () => {
    if (interactionsEndRef.current) {
      interactionsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (interactionsEndRef.current) {
      scrollToBottom();
    }
  }, [filteredData]);

  return (
    <Stack height="100%" justifyContent="space-between" p={2} width="100%" position="relative">
      {isMobile && (
        <>
          <IconButton
            sx={{ color: "primary.main", position: "absolute", top: 8, left: 0, zIndex: 2 }}
            onClick={() => setIsShownMobileActionsBar(true)}
          >
            <AddCircleOutlineOutlined />
          </IconButton>
          <InteractionsFilter
            handleFilter={handleFilterChange}
            filter={filter}
            handleSelectAll={handleSelectAll}
            handleClearAll={handleClearAll}
            handleDateFrom={handleDateFrom}
            handleDateTo={handleDateTo}
            handleApplyFilter={handleApplyFilter}
          />
        </>
      )}
      {!isMobile && (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={isMobile ? "space-between" : "center"}
            marginBottom={1}
          >
            <Typography color="primary.main" sx={{ flex: 1, textAlign: "center", fontWeight: 700 }}>
              {t("types.lead.interactionsMessages.history")}
            </Typography>

            <InteractionsFilter
              handleFilter={handleFilterChange}
              filter={filter}
              handleSelectAll={handleSelectAll}
              handleClearAll={handleClearAll}
              handleDateFrom={handleDateFrom}
              handleDateTo={handleDateTo}
              handleApplyFilter={handleApplyFilter}
            />
          </Stack>
          <Divider sx={{ width: "100%", borderColor: "secondary.light", mb: 1 }} />
        </>
      )}
      <Stack height="100%" sx={{ overflowY: "auto", overflowX: "hidden" }} width="100%">
        {Object.keys(groupedData).map((key) => (
          <Fragment key={key}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              gap={1}
              mb={2}
              sx={{
                ...(isMobile && {
                  position: "sticky",
                  top: 0,
                  backgroundColor: "background.paper",
                  zIndex: 1,
                  pb: 1,
                }),
              }}
            >
              <Divider sx={{ width: isMobile ? "30%" : "40%" }} />
              <Chip
                variant="outlined"
                label={key}
                sx={{
                  borderColor: "primary.lighter",
                  color: "text.main",
                  height: 24,
                  "&.MuiChip-label": { color: "text.main" },
                }}
              />
              <Divider sx={{ width: isMobile ? "30%" : "40%" }} />
            </Stack>
            <Stack gap={3}>
              {groupedData[key].map((item) => {
                if (item.fieldType === "TASK") {
                  return isMobile ? (
                    <LeadInteractionTaskItemMobile key={item.id} item={item} lead={lead} />
                  ) : (
                    <LeadInteractionTaskItem key={item.id} item={item} lead={lead} />
                  );
                } else
                  return isMobile ? (
                    <InteractionItemMobile key={item.id} item={item} lead={lead} />
                  ) : (
                    <InteractionItem key={item.id} item={item} lead={lead} />
                  );
              })}
              <div ref={interactionsEndRef} />
            </Stack>
          </Fragment>
        ))}
      </Stack>
      {isMobile ? (
        isShownMobileActionsBar && (
          <InteractionActionsMobile lead={lead} setIsShownMobileActionsBar={setIsShownMobileActionsBar} />
        )
      ) : (
        <InteractionActions lead={lead} />
      )}
    </Stack>
  );
};

export default memo(LeadInteractions);
