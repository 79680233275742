import { memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import { useMediaQuery } from "@mui/material";

import store from "store";
import { leadSelectors, leadThunks } from "store/ducks/lead";

import api from "services/api";

import HeaderPage from "views/common/HeaderPage";
import Error from "views/common/Error";
import LeadsDataTable from "views/common/LeadsDataTable";

import { DESKTOP_VIEW } from "utils/constants";
import { isFilterFieldFilled, saveAsExcelFile } from "utils/helpers";
import { tableSelectors } from "store/ducks/table";
import HeaderPageMobile from "views/common/HeaderPageMobile";

const LeadsList = ({
  pipelinesList,
  selectedPipeline,
  setSelectedPipeline,
  pageView,
  togglePageView,
  setPageView,
  createNewItemAction,
}) => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const { t } = useTranslation();

  const leads = useSelector(leadSelectors.getLeads());
  const leadsLoading = useSelector(leadSelectors.leadsLoading());

  const selectedLeads = useSelector(tableSelectors.getSelected());

  const initialFilterValues = {
    createdAtBefore: null,
    createdAtAfter: null,
    programs: [],
    responsible: [],
    meetingDateBefore: null,
    meetingDateAfter: null,
    contractDateBefore: null,
    contractDateAfter: null,
    closeDateBefore: null,
    closeDateAfter: null,
    closeReasons: [],
    channels: [],
    pipelines: [],
    stages: [],
    statuses: [],
  };
  const [filter, setFilter] = useState(initialFilterValues);
  const [appliedFilter, setAppliedFilter] = useState(initialFilterValues);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get("page") || 0);
  const pageSizeQuery = parseInt(searchParams.get("size") || 15);
  const searchQuery = searchParams.get("q") || "";
  const sortByQuery = searchParams.get("sortBy") || "";
  const sortDirectionQuery = searchParams.get("direction") || "";

  const isFilterFilled = isFilterFieldFilled(filter);

  useEffect(() => {
    if (isFilterApplied) {
      store.dispatch(leadThunks.filteredLeads({ query: `?${query}`, body: appliedFilter }));
    } else query && store.dispatch(leadThunks.fetchLeads(`?${query}`));
  }, [query, appliedFilter, isFilterApplied]);

  const handleApplyFilter = () => {
    if (!isFilterFilled) {
      setIsFilterApplied(false);
      setAppliedFilter(initialFilterValues);
    }
    setIsFilterApplied(true);
    setAppliedFilter(filter);
  };

  const handleExportToExcel = async () => {
    const today = DateTime.local().startOf("day");
    const response = await api.post(`/leads/filtered/export`, appliedFilter, {}, { responseType: "blob", raw: true });
    saveAsExcelFile(response, `leads-${today.toFormat("dd.MM.yyyy")}.xlsx`);
  };

  return (
    <>
      {isDesktop ? (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPage
            pipelinesList={pipelinesList}
            selectedPipeline={selectedPipeline}
            setSelectedPipeline={setSelectedPipeline}
            isSearchbarShow={!isFilterFieldFilled(filter)}
            pageView={pageView}
            togglePageView={togglePageView}
            setPageView={setPageView}
            createNewItemBtnTitle={"+ " + t("base.buttons.createLead")}
            createNewItemAction={createNewItemAction}
            allLeadsFilter={filter}
            handleAllLeadsFilter={setFilter}
            initialAllLeadsFilterValues={initialFilterValues}
            handleApplyAllLeadsFilter={handleApplyFilter}
            handleExportToExcel={handleExportToExcel}
            setIsAllLeadsFilterApplied={setIsFilterApplied}
            isAllLeadsFilterApplied={isFilterApplied}
            query={query}
            appliedAllLeadsFilter={appliedFilter}
            setAppliedAllLeadsFilter={setAppliedFilter}
            selectedLeads={selectedLeads}
          />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPageMobile
            pipelinesList={pipelinesList}
            selectedPipeline={selectedPipeline}
            setSelectedPipeline={setSelectedPipeline}
            isSearchbarShow={!isFilterFieldFilled(filter)}
            pageView={pageView}
            togglePageView={togglePageView}
            setPageView={setPageView}
            createNewItemAction={createNewItemAction}
            allLeadsFilter={filter}
            handleAllLeadsFilter={setFilter}
            initialAllLeadsFilterValues={initialFilterValues}
            handleApplyAllLeadsFilter={handleApplyFilter}
            handleExportToExcel={handleExportToExcel}
            setIsAllLeadsFilterApplied={setIsFilterApplied}
            isAllLeadsFilterApplied={isFilterApplied}
            query={query}
            appliedAllLeadsFilter={appliedFilter}
            setAppliedAllLeadsFilter={setAppliedFilter}
            selectedLeads={selectedLeads}
          />
        </ErrorBoundary>
      )}
      <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
        <LeadsDataTable
          list={leads.content}
          totalElements={leads.totalElements}
          isLoading={leadsLoading}
          totalPages={leads.totalPages}
          setSearchParams={setSearchParams}
          pageQuery={pageQuery}
          pageSizeQuery={pageSizeQuery}
          searchQuery={searchQuery}
          sortByQuery={sortByQuery}
          sortDirectionQuery={sortDirectionQuery}
        />
      </ErrorBoundary>
    </>
  );
};

export default memo(LeadsList);
