import { Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DownIcon, UpIcon } from "theme/icons";
import { MOBILE_VIEW } from "utils/constants";

const IndicatorCard = ({ title, amount, percentageChange }) => {
  const isIncrease = percentageChange > 0;
  const isMobile = useMediaQuery(MOBILE_VIEW);

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      gap={isMobile ? 1 : 3}
      sx={{ backgroundColor: "extra.white", borderRadius: 2, px: 2, py: 1 }}
      width="100%"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <Typography variant="h6" color="text.main">
          {title}
        </Typography>
        <MoreVertIcon color="text.main" />
      </Stack>
      <Typography variant={isMobile ? "h2" : "h1"} color="primary.main">
        {amount}
      </Typography>
      <Stack width="100%" gap={1}>
        <Divider sx={{ width: "100%" }} />
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <Typography color="text.light">12.03.2024</Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="body1" color={isIncrease ? "success.main" : "error.main"}>
              {isIncrease && "+"}
              {percentageChange}%
            </Typography>
            {isIncrease ? <UpIcon /> : <DownIcon />}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default IndicatorCard;
