import { memo } from "react";
import { Form, withFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { CircularProgress, IconButton, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";

import store from "store";
import { dictionarySelectors } from "store/ducks/dictionary";
import { leadActions } from "store/ducks/lead";
import { taskThunks } from "store/ducks/task";

import { CloseIcon, DoneIcon } from "theme/icons";
import { INTERACTION_TYPES_ICONS, MOBILE_VIEW } from "utils/constants";
import { getShortName } from "utils/helpers";
import { taskSchema } from "utils/schema";
import FormikAutocomplete from "views/form/FormikAutocomplete";
import FormikDatePicker from "views/form/FormikDatePicker";
import FormikTextField from "views/form/FormikTextField";
import FormikTimePicker from "views/form/FormikTimePicker";

const LeadInteractionsTaskForm = ({ item, values, isSubmitting, disableEditMode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const enums = useSelector(dictionarySelectors.getEnums());
  const { taskStatuses: statuses } = enums;
  const employees = useSelector(dictionarySelectors.getEmployees());
  const employeesLoading = useSelector(dictionarySelectors.employeesLoading());

  const taskTypes = useSelector(dictionarySelectors.getTaskTypes());
  const taskTypesLoading = useSelector(dictionarySelectors.taskTypesLoading());
  const isMobile = useMediaQuery(MOBILE_VIEW);

  return (
    <Form id="taskForm" style={{ height: "inherit" }}>
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack>{INTERACTION_TYPES_ICONS[item.fieldType] || null}</Stack>
        <FormikDatePicker
          name="dueDate"
          sx={{ minWidth: 100, ...(isMobile && { width: "100%" }), "& input": { fontSize: 12 } }}
        />
        <FormikTimePicker
          name="dueTime"
          sx={{ minWidth: isMobile ? 120 : 68, "& input": { fontSize: 12 } }}
          useTimezone
          date={values.dueDate}
          timeZone={values.timezone}
        />
        {!isMobile && (
          <>
            <FormikAutocomplete
              name="type"
              options={taskTypes || []}
              loading={taskTypesLoading}
              sx={{ width: "100%" }}
              fontSize={12}
            />
            <FormikAutocomplete name="status" options={statuses || []} sx={{ minWidth: 120 }} fontSize={12} />
          </>
        )}
      </Stack>
      {isMobile && (
        <Stack direction="row" alignItems="center" gap={2} width="100%">
          <FormikAutocomplete
            name="type"
            options={taskTypes || []}
            loading={taskTypesLoading}
            sx={{ width: "100%" }}
            fontSize={12}
          />
          <FormikAutocomplete name="status" options={statuses || []} sx={{ minWidth: 120 }} fontSize={12} />
        </Stack>
      )}
      <Stack direction="row" width="100%" alignItems="flex-end" gap={1}>
        <Stack width="100%" gap={1} mt={1}>
          <Stack direction="row" width="100%" gap={2}>
            <FormikAutocomplete
              name="responsible"
              getCustomLabel={getShortName}
              options={employees}
              loading={employeesLoading}
              fontSize={12}
              sx={{ width: "100%" }}
              multiple
            />
            {!isMobile && (
              <FormikTextField
                name="name"
                placeholder={t("base.placeholders.name")}
                sx={{ "& input": { fontSize: 12 }, width: "100%" }}
              />
            )}
          </Stack>
          {isMobile && (
            <FormikTextField
              name="name"
              placeholder={t("base.placeholders.name")}
              sx={{ "& input": { fontSize: 12 }, width: "100%" }}
            />
          )}
          <FormikTextField
            name="description"
            placeholder={t("base.placeholders.description")}
            sx={{ "& input": { fontSize: 12 } }}
          />
        </Stack>
        <Stack direction="row" gap={0.5}>
          <Tooltip title={t("base.tooltips.save")}>
            <IconButton disabled={isSubmitting} type="submit" form="taskForm" sx={{ p: 0 }}>
              {isSubmitting ? <CircularProgress size={18} /> : <DoneIcon fill={theme.palette.success.main} />}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("base.tooltips.cancel")}>
            <IconButton onClick={disableEditMode} sx={{ p: 0 }}>
              <CloseIcon fill={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ item = {}, leads }) => ({
      ...item,
      id: item?.id,
      author: item?.author,
      name: item?.name || "",
      description: item?.description || "",
      responsible: item?.responsible || [],
      status: item?.status || null,
      createdAt: item?.createdAt,
      dueDate: item?.dueDate || null,
      dueTime: item?.dueTime || null,
      files: item?.files || [],
      leads: leads,
      timezone: item?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      type: item?.type || null,
    }),
    validationSchema: taskSchema,
    handleSubmit: async (values, { props, setSubmitting }) => {
      const { fieldType, ...rest } = values;
      const response = await store.dispatch(taskThunks.updateTask(rest));

      if (!response.error) {
        toast.success(
          props.item ? props.t("messages.success.toast.updateTask") : props.t("messages.success.toast.createTask")
        );
        if (response.meta.requestStatus === "fulfilled") {
          const taskIndex = props.lead.tasks.findIndex((i) => i.id === values.id);
          const { leads, ...rest } = response.payload;

          const updatedTasks = [...props.lead.tasks];
          updatedTasks[taskIndex] = rest;

          store.dispatch(leadActions.updateItem({ collection: "lead", data: { ...props.lead, tasks: updatedTasks } }));
        }

        setSubmitting(false);
        props.disableEditMode();
      }
    },
    enableReinitialize: true,
  })(LeadInteractionsTaskForm)
);
