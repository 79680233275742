import { MOBILE_VIEW } from "utils/constants";
import IndicatorCard from "./IndicatorCard";
import { Stack, useMediaQuery } from "@mui/material";

const Indicators = () => {
  //TODO: errorBoundary, translation, list

  const isMobile = useMediaQuery(MOBILE_VIEW);
  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      alignItems="center"
      justifyContent="space-between"
      gap={isMobile ? 1 : 2}
      width="100%"
    >
      <IndicatorCard title="New leads" amount="234" percentageChange="17" />
      <IndicatorCard title="Lost leads" amount="74" percentageChange="-25" />
      <IndicatorCard title="New contracts" amount="135" percentageChange="37" />
      <IndicatorCard title="Another metric" amount="432" percentageChange="-3" />
    </Stack>
  );
};

export default Indicators;
