import { memo } from "react";
import { useTranslation } from "react-i18next";

import { Box, IconButton, Tooltip } from "@mui/material";
import { AddCircleOutlineOutlined } from "@mui/icons-material";

const AddRemoveInputAdornment = ({ name, add }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Tooltip title={t("base.tooltips.addField") + " " + name}>
        <IconButton sx={{ p: 0.25, color: "primary.main" }} onClick={add}>
          <AddCircleOutlineOutlined />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default memo(AddRemoveInputAdornment);
