import { memo } from 'react';
import { defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone';
import { AsYouType } from 'libphonenumber-js';

import { useField } from 'formik';
import { TextField, Typography, MenuItem, Select, InputAdornment } from '@mui/material';
// import { usePermissionContext } from 'services/context/permissionContext';
import "react-international-phone/style.css";

const FormikPhone = ({ name, disableError, adornmentProps, value, readOnly, ...rest }) => {
  const [field, meta, state] = useField(name);
//TODO: permission? 
  // const hasPermission = usePermissionContext();
  
  const { handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'ua',
    value,
    countries: defaultCountries,
    onChange: (data) => {
      const unformattedPhone = `+${data.phone.replace(/\D/g, '')}`;
      state.setValue(unformattedPhone);
    },
  });

  return (
    <TextField
      {...field}
      {...rest}
      value={new AsYouType(country).input(field.value)}
      InputLabelProps={{ shrink: true }}
      autoComplete="off"
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      error={!disableError && !!meta.error && meta.touched}
      helperText={!disableError && !!meta.error && meta.touched && meta.error}
      InputProps={{
        readOnly: 
        // !hasPermission || 
        readOnly,
        startAdornment: (
          <InputAdornment position="start" style={{ marginLeft: '-14px' }}>
            <Select
              style={{ backgroundColor: 'transparent' }}
              MenuProps={{
                style: {
                  height: '300px',
                  width: '100%',
                  top: '10px',
                  left: '-34px',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              sx={{
                width: 'max-content',
                /** --- Remove default outline (display only on focus) ---  */
                fieldset: {
                  display: 'none',
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: 'none',
                  },
                },
                /** --- Update default spacing ---  */
                '.MuiSelect-select': {
                  paddingRight: '24px !important',
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value)}
              renderValue={(value) => <FlagImage iso2={value} style={{ display: 'flex' }} />}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2} sx={{ backgroundColor: 'extra.white', my: 0.5 }}>
                    <FlagImage iso2={country.iso2} style={{ marginRight: 8 }} />
                    <Typography marginRight={1}>{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
        endAdornment: adornmentProps,
      }}
    />
  );
};

export default memo(FormikPhone);
