import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import loadDataInitialState from "store/utils/loadDataInitialState";
import loadDataExtraReducer from "store/utils/loadDataExtraReducer";

const uploadSlice = createSlice({
  name: "uploadSlice",
  initialState: {
    image: loadDataInitialState(""),
    fileId: loadDataInitialState("", false),
  },
  reducers: {
    clearImage(state) {
      state.image = loadDataInitialState("");
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.uploadImage, "image");
    loadDataExtraReducer(builder, thunks.uploadFile, "fileId");
  },
});

export const { clearImage, clearItem } = uploadSlice.actions;
export default uploadSlice.reducer;
