import { memo } from "react";
import { useSelector } from "react-redux";
import { withFormik, Form } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Stack, CircularProgress, Button } from "@mui/material";

import store from "store";
import { leadActions, leadThunks } from "store/ducks/lead";
import { pipelineActions } from "store/ducks/pipeline";
import { dictionarySelectors } from "store/ducks/dictionary";

import { FormColumnWrapper } from "views/common/styledComponents";
import FormikAutocomplete from "views/form/FormikAutocomplete";
import FormikTextField from "views/form/FormikTextField";
import { closeLeadSchema } from "utils/schema";

const CloseLeadForm = ({ isSubmitting, closeModal }) => {
  const { t } = useTranslation();

  const closeReasons = useSelector(dictionarySelectors.getLeadCloseReasons());

  return (
    <Form>
      <FormColumnWrapper sx={{ p: 0 }}>
        <FormikAutocomplete name="closeReason" label={t("base.labels.reasonOfClosing")} options={closeReasons} />
        <FormikTextField name="comment" label={t("base.labels.comment")} placeholder={t("base.placeholders.comment")} />
        <Stack direction="row" alignItems="center" gap={2} mt={3} mb={1}>
          <Button onClick={closeModal} sx={{ minWidth: 150 }}>
            {t("base.buttons.cancel")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            sx={{ minWidth: 150 }}
            startIcon={isSubmitting && <CircularProgress size={16} sx={{ mr: 1, color: "extra.white" }} />}
          >
            {isSubmitting ? t("base.buttons.saving") : t("types.lead.closeLead")}
          </Button>
        </Stack>
      </FormColumnWrapper>
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ lead = {}, closeLeadStatus }) => ({
      id: lead?.id,
      closeReason: lead?.closeReason || null,
      comment: lead?.comment || "",
      status: closeLeadStatus,
    }),
    validationSchema: closeLeadSchema,
    handleSubmit: async (values, { props }) => {
      const res = await store.dispatch(leadThunks.closeLead(values));

      if (!res.error) {
        toast.success(props.t("messages.success.toast.closeLead"));
        props.closeModal();

        props.actionToUpdateCollection === "lead" &&
          store.dispatch(leadActions.updateItem({ collection: "lead", data: res.payload }));

        props.actionToUpdateCollection === "stagesLeads" &&
          store.dispatch(
            pipelineActions.deleteLead({ collection: "stagesLeads", stageId: props.lead.stage.id, data: res.payload })
          );
      }
    },
    enableReinitialize: true,
  })(CloseLeadForm)
);
