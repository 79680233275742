import { memo, useEffect, useState } from "react";
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import Error from "views/common/Error";
import HeaderPage from "views/common/HeaderPage";
import { PageWrapper } from "views/common/styledComponents";

import { DESKTOP_VIEW } from "utils/constants";
import { getDataFromLS } from "utils/helpers";
import { PipelinesCol, PipelinesList } from "./components";
import { useSelector } from "react-redux";
import { pipelineSelectors, pipelineThunks } from "store/ducks/pipeline";

import store from "store";
import HeaderPageMobile from "views/common/HeaderPageMobile";

const PipelinesPage = () => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pipelines = useSelector(pipelineSelectors.getPipelines());
  const loading = useSelector(pipelineSelectors.pipelinesLoading());

  const [searchQuery, setSearchQuery] = useState("");

  const pipelinesView = getDataFromLS("pipelinesView");
  const [pageView, setPageView] = useState(pipelinesView || "list");

  const createNewPipeline = () => {
    navigate("/pipelines/new");
  };

  useEffect(() => {
    localStorage.setItem("pipelinesView", JSON.stringify(pageView));
  }, [pageView]);

  useEffect(() => {
    store.dispatch(pipelineThunks.fetchPipelines());
  }, []);

  const togglePageView = () => {
    pageView === "list" ? setPageView("col") : setPageView("list");
  };

  const filteredPipelines = pipelines?.filter((pipeline) =>
    pipeline.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <PageWrapper>
      {isDesktop ? (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPage
            title={t("types.pipeline.namePlural")}
            isSearchbarShow
            createNewItemAction={createNewPipeline}
            createNewItemBtnTitle={"+ " + t("base.buttons.createPipeline")}
            pageView={pageView}
            togglePageView={togglePageView}
            onSearch={setSearchQuery}
          />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPageMobile
            isSearchbarShow
            createNewItemAction={createNewPipeline}
            pageView={pageView}
            togglePageView={togglePageView}
            onSearch={setSearchQuery}
          />
        </ErrorBoundary>
      )}
      {pageView === "list" ? (
        <PipelinesList pipelines={filteredPipelines} loading={loading} />
      ) : (
        <PipelinesCol pipelines={filteredPipelines} loading={loading} />
      )}
    </PageWrapper>
  );
};

export default memo(PipelinesPage);
