import { memo } from "react";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "@sentry/react";

import PipeLineCard from "./PipeLineCard";
import Loader from "views/common/Loader";
import Error from "views/common/Error";
import { MOBILE_VIEW } from "utils/constants";

const PipelinesCol = ({ pipelines, loading }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(MOBILE_VIEW);

  return (
    <Box
      sx={{
        p: isMobile ? 1 : 2,
        backgroundColor: "secondary.lighter",
        borderRadius: 2,
        height: "100%",
        overflowY: "auto",
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {pipelines.length === 0 && (
            <Stack alignItems="center" justifyContent="center" height="100%">
              <Typography variant="h3" color="text.dark">
                {t("messages.warning.emptyList")}
              </Typography>
              <Typography variant="h6" color="text.dark">
                {t("messages.warning.createFirst")} {t("types.pipeline.name").toLowerCase()}
              </Typography>
            </Stack>
          )}
          <Box
            display="grid"
            gridTemplateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)"}
            gap={isMobile ? 1 : 2}
          >
            {pipelines.map((pipeline) => (
              <ErrorBoundary key={pipeline.id} fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
                <PipeLineCard key={pipeline.id} pipeline={pipeline} />
              </ErrorBoundary>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default memo(PipelinesCol);
