import { memo } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { CalendarIcon } from "theme/icons";
import filters from "filters";

const CustomDatePicker = ({ label, value, onChange, width }) => {
  const { t } = useTranslation();

  return (
    <DatePicker
      format="dd.MM.yyyy"
      label={label}
      value={value ? DateTime.fromISO(value) : null}
      onChange={(newValue) => onChange(filters.date(newValue?.ts, "yyyy-MM-dd") || null)}
      slots={{
        openPickerIcon: CalendarIcon,
      }}
      sx={{ width: width, minWidth: 120, "& input": { fontSize: 16 } }}
      slotProps={{
        textField: {
          placeholder: t("base.placeholders.dateFormat"),
          InputLabelProps: { shrink: true },
        },
      }}
    />
  );
};

export default memo(CustomDatePicker);
