import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userSelectors } from "store/ducks/user";
import { dictionaryThunks } from "store/ducks/dictionary";
import { useTranslation } from "react-i18next";

const AppDataContainer = ({ children }) => {
  const user = useSelector(userSelectors.getCurrentUser());
  const dispatch = useDispatch();

  const { i18n } = useTranslation();

  const currentLang = i18n.language;
  const userLanguage = user?.language.name.toLowerCase();

  useEffect(() => {
    if (!userLanguage && !currentLang) {
      i18n.changeLanguage("en");
      return;
    }

    if (currentLang !== userLanguage) {
      i18n.changeLanguage(userLanguage);
      return;
    }
  }, [userLanguage, currentLang, i18n]);

  useEffect(() => {
    if (user?.id) {
      dispatch(dictionaryThunks.fetchEnums());
      dispatch(dictionaryThunks.fetchPrograms());
      dispatch(dictionaryThunks.fetchManagers());
      dispatch(dictionaryThunks.fetchCountries());
      dispatch(dictionaryThunks.fetchCities());
      dispatch(dictionaryThunks.fetchLeadCloseReasons());
      dispatch(dictionaryThunks.fetchMarketingChannels());
      dispatch(dictionaryThunks.fetchProfessions());
      dispatch(dictionaryThunks.fetchEducations());
      dispatch(dictionaryThunks.fetchUniversities());
      dispatch(dictionaryThunks.fetchEmployees());
      dispatch(dictionaryThunks.fetchTaskTypes());
    }
  }, [dispatch, user?.id]);

  return children;
};

export default memo(AppDataContainer);
