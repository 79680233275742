import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { dictionarySelectors } from "store/ducks/dictionary";

import Modal from "views/common/modal/Modal";
import CloseLeadForm from "./CloseLeadForm";
import useModal from "hooks/useModal";
import { AttentionIcon } from "theme/icons";

const MenuLeadCard = ({ lead }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const enums = useSelector(dictionarySelectors.getEnums());
  const closeLeadStatus = enums.activeStatuses?.find((status) => status.name === "ARCHIVED");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [modalOpen, openModal, closeModal] = useModal();

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenLead = () => {
    navigate(`/leads/${lead?.id}`);
  };

  const handleCloseLead = () => {
    openModal();
    handleClose();
  };

  return (
    <>
      <IconButton
        id="lead"
        aria-controls={open ? "lead-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClickOpen}
        sx={{ p: 0.5 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="lead-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lead",
          role: "listbox",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            px: 1,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          sx={{
            color: "text.main",
            transition: "color 0.3s, backgroundColor 0.3s",
            borderRadius: 1,
            "&:hover": {
              color: "primary.main",
              backgroundColor: "primary.lighter",
            },
          }}
          onClick={handleOpenLead}
        >
          <Typography>{t("types.lead.openLead")}</Typography>
        </MenuItem>
        <Divider sx={{ width: "100%" }} />
        <MenuItem
          sx={{
            color: "error.main",
            transition: "color 0.3s, backgroundColor 0.3s",
            borderRadius: 1,
            "&:hover": {
              color: "extra.white",
              backgroundColor: "error.light",
            },
          }}
          onClick={handleCloseLead}
        >
          <Typography>{t("types.lead.closeLead")}</Typography>
        </MenuItem>
      </Menu>
      <Modal
        title={t("types.lead.closeLead") + `: "${lead.name}"`}
        icon={<AttentionIcon />}
        open={modalOpen}
        onClose={closeModal}
      >
        <CloseLeadForm
          closeModal={closeModal}
          lead={lead}
          t={t}
          closeLeadStatus={closeLeadStatus}
          actionToUpdateCollection={"stagesLeads"}
        />
      </Modal>
    </>
  );
};

export default memo(MenuLeadCard);
