import { memo, useCallback, useEffect, useRef, useState } from "react";

import { Typography, Slider, IconButton, Stack, useMediaQuery } from "@mui/material";

import api from "services/api";

import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import VolumeUpRounded from "@mui/icons-material/VolumeUpRounded";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Loader from "views/common/Loader";
import { MOBILE_VIEW } from "utils/constants";

const Player = ({ item }) => {
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const [volume, setVolume] = useState(80);
  const [muteVolume, setMuteVolume] = useState(false);

  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);

  const [record, setRecord] = useState("");
  const [recordLoading, setRecordLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const audioRef = useRef();
  const intervalRef = useRef();

  const updateProgress = useCallback(() => {
    if (audioRef.current) {
      setTimeProgress(audioRef.current.currentTime);
    }
  }, []);

  const fetchRecord = async (id) => {
    setRecordLoading(true);
    const response = await api.get(`/binotel/call-record/${id}`);
    setRecordLoading(false);

    if (!response.error) {
      setRecord(response);
    } else setHasError(true);
  };

  const togglePlayPause = async () => {
    if (!record && !recordLoading) {
      await fetchRecord(item.id);
    }

    if (hasError) {
      return;
    } else setIsPlaying((prev) => !prev);
  };

  useEffect(() => {
    if (isPlaying && record) {
      audioRef.current?.play();
      intervalRef.current = setInterval(updateProgress, 100);
    } else {
      audioRef.current?.pause();
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [isPlaying, record, updateProgress]);

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
  };

  const formatTime = (time) => {
    if (time && !isNaN(time)) {
      const minutes = Math.floor(time / 60);
      const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const seconds = Math.floor(time % 60);
      const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${formatMinutes}:${formatSeconds}`;
    }
    return "00:00";
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
  }, [volume, muteVolume]);

  const handleProgressChange = (event, newValue) => {
    setTimeProgress(newValue);
    audioRef.current.currentTime = newValue;
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: "90%",
      }}
    >
      {recordLoading && <Loader size={18} />}
      {!recordLoading && (
        <IconButton
          aria-label={isPlaying ? "play" : "pause"}
          onClick={togglePlayPause}
          sx={{ color: "primary.main", p: isMobile ? 0 : 0.5 }}
        >
          {!isPlaying ? (
            <PlayArrowRounded sx={{ fontSize: 24 }} htmlColor={"primary.main"} />
          ) : (
            <PauseRounded sx={{ fontSize: 24 }} htmlColor={"primary.main"} />
          )}
        </IconButton>
      )}

      <Stack direction="row" alignItems="center" gap={2} ml={1} width='100%'>
        <Typography variant="capture" color="text.light">
          {formatTime(timeProgress)}
        </Typography>
        <Slider
          aria-label="time-indicator"
          size="small"
          value={timeProgress}
          min={0}
          max={duration}
          onChange={handleProgressChange}
          sx={{
            color: "primary.main",
            width: '100%',
            maxWidth: 160,
            minWidth: 120,
            height: 4,
            "& .MuiSlider-thumb": {
              width: 8,
              height: 8,
              transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
              "&::before": {
                boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
              },
              "&:hover, &.Mui-focusVisible": {
                boxShadow: `0px 0px 0px 4px ${"rgb(0 0 0 / 16%)"}`,
              },
              "&.Mui-active": {
                width: 10,
                height: 10,
              },
            },
            "& .MuiSlider-rail": {
              opacity: 0.28,
            },
          }}
        />
        <Typography variant="capture" color="text.light" mr={2}>
          {formatTime(duration)}
        </Typography>
      </Stack>
      <Stack
        spacing={1}
        direction="row"
        sx={{
          width: "100%",
          px: 1,
        }}
        alignItems="center"
      >
        <IconButton
          sx={{ color: muteVolume || volume === 0 ? "error.main" : "primary.main", p: 0.5 }}
          onClick={() => setMuteVolume((prev) => !prev)}
        >
          {muteVolume || volume === 0 ? (
            <VolumeOffIcon sx={{ fontSize: 22 }} />
          ) : (
            <VolumeUpRounded sx={{ fontSize: 22 }} />
          )}
        </IconButton>
        <Slider
          aria-label="Volume"
          value={volume}
          min={0}
          max={100}
          onChange={(e) => setVolume(e.target.value)}
          sx={{
            minWidth: 60,
            maxWidth: 100,
            color: "primary.main",
            "& .MuiSlider-track": {
              border: "none",
            },
            "& .MuiSlider-thumb": {
              width: 12,
              height: 12,
              backgroundColor: "#fff",
              "&::before": {
                boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
              },
              "&:hover, &.Mui-focusVisible, &.Mui-active": {
                boxShadow: "none",
              },
            },
          }}
        />
      </Stack>
      {record && <audio src={record} ref={audioRef} onLoadedMetadata={onLoadedMetadata} />}
    </Stack>
  );
};

export default memo(Player);
