import { createSlice } from "@reduxjs/toolkit";
import thunks from "./thunks";
import loadDataInitialState from "store/utils/loadDataInitialState";
import loadDataExtraReducer from "store/utils/loadDataExtraReducer";
import loadDataPaginatedState from "store/utils/loadDataPaginatedState";

const lead = createSlice({
  name: "leadSlice",
  initialState: {
    leads: loadDataPaginatedState(),
    lead: loadDataInitialState(null),
    contactLeads: loadDataInitialState([]),
  },
  reducers: {
    addItem(state, action) {
      state[action.payload.collection].data.content.push(action.payload.data);
    },
    replaceItem(state, action) {
      const index = state[action.payload.collection].data.content.findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data[index] = action.payload.data;
    },
    updateItem(state, action) {
      state[action.payload.collection].data = action.payload.data;
    },
    deleteItem(state, action) {
      const index = state[action.payload.collection].data.content.findIndex((i) => i.id === action.payload.data.id);
      state[action.payload.collection].data.content.splice(index, 1);
    },

    /**  --- Add lead to leads list if the lead is out of loaded leads list with pagination.
     *  Just for Autocomplete --- */
    setLeads(state, action) {
      state.leads.data.content = action.payload;
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchLeads, "leads");
    loadDataExtraReducer(builder, thunks.archiveLeads, "leads");
    loadDataExtraReducer(builder, thunks.fetchLead, "lead");
    loadDataExtraReducer(builder, thunks.fetchContactLeads, "contactLeads");
  },
});

export const { addItem, replaceItem, updateItem, deleteItem, setLeads } = lead.actions;
export default lead.reducer;
