import { EditOutlined } from "@mui/icons-material";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";

import filters from "filters";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { INTERACTION_TYPES_ICONS, TASK_PERFORMANCE_DEADLINE_COLOR, TASK_STATUS_COLOR } from "utils/constants";
import { defineTaskStatus, getShortName } from "utils/helpers";
import LeadInteractionsTaskForm from "./LeadInteractionsTaskForm";
import { useSelector } from "react-redux";
import { taskSelectors, taskThunks } from "store/ducks/task";
import store from "store";
import { InProgressIcon } from "theme/icons";

const LeadInteractionTaskItemMobile = ({ item, lead }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [editMode, setEditMode] = useState(false);

  const task = useSelector(taskSelectors.getTask());
  const taskLoading = useSelector(taskSelectors.taskLoading());

  const taskStatus = defineTaskStatus(item?.dueDate);
  const statusColor = TASK_PERFORMANCE_DEADLINE_COLOR(theme)[taskStatus]?.color;

  const enableEditMode = () => {
    setEditMode(true);
  };
  const disableEditMode = () => {
    setEditMode(false);
  };

  useEffect(() => {
    editMode && store.dispatch(taskThunks.fetchTask(item.id));
  }, [item.id, editMode]);

  return (
    <Stack
      key={item.id}
      sx={{
        border: editMode ? "1px dashed" : "1px solid",
        borderColor: "primary.light",
        borderRadius: 1,
        p: 1,
        position: "relative",
        "&:hover": {
          boxShadow: "0px 0px 2px rgba(77, 84, 201, 0.7)",
        },
      }}
    >
      {editMode ? (
        <LeadInteractionsTaskForm
          t={t}
          item={item}
          disableEditMode={disableEditMode}
          lead={lead}
          leads={task?.leads}
          taskLoading={taskLoading}
        />
      ) : (
        <>
          <Stack direction="column" gap={0.5}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" gap={1}>
                {INTERACTION_TYPES_ICONS[item.fieldType] || null}
                <Typography variant="capture" color="text.light" whiteSpace="nowrap">
                  {filters.date(item.createdAt, "dd.MM.yyyy / HH:mm")}
                </Typography>
              </Stack>
              <Typography
                variant="capture"
                fontWeight={600}
                color={TASK_STATUS_COLOR(theme)[item.status.name]}
                marginLeft="auto"
              >
                {item.status.label}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" gap={1}>
                <InProgressIcon fill={statusColor} />
                <Typography variant="capture" color="text.">
                  {filters.date(item.dueDate)} {item.dueTime && `/ ${item.dueTime}`}
                </Typography>
              </Stack>
              <Typography variant="capture" fontWeight={600} color="text.light">
                {item.type?.name}
              </Typography>
            </Stack>
            
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography variant="capture" color="text.light">
                {t("types.lead.interactionsMessages.from")}
              </Typography>
              <Typography variant="capture" color="info.main">
                {getShortName(item.author)}
              </Typography>
              <Typography variant="capture" color="text.light">
                {t("types.lead.interactionsMessages.to")}
              </Typography>
              {item.responsible.map((manager) => (
                <Typography key={manager.id} variant="capture" color="primary.main">
                  {getShortName(manager)}{" "}
                </Typography>
              ))}
            </Stack>
          </Stack>
          <Typography variant="capture" fontWeight={700} color={"text.dark"}>
            {item.name}
          </Typography>
          <Typography variant="body2" color="text.main">
            {item.description}
          </Typography>
          <IconButton
            onClick={enableEditMode}
            sx={{
              position: "absolute",
              right: 8,
              bottom: 8,
              p: 0,
              color: "primary.lighter",
              transition: "color 0.5s",
              "&:hover": { color: "primary.main" },
            }}
          >
            <EditOutlined fontSize="14px" />
          </IconButton>
        </>
      )}
    </Stack>
  );
};

export default memo(LeadInteractionTaskItemMobile);
