import { Button, ListItemButton, ListItemIcon, Stack, Typography, styled } from "@mui/material";

import { DESKTOP_HEIGHT, MOBILE_HEIGHT, TABLET_HEIGHT } from "utils/constants";

export const GradientButton = styled(Button)(({ theme }) => ({
  background: theme.palette.extra.gradient,
  width: "345px",
  fontWeight: 600,
  "&.Mui-disabled": {
    opacity: 0.3,
    pointerEvents: "auto",
    color: theme.palette.extra.white,
  },
}));

export const PageWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  height: DESKTOP_HEIGHT,
  [theme.breakpoints.down("lg")]: {
    height: TABLET_HEIGHT,
  },
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    height: MOBILE_HEIGHT,
  },
}));

export const FormColumnWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.extra.white,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  gap: theme.spacing(1),
}));

export const FormColumnTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
  color: theme.palette.primary.main,
  textAlign: "center",
}));

export const DictionaryListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  borderRadius: "50%",
  maxWidth: "fit-content",
  "& .MuiListItemIcon-root": {
    color: theme.palette.primary.lighter,
    transition: "color 0.3s",
  },
  "&:hover": {
    backgroundColor: "transparent",
    "& .MuiListItemIcon-root": { color: theme.palette.primary.main },
  },
}));

export const DictionaryListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  padding: theme.spacing(0.5),
  minWidth: 0,
}));
