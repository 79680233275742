import { memo } from "react";
import { useTranslation } from "react-i18next";

import { Divider, Stack, Typography } from "@mui/material";

import filters from "filters";
import { INTERACTION_TYPES_ICONS } from "utils/constants";
import { getShortName } from "utils/helpers";
import InteractionMessage from "./InteractionMessage";
import CommentInteraction from "./CommentInteraction";
import CallInteraction from "./CallInteraction";

const InteractionItem = ({ item, lead }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Stack>{INTERACTION_TYPES_ICONS[item.type] || null}</Stack>
      <Stack sx={{ width: "100%" }}>
        <Stack direction="row" alignItems="center" gap={2} flex={1}>
          <Typography variant="capture" color="text.light" whiteSpace="nowrap">
            {filters.date(item.createdAt, "dd.MM.yyyy / HH:mm")}
          </Typography>
          <Typography variant="capture" color={item.author ? "info.main" : "text.light"}>
            {item.author ? getShortName(item.author) : t("types.lead.interactionsMessages.system")}
          </Typography>
          <InteractionMessage item={item} t={t} />
        </Stack>
        {(item.type === "PHONE_CALL_IN" || item.type === "PHONE_CALL_OUT") && <CallInteraction item={item} />}

        {item.type === "COMMENT" && <CommentInteraction item={item} lead={lead} />}
        <Divider sx={{ borderColor: "secondary.light" }} />
      </Stack>
    </Stack>
  );
};

export default memo(InteractionItem);
