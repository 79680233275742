import { memo } from "react";
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";

import Error from "views/common/Error";
import { PipelineForm } from "./components";
import { useNavigate } from "react-router-dom";

const NewPipelinePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
      <PipelineForm navigate={navigate} t={t}/>
    </ErrorBoundary>
  );
};

export default memo(NewPipelinePage);
