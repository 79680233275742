import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

import { Button, Divider, IconButton, Popover, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { CloseOutlined, FilterAltOffOutlined, FilterAltOutlined } from "@mui/icons-material";

import { dictionarySelectors } from "store/ducks/dictionary";
import { pipelineSelectors } from "store/ducks/pipeline";

import CustomAutocomplete from "views/filter/CustomAutocomplete";
import CustomDatePicker from "views/filter/CustomDatePicker";
import { getShortName, isFilterFieldFilled } from "utils/helpers";
import { MOBILE_VIEW } from "utils/constants";

const AllLeadsFilter = ({
  filter,
  handleFilter,
  handleApplyAllLeadsFilter,
  initialAllLeadsFilterValues,
  setIsAllLeadsFilterApplied,
  setAppliedAllLeadsFilter,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const closeReasons = useSelector(dictionarySelectors.getLeadCloseReasons());
  const programs = useSelector(dictionarySelectors.getPrograms());
  const managers = useSelector(dictionarySelectors.getManagers());
  const channels = useSelector(dictionarySelectors.getMarketingChannels());
  const enums = useSelector(dictionarySelectors.getEnums());
  const pipelines = useSelector(pipelineSelectors.getPipelines());
  const pipelinesLoading = useSelector(pipelineSelectors.pipelinesLoading());

  const stagesOptions = filter?.pipelines
    ?.map((pipeline) => {
      return pipeline.stages.map((stage) => ({ ...stage, pipeline: { name: pipeline.name } }));
    })
    .flat();

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleFilter(initialAllLeadsFilterValues);
    setIsAllLeadsFilterApplied(false);
    setAppliedAllLeadsFilter(initialAllLeadsFilterValues);
  };

  const handleApplyAndClose = () => {
    handleApplyAllLeadsFilter();
    setAnchorEl(null);
  };

  const isFilterFilled = isFilterFieldFilled(filter);

  const handleDateChange = (date, isStartOfDay) => {
    if (date) {
      return isStartOfDay ? DateTime.fromISO(date).startOf("day").toISO() : DateTime.fromISO(date).endOf("day").toISO();
    }
    return null;
  };

  const handleOnStagesChange = (value) => {
    const stages = value.map(({ pipeline, ...rest }) => rest);
    handleFilter({ ...filter, stages: stages });
  };

  return (
    <>
      <Tooltip title={t("base.tooltips.leadFilter")}>
        <IconButton
          id="allLeads-filter-button"
          aria-controls={open ? "allLeads-filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickOpen}
          sx={{ color: "primary.main", p: 0, width: 40 }}
        >
          {isFilterFilled ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
        </IconButton>
      </Tooltip>
      <Popover
        id="allLeads-filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          width: "100%",
        }}
        MenuListProps={{
          "aria-labelledby": "allLeads-filter",
          role: "listbox",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            ...(isMobile && { width: "100%" }),
            ...(!isMobile && {
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 10,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            }),
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Stack width="100%" p={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1} width="100%">
            <Typography color="text.main" fontWeight={600}>
              {t("types.lead.leadsFilter")}
            </Typography>
            <IconButton sx={{ color: "primary.main", p: 0 }} onClick={handleClose}>
              <CloseOutlined fontSize="small" />
            </IconButton>
          </Stack>
          <Divider sx={{ borderColor: "background.default", mb: 1 }} />
          <Stack
            direction={isMobile ? "column" : "row"}
            alignItems="flex-start"
            gap={3}
            width="100%"
            height={isMobile ? 400 : "100%"}
            sx={{ ...(isMobile && { overflowY: "auto" }) }}
          >
            <Stack width="100%" gap={1} minWidth={280} maxWidth={isMobile ? "95%" : 280}>
              <CustomAutocomplete
                options={closeReasons}
                value={filter.closeReasons}
                label={t("base.labels.reasonOfClosing")}
                multiple
                onChange={(value) => handleFilter({ ...filter, closeReasons: value })}
              />
              <CustomAutocomplete
                options={programs}
                value={filter.programs}
                multiple
                label={t("base.labels.program")}
                onChange={(value) => handleFilter({ ...filter, programs: value })}
              />
              <CustomAutocomplete
                options={managers}
                label={t("base.labels.responsible")}
                placeholder={t("base.placeholders.choose")}
                value={filter.responsible}
                multiple
                getCustomLabel={getShortName}
                onChange={(value) => handleFilter({ ...filter, responsible: value })}
              />
              <CustomAutocomplete
                options={channels}
                label={t("base.labels.channel")}
                placeholder={t("base.placeholders.choose")}
                value={filter.channels}
                multiple
                onChange={(value) => handleFilter({ ...filter, channels: value })}
              />
              <CustomAutocomplete
                options={enums.activeStatuses}
                label={t("base.labels.status")}
                placeholder={t("base.placeholders.choose")}
                value={filter.statuses}
                multiple
                onChange={(value) => handleFilter({ ...filter, statuses: value })}
              />
              <CustomAutocomplete
                options={pipelines}
                loading={pipelinesLoading}
                label={t("base.labels.pipeline")}
                placeholder={t("base.placeholders.choose")}
                value={filter.pipelines}
                multiple
                onChange={(value) => handleFilter({ ...filter, pipelines: value })}
              />
              <CustomAutocomplete
                options={stagesOptions}
                loading={pipelinesLoading}
                label={t("base.labels.stage")}
                placeholder={t("base.placeholders.choose")}
                value={filter.stages}
                multiple
                onChange={handleOnStagesChange}
                groupBy={(option) => option.pipeline.name}
                getCustomLabel={(option) => option.name}
              />
            </Stack>
            <Stack width="100%" gap={1} minWidth={280} maxWidth={isMobile ? "95%" : 280}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-end" gap={2} width="100%">
                <CustomDatePicker
                  value={filter.createdAtAfter}
                  label={t("base.labels.createdAtAfter")}
                  onChange={(date) => handleFilter({ ...filter, createdAtAfter: handleDateChange(date, true) })}
                  width="100%"
                />
                <Typography>-</Typography>
                <CustomDatePicker
                  value={filter.createdAtBefore}
                  label={t("base.labels.createdAtBefore")}
                  onChange={(date) => handleFilter({ ...filter, createdAtBefore: handleDateChange(date, false) })}
                  width="100%"
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-end" gap={2}>
                <CustomDatePicker
                  value={filter.meetingDateAfter}
                  label={t("base.labels.meetingDateAfter")}
                  onChange={(date) => handleFilter({ ...filter, meetingDateAfter: date })}
                  width="100%"
                />
                <Typography>-</Typography>
                <CustomDatePicker
                  value={filter.meetingDateBefore}
                  label={t("base.labels.meetingDateBefore")}
                  onChange={(date) => handleFilter({ ...filter, meetingDateBefore: date })}
                  width="100%"
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-end" gap={2}>
                <CustomDatePicker
                  value={filter.contractDateAfter}
                  label={t("base.labels.contractDateAfter")}
                  onChange={(date) => handleFilter({ ...filter, contractDateAfter: date })}
                  width="100%"
                />
                <Typography>-</Typography>
                <CustomDatePicker
                  value={filter.contractDateBefore}
                  label={t("base.labels.contractDateBefore")}
                  onChange={(date) => handleFilter({ ...filter, contractDateBefore: date })}
                  width="100%"
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-end" gap={2}>
                <CustomDatePicker
                  value={filter.closeDateAfter}
                  label={t("base.labels.closeDateAfter")}
                  onChange={(date) => handleFilter({ ...filter, closeDateAfter: date })}
                  width="100%"
                />
                <Typography>-</Typography>
                <CustomDatePicker
                  value={filter.closeDateBefore}
                  label={t("base.labels.closeDateBefore")}
                  onChange={(date) => handleFilter({ ...filter, closeDateBefore: date })}
                  width="100%"
                />
              </Stack>
            </Stack>
          </Stack>
          <Divider
            sx={{
              my: 2,
              // width: "100%"
            }}
          />
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} width="100%">
            <Button onClick={handleClose} sx={{ width: "100%" }}>
              {t("base.buttons.cancel")}
            </Button>
            <Button onClick={handleApplyAndClose} sx={{ width: "100%" }} variant="contained">
              {t("base.buttons.apply")}
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default memo(AllLeadsFilter);
