import { memo, useState } from "react";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import store from "store";
import { dictionarySelectors } from "store/ducks/dictionary";
import { leadThunks } from "store/ducks/lead";
import { tableSelectors } from "store/ducks/table";

import Modal from "views/common/modal/Modal";
import CustomDatePicker from "views/filter/CustomDatePicker";
import CustomAutocomplete from "views/filter/CustomAutocomplete";

import { AttentionIcon, ClockIcon, DueDateIcon } from "theme/icons";
import { getShortName } from "utils/helpers";
import useModal from "hooks/useModal";
import { CloseOutlined } from "@mui/icons-material";
import { MOBILE_VIEW } from "utils/constants";

const BulkLeadsStage = () => {
  const { t } = useTranslation();
  const [modalOpen, openModal, closeModal] = useModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const enums = useSelector(dictionarySelectors.getEnums());
  const { taskStatuses: statuses } = enums;
  const taskTypes = useSelector(dictionarySelectors.getTaskTypes());
  const taskTypesLoading = useSelector(dictionarySelectors.taskTypesLoading());

  const employees = useSelector(dictionarySelectors.getEmployees());
  const employeesLoading = useSelector(dictionarySelectors.employeesLoading());

  const selectedLeads = useSelector(tableSelectors.getSelected());
  const leadIds = selectedLeads.length > 0 && selectedLeads?.map((lead) => lead?.id);
  const initialTaskDataValue = {
    name: "",
    description: "",
    responsible: [],
    status: null,
    type: null,
    dueDate: null,
    dueTime: null,
    leads: selectedLeads,
  };

  const [taskData, setTaskData] = useState(initialTaskDataValue);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setTaskData(initialTaskDataValue);
    setAnchorEl(null);
  };

  const handleApplyAndClose = async () => {
    const response = await store.dispatch(leadThunks.addBulkLeadsTask({ ids: leadIds, task: taskData }));

    if (!response.error) {
      toast.success(t("messages.success.toast.createTask"));
      setAnchorEl(null);
      closeModal();
    }
  };

  const handleTimeChange = (time) => {
    if (!time) {
      setTaskData({ ...taskData, dueTime: null });
      return;
    }
    const formattedTime = time.set({ second: 0 }).toFormat("HH:mm:ss");
    setTaskData({ ...taskData, dueTime: formattedTime });
  };

  return (
    <>
      <Tooltip title={t("base.tooltips.addTask")}>
        <IconButton
          id="bulk-task-button"
          aria-controls={open ? "bulk-task-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickOpen}
        >
          <DueDateIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="bulk-task-popover"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            ...(isMobile && { width: "100%" }),
            ...(!isMobile && {
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                left: 10,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            }),
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack direction="row" px={2} pt={1} justifyContent="space-between" alignItems="center" mb={1}>
          <Typography color="text.main" fontWeight={600}>
            {t("types.lead.bulkActions.taskTitle")}
          </Typography>
          <IconButton sx={{ color: "primary.main", p: 0 }} onClick={handleClose}>
            <CloseOutlined fontSize="small" />
          </IconButton>
        </Stack>
        <Divider sx={{ borderColor: "background.default", mx: 2 }} />
        <Stack p={2} pt={1} gap={1} width="100%" minWidth={380}>
          <TextField
            value={taskData.name}
            onChange={(event) => setTaskData({ ...taskData, name: event.target.value })}
            label={t("base.labels.name")}
            placeholder={t("base.placeholders.name")}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            value={taskData.description}
            onChange={(event) => setTaskData({ ...taskData, description: event.target.value })}
            label={t("base.labels.description")}
            placeholder={t("base.placeholders.description")}
            InputLabelProps={{ shrink: true }}
          />

          <CustomAutocomplete
            options={employees}
            loading={employeesLoading}
            value={taskData.responsible}
            getCustomLabel={getShortName}
            label={t("base.labels.responsible")}
            multiple
            onChange={(value) => setTaskData({ ...taskData, responsible: value })}
            maxWidth={500}
          />
          <Stack direction="row" alignItems="center" gap={2} width="100%">
            <CustomAutocomplete
              options={statuses}
              value={taskData.status}
              label={t("base.labels.status")}
              onChange={(value) => setTaskData({ ...taskData, status: value })}
            />
            <CustomAutocomplete
              options={taskTypes}
              loading={taskTypesLoading}
              value={taskData.type}
              label={t("base.labels.type")}
              onChange={(value) => setTaskData({ ...taskData, type: value })}
            />
          </Stack>
          <Stack direction="row" alignItems="center" gap={2} width="100%">
            <CustomDatePicker
              value={taskData.dueDate}
              label={t("base.labels.dueDate")}
              onChange={(date) => setTaskData({ ...taskData, dueDate: date })}
              width="50%"
            />
            <DesktopTimePicker
              sx={{ width: "50%" }}
              ampm={false}
              closeOnSelect
              value={taskData.dueTime ? DateTime.fromISO(taskData.dueTime) : null}
              label={t("base.labels.dueTime")}
              onChange={(time) => handleTimeChange(time)}
              slots={{
                openPickerIcon: ClockIcon,
              }}
              slotProps={{
                textField: {
                  InputLabelProps: { shrink: true },
                },
                actionBar: {
                  sx: { display: "none" },
                },
              }}
            />
          </Stack>
          <Divider sx={{ width: "100%", my: 1 }} />
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
            <Button onClick={handleClose} sx={{ width: "100%" }}>
              {t("base.buttons.cancel")}
            </Button>
            <Button
              onClick={openModal}
              sx={{ width: "100%" }}
              variant="contained"
              disabled={!taskData?.name || taskData?.responsible?.length === 0 || !taskData.status}
            >
              {t("base.buttons.apply")}
            </Button>
          </Stack>
        </Stack>
      </Popover>
      <Modal
        title={`${t("types.lead.bulkActions.statusTitle")}`}
        open={modalOpen}
        onClose={closeModal}
        icon={<AttentionIcon />}
        actions={
          <>
            <Button sx={{ px: 8 }} onClick={closeModal}>
              {t("base.buttons.cancel")}
            </Button>
            <Button variant="contained" sx={{ px: 8 }} type="button" onClick={handleApplyAndClose}>
              {t("base.buttons.apply")}
            </Button>
          </>
        }
      >
        <Typography textAlign="center" color="text.main" fontWeight={600}>
          {t("types.lead.bulkActions.taskMessage")} {selectedLeads.length} {t("types.lead.namePlural").toLowerCase()}?
        </Typography>
      </Modal>
    </>
  );
};

export default memo(BulkLeadsStage);
