import { memo, useEffect, useState } from "react";
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Stack, Tab, Tabs, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from "@mui/material";

import store from "store";
import { leadSelectors, leadThunks } from "store/ducks/lead";

import Error from "views/common/Error";
import Loader from "views/common/Loader";
import HeaderPage from "views/common/HeaderPage";
import { PageWrapper } from "views/common/styledComponents";
import ContactForm from "../../common/ContactForm";
import LeadDetailsForm from "./components/LeadDetailsForm";
import LeadInteractions from "./components/LeadInteractions";

import { TabPanel, a11yProps } from "utils/tabsFunc";
import useModal from "hooks/useModal";
import { dictionarySelectors } from "store/ducks/dictionary";
import Modal from "views/common/modal/Modal";
import { AttentionIcon } from "theme/icons";
import CloseLeadForm from "./components/CloseLeadForm";
import { DESKTOP_HEIGHT, DESKTOP_VIEW, MOBILE_VIEW, TABLET_HEIGHT, TABLET_VIEW } from "utils/constants";
import HeaderPageMobile from "views/common/HeaderPageMobile";

const LeadPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { id } = useParams();
  const [modalOpen, openModal, closeModal] = useModal();
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const isTablet = useMediaQuery(TABLET_VIEW);

  const [leadView, setLeadView] = useState("");

  const [leadTabsValue, setLeadTabsValue] = useState(0);

  const lead = useSelector(leadSelectors.getLead());
  const leadLoading = useSelector(leadSelectors.leadLoading());

  const enums = useSelector(dictionarySelectors.getEnums());
  const closeLeadStatus = enums?.activeStatuses?.find((status) => status.name === "ARCHIVED");

  useEffect(() => {
    store.dispatch(leadThunks.fetchLead(id));
  }, [id]);

  useEffect(() => {
    isMobile && setLeadView("data");
  }, [isMobile]);

  const handleChange = (event, newValue) => {
    setLeadTabsValue(newValue);
  };

  const handleLeadViewChange = (event, newView) => {
    if (leadView === newView || newView === null) return;

    setLeadView(newView);
  };

  if (leadLoading) {
    return <Loader />;
  }

  return (
    <PageWrapper
      sx={{ height: { lg: DESKTOP_HEIGHT, sm: TABLET_HEIGHT, xs: "calc(100dvh - 138px)" }, gap: { sm: 1, xs: 1 } }}
    >
      {isDesktop ? (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPage title={t("types.lead.name")} openModal={openModal} closeLead={lead.status?.name !== "ARCHIVED"} />
        </ErrorBoundary>
      ) : (
        <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
          <HeaderPageMobile openModal={openModal} closeLead={lead.status?.name !== "ARCHIVED"} />
        </ErrorBoundary>
      )}

      <Stack
        height={"calc(100% - 76px)"}
        direction={isMobile ? "column" : "row"}
        gap={2}
        px={isMobile || isTablet ? 1 : 0}
      >
        {isMobile && (
          <ToggleButtonGroup
            color="primary"
            value={leadView}
            exclusive
            onChange={handleLeadViewChange}
            sx={{ px: 2, width: "100%" }}
          >
            <ToggleButton sx={{ width: "100%" }} value={"data"}>
              {t("base.dictionary.data")}
            </ToggleButton>
            <ToggleButton sx={{ width: "100%" }} value={"history"}>
              {t("base.dictionary.history")}
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {((isMobile && leadView === "data") || isDesktop || isTablet) && (
          <Stack
            flex={isMobile ? 1 : 0.3}
            height="100%"
            borderRadius={2}
            boxShadow={`0px 0px 4px 0px ${theme.palette.primary.lighter}`}
          >
            <Tabs value={leadTabsValue} onChange={handleChange} aria-label="main data">
              <Tab
                sx={{ width: `calc(50% - ${theme.spacing(1)})` }}
                label={t("types.lead.form.contact")}
                {...a11yProps(0)}
              />
              <Tab sx={{ width: `calc(50% - ${theme.spacing(1)})` }} label={t("types.lead.name")} {...a11yProps(1)} />
            </Tabs>
            <Stack
              justifyContent="center"
              sx={{
                backgroundColor: "extra.white",
                borderRadius: theme.spacing(0, 0, 1, 1),
                gap: 2,
                height: "calc(100% - 48px)",
              }}
            >
              <TabPanel value={leadTabsValue} index={0}>
                <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
                  <ContactForm contact={lead?.contact} lead={lead} t={t} />
                </ErrorBoundary>
              </TabPanel>
              <TabPanel value={leadTabsValue} index={1}>
                <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
                  <LeadDetailsForm lead={lead} t={t} />
                </ErrorBoundary>
              </TabPanel>
            </Stack>
          </Stack>
        )}
        {((isMobile && leadView === "history") || isDesktop || isTablet) && (
          <Stack flex={isMobile ? 1 : 0.7} height="100%">
            <Stack
              justifyContent="center"
              sx={{
                backgroundColor: "extra.white",
                borderRadius: theme.spacing(1),
                gap: 2,
                height: "100%",
              }}
            >
              <ErrorBoundary fallback={<Error message={t("messages.errors.failedLoadComponent")} />}>
                <LeadInteractions lead={lead} t={t} />
              </ErrorBoundary>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Modal
        title={t("types.lead.closeLead") + `: "${lead.name}"`}
        icon={<AttentionIcon />}
        open={modalOpen}
        onClose={closeModal}
      >
        <CloseLeadForm
          closeModal={closeModal}
          lead={lead}
          t={t}
          closeLeadStatus={closeLeadStatus}
          actionToUpdateCollection={"lead"}
        />
      </Modal>
    </PageWrapper>
  );
};

export default memo(LeadPage);
